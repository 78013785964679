import CONFIG from '@config';

export const FETCH_REFERENCES = 'FETCH_REFERENCES';
export const fetchReferences = () => ({
  type: FETCH_REFERENCES,
  route: '/references',
  method: 'GET',
  url: CONFIG.API_V2_URL,
});

export const FETCH_AGENCIES = 'FETCH_AGENCIES';
export const fetchAgencies = () => ({
  type: FETCH_AGENCIES,
  route: '/agencies',
  method: 'GET',
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const PATCH_AGENCY = 'PATCH_AGENCY';
export const patchAgency = (id: number, agencyModifications: Object) => ({
  type: PATCH_AGENCY,
  route: `/agencies/${id}`,
  method: 'PATCH',
  body: agencyModifications,
  url: CONFIG.API_V2_URL,
});

export const POST_AGENCY = 'POST_AGENCY';
export const postAgency = (agencyModifications: Object) => ({
  type: POST_AGENCY,
  route: `/agencies`,
  method: 'POST',
  body: agencyModifications,
  url: CONFIG.API_V2_URL,
});
