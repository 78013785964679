import React, { Component } from 'react';
import { ListGroup } from 'react-bootstrap';

import { GenericFormGroup } from '@components';

type Props = {
  label?: string;
  required?: boolean;
  onChange?: Function;
  className?: string;
  listClassName?: string;
  listItemClassName?: string;
  options?: OptionItem[];
  dense?: boolean;
  selected?: number;
  labelNoResults?: string;
  controlId?: string;
  prepend?: string;
  append?: string;
  clickable?: boolean;
  hoverable?: boolean;
};

interface OptionItem {
  id: number | string;
  label: React.ReactNode;
}

class GenericList extends Component<Props> {
  static defaultProps = {
    clickable: true,
    hoverable: true,
    controlId: null,
    dense: false,
    className: undefined,
    labelNoResults: '',
    listClassName: '',
    listItemClassName: '',
    prepend: '',
    append: '',
    selected: undefined,
  };

  render() {
    const {
      label,
      className,
      required,
      controlId,
      prepend,
      append,
    } = this.props;

    return (
      <GenericFormGroup
        className={className}
        label={label}
        required={required}
        controlId={controlId}
        prepend={prepend}
        append={append}
      >
        {this.renderList()}
      </GenericFormGroup>
    );
  }

  renderList = () => {
    const { options, labelNoResults, listClassName } = this.props;

    return (
      <ListGroup className={listClassName}>
        {options.map((option: OptionItem) => this.renderListItem(option))}
        {!options.length ? (
          <ListGroup.Item>{labelNoResults}</ListGroup.Item>
        ) : null}
      </ListGroup>
    );
  };

  renderListItem = (option: OptionItem) => {
    const {
      dense,
      selected,
      clickable,
      hoverable,
      listItemClassName,
    } = this.props;

    return (
      <ListGroup.Item
        key={option.id}
        className={`${clickable ? 'clickable ' : ''}${
          hoverable ? 'hoverable ' : ''
        }${dense ? 'dense ' : ''}${
          selected === option.id ? 'selected' : ''
        }${listItemClassName}`}
        onClick={this.handleClick.bind(this, option.id)}
      >
        {option.label}
      </ListGroup.Item>
    );
  };

  handleClick = (value: number, event: MouseEvent) => {
    const { onChange } = this.props;

    if (onChange) onChange(value, event);
  };
}

export default GenericList;
