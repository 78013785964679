import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';

import routes from '@routes';

import { CLEANING_CONTRACTS_POLICIES } from '@policies/cleaningContracts';

import { getMe } from '@redux/me/selectors';

import SuspenseRouteFallback from '@components/SuspenseRouteFallback/SuspenseRouteFallback';

/* istanbul ignore next */
const CleaningContractsList = React.lazy(() =>
  import('../Common/CleaningContracts/CleaningContractsList'),
);

/* istanbul ignore next */
const CleaningContractorsHeatmap = React.lazy(() =>
  import('./CleaningContractorsHeatmap/CleaningContractorsHeatmap'),
);
/* istanbul ignore next */
const Contractors = React.lazy(() =>
  import('../Settings/Contractors/Contractors'),
);

export function CleaningContracts() {
  const user = useSelector(getMe);

  return (
    <SuspenseRouteFallback>
      <Switch>
        {user.checkPolicies(CLEANING_CONTRACTS_POLICIES[routes.CLEANINGS]) && (
          <Route exact path={routes.CLEANINGS}>
            <CleaningContractsList />
          </Route>
        )}
        {user.checkPolicies(
          CLEANING_CONTRACTS_POLICIES[routes.CLEANINGS_HEATMAP],
        ) && (
          <Route exact path={routes.CLEANINGS_HEATMAP}>
            <CleaningContractorsHeatmap />
          </Route>
        )}
        {user.checkPolicies(
          CLEANING_CONTRACTS_POLICIES[routes.CLEANINGS_CONTRACTORS],
        ) && (
          <Route path={routes.CLEANINGS_CONTRACTORS}>
            <Contractors />
          </Route>
        )}
      </Switch>
    </SuspenseRouteFallback>
  );
}

export default CleaningContracts;
