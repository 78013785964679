import React, { ChangeEvent, Component } from 'react';
import { Form } from 'react-bootstrap';

import { GenericFormGroup } from '@components';

import './GenericSelect.scss';

export interface GenericSelectProps {
  value?: string | number;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (value: string) => void;
  options?: OptionItem[];
  defaultOption?: string;
  className?: string;
  autoFocus?: boolean;
  controlId?: string;
  variant?: 'normal' | 'compact' | 'large';
}

interface OptionItem {
  id: number | string;
  label: string;
}

class GenericSelect extends Component<GenericSelectProps> {
  static defaultProps = {
    autoFocus: false,
    controlId: null,
    options: [],
    disabled: false,
    className: undefined,
    defaultOption: '',
    variant: 'normal',
  };

  render() {
    const { label, required, controlId, className } = this.props;

    return (
      <GenericFormGroup
        className={className}
        label={label}
        required={required}
        controlId={controlId}
      >
        {this.renderSelect()}
      </GenericFormGroup>
    );
  }

  renderSelect = () => {
    const {
      value,
      options,
      disabled,
      defaultOption,
      autoFocus,
      required,
    } = this.props;

    return (
      <Form.Control
        as="select"
        className={[
          this.handleVariant(),
          !value ? ' GenericSelect__select--default' : '',
        ].join('')}
        required={required}
        disabled={disabled}
        value={[undefined, null].includes(value) ? '' : value}
        onChange={this.handleChange}
        autoFocus={autoFocus}
      >
        {defaultOption ? <option value="">{defaultOption}</option> : null}
        {options.map((option: OptionItem) => this.renderOption(option))}
      </Form.Control>
    );
  };

  renderOption = (option: OptionItem) => {
    return (
      <option key={option.id} value={option.id}>
        {option.label}
      </option>
    );
  };

  handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const { onChange, required } = this.props;
    const {
      target: { value },
    } = event;

    if (required && value === '') return;

    onChange(value === '' ? null : value);
  };

  handleVariant = () => {
    const { variant } = this.props;

    switch (variant) {
      case 'compact':
        return 'form-control-sm';
      case 'large':
        return 'form-control-lg';
      default:
        return '';
    }
  };
}

export default GenericSelect;
