import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Switch, useHistory, useLocation } from 'react-router';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';

import { SSlideInPortal } from '@smartrenting/smartomic';

import routes from '@routes';

import { startInitialization } from '@redux/common/actions';

import { isInitialized, isLoading } from '@redux/common/selectors';
import { getMe, isAuthenticated } from '@redux/me/selectors';

import { useAction } from '@hooks/useAction/useAction';

import { SuspenseComponent } from '@components';
import Loader from '@components/Loader/Loader';
import SuspenseRouteFallback from '@components/SuspenseRouteFallback/SuspenseRouteFallback';

import CleaningContracts from '@scopes/CleaningContracts/CleaningContracts';

import { ClearIndexedDB } from '../../../indexDB/indexDB';

import { useHomePathFromRole } from './components/useHomePathFromRole/useHomePathFromRole';

import './App.scss';

// /* istanbul ignore next */
const Toast = React.lazy(() => import('./components/Toast/Toast'));
// /* istanbul ignore next */
const Sidebar = React.lazy(() => import('./components/Sidebar/Sidebar'));

const SendNotificationModal = React.lazy(() =>
  import('./components/SendNotificationModal/SendNotificationModal'),
);

// /* istanbul ignore next */
const Apartments = React.lazy(() => import('@scopes/Apartments/Apartments'));

const Authentication = React.lazy(
  // /* istanbul ignore next */
  () => import('@scopes/Authentication/Authentication'),
);
// /* istanbul ignore next */
const Bookings = React.lazy(() => import('@scopes/Bookings/Bookings'));
// /* istanbul ignore next */
const Contracts = React.lazy(() => import('@scopes/Contracts/Contracts'));
// /* istanbul ignore next */
const Prospects = React.lazy(() => import('@scopes/Prospects/Prospects'));
// /* istanbul ignore next */
const Settings = React.lazy(() => import('@scopes/Settings/Settings'));
// /* istanbul ignore next */
const Finances = React.lazy(() => import('@scopes/Finances/Finances'));
// /* istanbul ignore next */
const SpecialEvents = React.lazy(() =>
  // /* istanbul ignore next */
  import('@scopes/SpecialEvents/SpecialEvents'),
);

export function App() {
  const { t } = useTranslation();
  const authenticated = useSelector(isAuthenticated);
  const initialized = useSelector(isInitialized);
  const loading = useSelector(isLoading);
  const initialize = useAction(startInitialization(true));
  const user = useSelector(getMe);
  const location = useLocation();
  const history = useHistory();
  const homePath = useHomePathFromRole(user?.role);

  useEffect(() => {
    if (authenticated && !initialized) {
      initialize();
    } else if (!authenticated && !location.pathname.match(/(\/$)|(^\/auth)/u)) {
      history.replace(`/auth?from=${location.pathname}`);
    }
  }, [initialized, authenticated, location.pathname]);

  useEffect(() => {
    if ('Notification' in window && Notification.permission === 'default') {
      Notification.requestPermission();
    }

    ClearIndexedDB();
  }, []);

  return (
    <>
      {loading && <Loader text={t('common.loading')} />}

      <div
        className={`container-fluid nopadding fullscreen ${
          !authenticated ? 'fullscreen--centered' : ''
        }`}
      >
        {authenticated && initialized && (
          <SuspenseComponent>
            <>
              <Toast />
              <Sidebar />
              <SendNotificationModal />
            </>
          </SuspenseComponent>
        )}
        <div id="content" className="App">
          <Route exact path={routes.ROOT}>
            {(!authenticated || (authenticated && initialized)) && (
              <Redirect
                to={{
                  pathname: authenticated ? homePath : routes.AUTH,
                  search: location.search,
                }}
              />
            )}
          </Route>
          <Route path={routes.AUTH}>
            <SuspenseRouteFallback>
              <Authentication />
            </SuspenseRouteFallback>
          </Route>
          {authenticated && initialized && (
            <Switch>
              <Route path={routes.CONTRACTS}>
                <SuspenseRouteFallback>
                  <Contracts />
                </SuspenseRouteFallback>
              </Route>
              <Route path={routes.SETTINGS}>
                <SuspenseRouteFallback>
                  <Settings />
                </SuspenseRouteFallback>
              </Route>

              {!user.isContractor() && (
                <>
                  <Route path={routes.PROSPECTS}>
                    <SuspenseRouteFallback>
                      <Prospects />
                    </SuspenseRouteFallback>
                  </Route>
                  <Route path={routes.APARTMENTS}>
                    <SuspenseRouteFallback>
                      <Apartments />
                    </SuspenseRouteFallback>
                  </Route>
                  <Route path={routes.BOOKINGS}>
                    <SuspenseRouteFallback>
                      <Bookings />
                    </SuspenseRouteFallback>
                  </Route>
                  <Route path={routes.CLEANINGS}>
                    <SuspenseRouteFallback>
                      <CleaningContracts />
                    </SuspenseRouteFallback>
                  </Route>
                  <Route path={routes.FINANCES}>
                    <SuspenseRouteFallback>
                      <Finances />
                    </SuspenseRouteFallback>
                  </Route>
                  <Route path={routes.SPECIAL_EVENTS}>
                    <SuspenseRouteFallback>
                      <SpecialEvents />
                    </SuspenseRouteFallback>
                  </Route>
                </>
              )}
              <Route exact path={routes.ROOT}>
                {/**
                 * This is necessary to trick outer router in taking precedence over the following redirect
                 */}
              </Route>
              <Route>
                <Redirect
                  path="*"
                  to={{
                    pathname: routes.ROOT,
                  }}
                />
              </Route>
            </Switch>
          )}
        </div>
      </div>
    </>
  );
}

export default () => (
  <SSlideInPortal>
    <Router>
      <App />
    </Router>
  </SSlideInPortal>
);
