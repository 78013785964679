export const STATUS = {
  PENDING: 'pending',
  ACTIVE: 'active',
  ARCHIVED: 'archived',
};

export const ROLE = {
  ADMIN: 'admin',
  HEAD_OF_OPS: 'head-of-ops',
  HEAD_OF_SALES: 'head-of-sales',
  CUSTOMER_EXPERIENCE: 'customer-experience',
  BUSINESS: 'business',
  PROBLEM_SOLVER: 'problem-solver',
  ACCOUNT_MANAGER: 'account-manager',
  CONTENT: 'content',
  SALE: 'sale',
  GUEST_MANAGER: 'guest-manager',
  INVENTORY_AGENT: 'inventory-agent',
  PROSPECT: 'prospect',
  CLEANING_CONTRACTOR_ADMIN: 'cleaning-contractor-admin',
  CLEANING_CONTRACTOR: 'cleaning-contractor',
  TECHNICAL: 'technical',
};

export const ROLE_GROUP = {
  sales: [
    ROLE.HEAD_OF_SALES,
    ROLE.ACCOUNT_MANAGER,
    ROLE.CUSTOMER_EXPERIENCE,
    ROLE.SALE,
  ],
  operationals: [
    ROLE.HEAD_OF_OPS,
    ROLE.CUSTOMER_EXPERIENCE,
    ROLE.PROBLEM_SOLVER,
    ROLE.GUEST_MANAGER,
    ROLE.INVENTORY_AGENT,
  ],
  handleApartments: [
    ROLE.HEAD_OF_OPS,
    ROLE.HEAD_OF_SALES,
    ROLE.CUSTOMER_EXPERIENCE,
    ROLE.ACCOUNT_MANAGER,
  ],
  handleInventories: [
    ROLE.HEAD_OF_OPS,
    ROLE.CUSTOMER_EXPERIENCE,
    ROLE.INVENTORY_AGENT,
  ],
  handlePublication: [ROLE.HEAD_OF_OPS, ROLE.CONTENT],
};

export const PROSPECT_ORIGIN = {
  FACEBOOK: 'facebook',
  PEOPLE: 'people',
  SCHOOL: 'school',
  FLYER: 'flyer',
  YOUTUBE: 'youtube',
  MEDIA: 'media',
  PUBLICITY: 'publicity',
  BDE: 'BDE',
  SOCIAL_NETWORKS: 'social-networks',
  WALK_IN: 'walk-in',
  TRANSAVIA: 'transavia',
  OTHERS: 'others',
};

export const PROSPECT_STATUS = {
  NEW: 'new',
  WAIT_FIRST_CALL: 'wait-first-call',
  PHONE_CALL: 'phone-call',
  WAIT_VISIT: 'wait-visit',
  VISIT_SCHEDULED: 'visit-scheduled',
  WAIT_DOCUMENTS: 'wait-documents',
  MANDATE_TO_SENT: 'mandate-to-sent',
  SALE_TO_VALIDATE: 'sale-to-validate',
  CLIENT_TO_VALIDATE: 'client-to-validate',
  TO_SIGN: 'to-sign',
  SIGNED: 'signed',
  EXPIRED: 'expired',
  REJECTED: 'rejected',
  TO_RECONTACT: 'to-recontact',
};

export const PROSPECT_BUTTON_TO_DISPLAY = {
  [PROSPECT_STATUS.NEW]: [
    'waitFirstCall',
    'setPhoneCall',
    'setVisit',
    'toRecontact',
    'discard',
  ],
  [PROSPECT_STATUS.TO_RECONTACT]: ['setPhoneCall', 'setVisit', 'discard'],
  [PROSPECT_STATUS.PHONE_CALL]: [
    'waitFirstCall',
    'setPhoneCallAgain',
    'waitVisit',
    'setVisit',
    'toRecontact',
    'discard',
  ],
  [PROSPECT_STATUS.VISIT_SCHEDULED]: [
    'setVisitAgain',
    'waitVisit',
    'toRecontact',
    'discard',
  ],
  [PROSPECT_STATUS.WAIT_DOCUMENTS]: ['toRecontact', 'discard'],
  [PROSPECT_STATUS.REJECTED]: [
    'setPhoneCall',
    'setVisit',
    'toRecontact',
    'setNew',
  ],
  [PROSPECT_STATUS.WAIT_FIRST_CALL]: [
    'setPhoneCall',
    'setVisit',
    'waitVisit',
    'toRecontact',
    'discard',
  ],
  [PROSPECT_STATUS.WAIT_VISIT]: ['setVisit', 'toRecontact', 'discard'],
  [PROSPECT_STATUS.MANDATE_TO_SENT]: ['toRecontact', 'discard'],
};

export const PROSPECT_STATUSES_ORDER = [
  PROSPECT_STATUS.NEW,
  PROSPECT_STATUS.PHONE_CALL,
  PROSPECT_STATUS.VISIT_SCHEDULED,
  PROSPECT_STATUS.MANDATE_TO_SENT,
  PROSPECT_STATUS.EXPIRED,
  PROSPECT_STATUS.SIGNED,
  PROSPECT_STATUS.TO_RECONTACT,
  PROSPECT_STATUS.REJECTED,
  PROSPECT_STATUS.WAIT_FIRST_CALL,
  PROSPECT_STATUS.WAIT_VISIT,
  PROSPECT_STATUS.WAIT_DOCUMENTS,
  PROSPECT_STATUS.SALE_TO_VALIDATE,
  PROSPECT_STATUS.CLIENT_TO_VALIDATE,
  PROSPECT_STATUS.TO_SIGN,
];

export const PROSPECT_VISIT_TYPE = {
  PHYSICAL: 'physical',
  VIDEO: 'video',
};

export const LEAVING_STATUS = {
  INIT: 'init',
  SIGNED: 'signed',
  ARCHIVED: 'archived',
  CLOSED: 'closed',
  EXPIRED: 'expired',
  CANCELED: 'canceled',
};

export const CHECKOUT_STATUS = {
  NO_PROBLEM: 'no-problem',
  MINOR_PROBLEM: 'minor-problem',
  MAJOR_PROBLEM: 'major-problem',
};

export const IMPORTANCE = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
};

export const EVENT_TYPE = {
  CREATE: 'create',
  UPDATE: 'update',
  CHANGE_SALE: 'change-sale',
  CHANGE_STATUS: 'change-status',
  COMMENT: 'comment',
  ADD_DOCUMENT: 'add-document',
  REMOVE_DOCUMENT: 'remove-document',
  GENERATE_MANDATE: 'generate-mandate',
};

export const OWNERSHIP = {
  OWNER: 'owner',
  TENANT: 'tenant',
};

export const RESIDENCE_TYPE = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
};

export const APARTMENT_RISKS = {
  LOW: 'low',
  NORMAL: 'normal',
  HIGH: 'high',
  VERY_HIGH: 'very-high',
};

export const CONTRACTOR_TYPES = {
  CLEANING: 'cleaning',
  INVENTORY: 'inventory',
  PHOTO: 'photo',
};

export const BOOKING_STATUS = {
  BOOKED: 'Booked',
  UNAVAILABLE: 'Unavailable',
  TENTATIVE: 'Tentative',
  CANCELED: 'Canceled',
};

export const BOOKING_TYPE = {
  COME_BACK: 'come-back',
  MAINTENANCE: 'maintenance',
  CLOSURE: 'closure',
  BOOKING: 'booking',
};

export const BOOKING_SOURCE = {
  AIRBNB: 'Airbnb',
  BOOKING_COM: 'Booking.com',
  LLOYD: 'Lloyd',
};

export const BOOKING_CHECK_TYPE = {
  CHECKIN: 'checkin',
  CHECKOUT: 'checkout',
} as const;

export const CLEANING_TYPES = {
  CLASSIC: 'classic',
  DEEP: 'deep',
  LAST_CLEANING: 'last-cleaning',
  FIRST_CLEANING: 'first-cleaning',
};

export const CLEANING_CONTRACT_STATUS = {
  ACTIVE: 'active',
  DONE: 'done',
  CANCELED: 'canceled',
  NOT_DONE: 'not-done',
  IN_PROGRESS: 'in-progress',
};

export const ROOM_TYPE = {
  LIVINGROOM: 'livingroom',
  BEDROOM: 'bedroom',
  BATHROOM: 'bathroom',
};

export const ROOM_ITEM_TYPE = {
  BATH: 'bath',
  BED_140: 'bed-140',
  BED_160: 'bed-160',
  BED_180: 'bed-180',
  BED_SOLO: 'bed-solo',
  BED_BUNK: 'bed-bunk',
  BED_COUCH: 'bed-couch',
  SHOWER: 'shower',
  TOILETS: 'toilets',
};

export const FORMAT_TYPE = {
  JSON: 'application/json',
  CSV: 'text/csv',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};

export const PHOTO_CONTRACT_STATUS = {
  TO_SCHEDULE: 'to-schedule',
  SCHEDULED: 'scheduled',
  DELIVERED: 'delivered',
  CANCELED: 'canceled',
};

export const PHOTO_CONTRACT_TYPE = {
  PICTHOUSE: 'picthouse',
  OTHER: 'other',
};

export const PLATFORMS = {
  AIRBNB: 'airbnb',
  BOOKING: 'booking',
  EXPEDIA: 'expedia',
};

export const KEYWORD_CATEGORY = {
  APARTMENT: 'apartment',
  BUILDING: 'building',
  NEIGHBORHOOD: 'neighborhood',
  SURROUNDING: 'surrounding',
};

export const HOST_RENT_TYPE = {
  FIXED: 'fixed',
  COMMISSION: 'commission',
};

export const DEPOSIT_STATUS = {
  PAID: 'paid',
  PENDING: 'pending',
};

export const HOST_RENT_ROW_TYPE = {
  FIXED: 'fixed',
  BOOKING: 'booking',
  MAINTENANCE: 'maintenance',
  COME_BACK: 'come-back',
  CUSTOM: 'custom',
  CEILING: 'ceiling',
  ALREADY_PAID_RENT: 'already-paid-rent',
  ALREADY_PAID_EARLY_PAYMENT: 'already-paid-early-payment',
  ALREADY_PAID_PAID_INSURANCE: 'already-paid-paid-insurance',
  ALREADY_PAID_PAID_CLEANING: 'already-paid-paid-cleaning',
  ALREADY_PAID_PAID_KEY: 'already-paid-paid-key',
  ALREADY_PAID_SETUP_FEE: 'already-paid-setup-fee',
  MODALITY_EARLY_PAYMENT: 'modality-early-payment',
  MODALITY_PAID_INSURANCE: 'modality-paid-insurance',
  MODALITY_PAID_CLEANING: 'modality-paid-cleaning',
  MODALITY_PAID_KEY: 'modality-paid-key',
  MODALITY_SETUP_FEE: 'modality-setup-fee',
};

export const PROSPECT_SOURCE = {
  LLOYD: 'lloyd',
  WEBSITE: 'website',
  OTHERS: 'others',
};

export const HOUSING_TYPE = {
  APARTMENT: 'apartment',
  HOUSE: 'house',
};

export const BOOKING_CHECK_STATUS = {
  CHECKED: 'checked',
  MANUALLY_CHECKED: 'manually-checked',
  UNCHECKED: 'unchecked',
};

export const INTERNET_FACILITY = {
  WIFI: 'wifi',
  DONGLE: 'dongle',
};

export const LANGUAGE = {
  FR: 'fr',
  EN: 'en',
};

export const TRANSLATION_LANGUAGE = {
  FR: 'FR',
  EN: 'EN',
  DE: 'DE',
  IT: 'IT',
  ES: 'ES',
};

export const INVENTORY_STATUS = {
  DONE: 'done',
  INIT: 'init',
  TO_SIGN: 'to-sign',
};

export const INVENTORY_TYPE = {
  IN: 'in',
  IN_REVIEW: 'in-review',
  OUT: 'out',
  OUT_REVIEW: 'out-review',
  OUT_VALIDATION: 'out-validation',
};

export const INVENTORY_CASE = {
  IN: 'in',
  OUT: 'out',
  LITIGATION: 'litigation',
};

export const APARTMENT_KEY_TYPE = {
  KEYCAFE: 'keycafe',
  KEYNEST: 'keynest',
  OTHERS: 'others',
};

export const APARTMENT_KEY_STATUS = {
  WAITING_DROPOFF: 'waiting-dropoff',
  IN_USE: 'in-use',
  STORED: 'stored',
};

export const APARTMENT_KEY_DENOMINATION = {
  GUEST: 'guest',
  AGENCY: 'agency',
  CLEANING: 'cleaning',
} as const;

export const DELIVERY_CONTRACTOR = {
  LA_POSTE: 'la-poste',
  STUART: 'stuart',
};

export const KEY_SETUP_STATUS = {
  INIT: 'init',
  READY: 'ready',
  DEPOSITED: 'deposited',
} as const;

export const LEAVING_LAST_CHECK_TYPE = {
  BREAKAGE: 'breakage',
  PLUMBING: 'plumbing',
  INVENTORY: 'inventory',
  OTHER: 'other',
};

export const LEAVING_LAST_CHECK_LEVEL = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
};

export const CLEANING_TAG = {
  GUEST_KEY_INSIDE: 'guest-key-inside',
  GUEST_INSIDE: 'guest-inside',
  THEFT_DAMAGE_RISK: 'theft-damage-risk',
  DEMANDING_CLEANING: 'demanding-cleaning',
};

export const CLEANING_OBSERVATION_TYPE = {
  GET_KEY: 'get-key',
  ENTER_APARTMENT: 'enter-apartment',
  CHECKLIST: 'checklist',
  TODO: 'todo',
  DEPOSE_KEY_GUEST: 'depose-key-guest',
  DEPOSE_KEY_CLEANING: 'depose-key-cleaning',
};

export const CLEANING_OBSERVATION_STATUS = {
  INIT: 'init',
  COMPLETE: 'complete',
};

export const CLEANING_OBSERVATION_CHECKLIST_NAME = {
  SMOKE: 'smoke',
  WATER: 'water',
  LIGHT: 'light',
  TV: 'tv',
  WIFI: 'wifi',
  BROKEN_FURNITURE: 'broken-furniture',
  SCRATCHES: 'scratches',
};

export const CLEANING_OBSERVATION_ROOM_TYPE = {
  KITCHEN: 'kitchen',
  BEDROOM: 'bedroom',
  BATHROOM: 'bathroom',
};

export const CLEANING_OBSERVATION_TODO_NAME = {
  MICROWAVE: 'microwave',
  TRASH: 'trash',
  EMPTY_FRIDGE: 'empty-fridge',
  DISHES: 'dishes',
  TOILETS: 'toilets',
  SINK: 'sink',
  BATH_SHOWER: 'bath-shower',
  SIPHON: 'siphon',
  BED: 'bed',
  AMENITIES: 'amenities',
  TOWEL: 'towel',
};

export const CLEANING_OBSERVATION_SKIP_REASON = {
  CANNOT_CLEAN: 'cannot-clean',
  NOT_FOUND: 'not-found',
  OTHER: 'other',
};

export const HOST_RENT_SEPA_STATUS = {
  GENERATED: 'generated',
  CANCELED: 'canceled',
};

export const HOST_RENT_SEPA_ACTIONS = {
  XML: 'xml',
  XLS: 'xls',
  CANCEL: 'cancel',
};

export const ACCESS_STEP_TYPE = {
  ACCESS: 'access',
  ACTION: 'action',
  CUSTOM: 'custom',
  FIXED: 'fixed',
  LOCATION: 'location',
};

export const ACCESS_TYPE = {
  CAR: 'car',
  FOOT: 'foot',
};

export const SEMI_AUTO_MAIL_TYPE = {
  POST_PHONE_CALL: 'post-phone-call',
  POST_VISIT: 'post-visit-guaranteed-rent',
  POST_VISIT_COM_SEASONALITY: 'post-visit-commission-seasonality',
  POST_VISIT_COM_DELAY: 'post-visit-commission-delay',
  HELP_SUBLEASE: 'help-sublease',
  ARGUMENT_SUBLEASE_EXEMPLE_1: 'argument-sublease-exemple-internship',
  ARGUMENT_SUBLEASE_EXEMPLE_2: 'argument-sublease-exemple-project',
  ARGUMENT_SUBLEASE_EXEMPLE_3: 'argument-sublease-exemple-holidays',
  INVENTORY_OUT_ISSUE: 'inventory-out-issue',
  GOOGLE_REVIEW: 'google-review',
  OLYMPIC_GAMES: 'olympic-games',
};

export const LEAVING_REASON = {
  FAMILY_TRAVEL: 'family-travel',
  BUSINESS_TRAVEL: 'business-travel',
  HOLIDAYS: 'holidays',
  STUDY_ABROAD: 'study-abroad',
  LINGUISTIC_STAY: 'linguistic-stay',
  OTHER: 'other',
};

export const ROOM_ITEM_BY_ROOM = {
  [ROOM_TYPE.BEDROOM]: [
    ROOM_ITEM_TYPE.BED_140,
    ROOM_ITEM_TYPE.BED_160,
    ROOM_ITEM_TYPE.BED_180,
    ROOM_ITEM_TYPE.BED_BUNK,
    ROOM_ITEM_TYPE.BED_SOLO,
    ROOM_ITEM_TYPE.BED_COUCH,
  ],
  [ROOM_TYPE.BATHROOM]: [
    ROOM_ITEM_TYPE.BATH,
    ROOM_ITEM_TYPE.SHOWER,
    ROOM_ITEM_TYPE.TOILETS,
  ],
  [ROOM_TYPE.LIVINGROOM]: [
    ROOM_ITEM_TYPE.BED_140,
    ROOM_ITEM_TYPE.BED_160,
    ROOM_ITEM_TYPE.BED_180,
    ROOM_ITEM_TYPE.BED_BUNK,
    ROOM_ITEM_TYPE.BED_SOLO,
    ROOM_ITEM_TYPE.BED_COUCH,
  ],
};

export const IDENTITY_CHECK_STATUS = {
  RECEIVED: 'checked',
  TO_CHECK: 'to-check',
  PENDING: 'unchecked',
};

export const GENDER = {
  MALE: 'male',
  FEMALE: 'female',
};

export const POLICIES = {
  PROSPECTS_LIST: 'prospects_list',
  PROSPECTS_UPDATE: 'prospects_update',
  PROSPECTS_DELETE: 'prospects_delete',
  LEAVINGS_GENERATE: 'leavings_generate',
  LEAVINGS_SIGN: 'leavings_sign',
  APARTMENTS_START_VISIT: 'apartments_start_visit',
  APARTMENTS_LIST: 'apartments_list',
  APARTMENTS_UPDATE: 'apartments_update',
  APARTMENTS_UPDATE_AFTER_SIGN: 'apartments_update_after_sign',
  LEAVINGS_SETUP: 'leavings_setup',
  LEAVINGS_UNSETUP: 'leavings_unsetup',
  PHOTO_CONTRACTS_LIST: 'photo-contracts_list',
  PHOTO_CONTRACTS_SCHEDULE: 'photo-contracts_schedule',
  PHOTO_CONTRACTS_MANAGE: 'photo-contracts_manage',
  HOSTRENT: 'hostrent',
  LEAVINGS_START_INVENTORY: 'leavings_start_inventory',
  BOOKINGS_LIST: 'bookings_list',
  BOOKINGS_MANAGE: 'bookings_manage',
  BOOKINGS_EXTRACT: 'bookings_extract',
  CLEANINGS_LIST: 'cleanings_list',
  CLEANINGS_UPDATE: 'cleanings_update',
  CLEANINGS_START: 'cleanings_start',
  CLEANINGS_UPDATE_UNRESTRICTED: 'cleanings_update_unrestricted',
  CLEANINGS_DELETE: 'cleanings_delete',
  CLEANINGS_ADD: 'cleanings_add',
  CLEANINGS_SET_USERS: 'cleanings_set_users',
  USERS_LIST: 'users_list',
  USERS_MANAGE: 'users_manage',
  CONTRACTOR_LIST: 'contractor_list',
  CONTRACTOR_MANAGE: 'contractor_manage',
  NEIGHBORHOOD: 'neighborhood',
  NEIGHBORHOOD_MANAGE: 'neighborhood_manage',
  AGENCIES: 'agencies',
  CITIES: 'cities',
};

export const INVENTORY_OBSERVATION_STATUS = {
  GOOD: 'good',
  NORMAL: 'normal',
  OUT_OF_ORDER: 'out-of-order',
  WORN: 'worn',
  SAME: 'same',
  CHANGED: 'changed',
  DAMAGED: 'damaged',
  NOT_FOUND: 'not-found',
};

export const INVENTORY_ROOM_TYPE = {
  LIVINGROOM: 'livingroom',
  KITCHEN: 'kitchen',
  BEDROOM: 'bedroom',
  BATHROOM: 'bathroom',
  ENTRANCE: 'entrance',
  HALL: 'hall',
  TOILETS: 'toilets',
  OTHERS: 'others',
};

export const INVENTORY_ROOM_OBSERVATION_TYPE = {
  CEILING: 'ceiling',
  FLOOR: 'floor',
  DOOR: 'door',
  GLOBAL: 'global',
  SHUTTER: 'shutter',
  LIGHT: 'light',
  WALL: 'wall',
  WINDOW: 'window',
};

// type
export const INVENTORY_KEY_TYPES = {
  APARTMENT_DOOR: 'apartment-door',
  BUILDING_BADGE: 'building-badge',
  TRASH_LOCAL: 'trash-local',
  MAILBOX: 'mail-box',
  CELLAR: 'cellar',
  CUSTOM: 'custom',
};

export const KEY_TAGS = {
  STORE_PROBLEM: 'store-problem',
  LOCK_PROBLEM: 'lock-problem',
  STORE_BLOCKED: 'store-blocked',
  WRONG_CODE: 'wrong-code',
  REPRODUCING: 'reproducing',
  COPY: 'copy',
} as const;

export const KEY_SITES = {
  LOST: 'lost',
  APARTMENT: 'apartment',
  CONTRACTOR: 'contractor',
  AGENCY: 'agency',
} as const;

export const KEY_ERROR_ENUM = {
  DELETED: 'deleted',
  KEYSITE_LOST: 'keysite-lost',
  KEYSITE_APARTMENT: 'keysite-apartment',
  NOT_DEPOSITED: 'not-deposited',
  KEPT: 'kept',
} as const;

export const PREREQUISITE_TYPES = {
  SMOKE_DETECTOR: 'smoke-detector',
  PILLOWS_AND_COMFORTERS: 'pillows-and-comforters',
  KEYS: 'keys',
  VACUUM_CLEANER: 'vacuum-cleaner',
  CLASSIC_TOILETS: 'classic-toilets',
} as const;

export const PREREQUISITE_STATUS = {
  CONFORM: 'conform',
  PENDING: 'pending',
  REFUSED: 'refused',
} as const;

export const TERM_PRICING_TYPE = {
  EARLY_PAYMENT: 'early-payment',
  INVENTORY_PAID: 'inventory-paid',
  CLEANING_PAID: 'cleaning-paid',
  KEY_PAID: 'key-paid',
  INSURANCE_PAID: 'insurance-paid',
};

export default {
  STATUS,
  ROLE,
  ROLE_GROUP,
  PROSPECT_ORIGIN,
  PROSPECT_STATUS,
  PROSPECT_VISIT_TYPE,
  LEAVING_STATUS,
  CHECKOUT_STATUS,
  IMPORTANCE,
  EVENT_TYPE,
  OWNERSHIP,
  RESIDENCE_TYPE,
  APARTMENT_RISKS,
  CONTRACTOR_TYPES,
  BOOKING_STATUS,
  BOOKING_TYPE,
  BOOKING_SOURCE,
  CLEANING_TYPES,
  CLEANING_CONTRACT_STATUS,
  ROOM_TYPE,
  ROOM_ITEM_TYPE,
  FORMAT_TYPE,
  PHOTO_CONTRACT_STATUS,
  PHOTO_CONTRACT_TYPE,
  PLATFORMS,
  KEYWORD_CATEGORY,
  HOST_RENT_TYPE,
  DEPOSIT_STATUS,
  HOST_RENT_ROW_TYPE,
  PROSPECT_SOURCE,
  HOUSING_TYPE,
  BOOKING_CHECK_STATUS,
  INTERNET_FACILITY,
  LANGUAGE,
  TRANSLATION_LANGUAGE,
  INVENTORY_STATUS,
  INVENTORY_TYPE,
  APARTMENT_KEY_TYPE,
  APARTMENT_KEY_STATUS,
  APARTMENT_KEY_DENOMINATION,
  DELIVERY_CONTRACTOR,
  KEY_SETUP_STATUS,
  LEAVING_LAST_CHECK_TYPE,
  LEAVING_LAST_CHECK_LEVEL,
  CLEANING_TAG,
  CLEANING_OBSERVATION_TYPE,
  CLEANING_OBSERVATION_STATUS,
  CLEANING_OBSERVATION_CHECKLIST_NAME,
  CLEANING_OBSERVATION_ROOM_TYPE,
  CLEANING_OBSERVATION_TODO_NAME,
  CLEANING_OBSERVATION_SKIP_REASON,
  HOST_RENT_SEPA_STATUS,
  HOST_RENT_SEPA_ACTIONS,
  ACCESS_STEP_TYPE,
  ACCESS_TYPE,
  SEMI_AUTO_MAIL_TYPE,
  LEAVING_REASON,
  IDENTITY_CHECK_STATUS,
  GENDER,
  KEY_SITES,
  KEY_ERROR_ENUM,
};
