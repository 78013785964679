import * as React from 'react';

import { GenericFormGroup } from '@components';

import './GenericRadioGroup.scss';

type RadioOption = { label: string };

type Options = Record<string, RadioOption>;
type Props = {
  options: Options;
  label?: JSX.Element | JSX.Element[];
  required?: boolean;
  value: any;
  horizontal?: boolean;
  onChange?: Function;
  className?: string;
  name?: string;
};

export default class GenericRadioGroup extends React.PureComponent<Props> {
  static defaultProps = {
    horizontal: false,
    required: false,
    onChange: () => {},
    className: '',
    name: '',
  };

  render() {
    const { horizontal, options, label, required, className } = this.props;

    if (!options) return null;

    return (
      <GenericFormGroup
        className={`GenericRadioGroup ${className || ''}`}
        required={required}
        label={label}
      >
        <div className="generic-radio-group">
          {horizontal ? this.renderHorizontal() : this.renderVertical()}
        </div>
      </GenericFormGroup>
    );
  }

  renderHorizontal = () => {
    const { options } = this.props;

    return (
      <div className="row">
        {Object.keys(options).map((key) => {
          return <div key={key}>{this.renderRadio(key)}</div>;
        })}
      </div>
    );
  };

  renderVertical = () => {
    const { options } = this.props;

    return (
      <React.Fragment>
        {Object.keys(options).map((key) => {
          return (
            <div key={key} className="row">
              {this.renderRadio(key)}
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  renderRadio = (key: string) => {
    const { options, value, name } = this.props;
    const option = options[key];

    return (
      <div className="custom-control custom-radio generic-radio-group__radio">
        <input
          {...option}
          className="custom-control-input generic-radio-group__radio__input"
          id={`${name}-${key}-radio`}
          onChange={this.handleChange}
          type="radio"
          value={key}
          checked={key === value}
        />
        <label
          htmlFor={`${name}-${key}-radio`}
          className="custom-control-label generic-radio-group__radio__label"
        >
          {option.label}
        </label>
      </div>
    );
  };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;

    if (onChange && typeof onChange === 'function')
      onChange(event && event.currentTarget && event.currentTarget.value);
  };
}
