import React from 'react';

import { SSmartLoader } from '@smartrenting/smartomic';

import './Loader.scss';

interface Props {
  text?: string;
  className?: string;
  style?: Object;
  scale?: number | string;
}

export function Loader({
  text = '',
  className = '',
  style = {},
  scale = 1,
}: Props) {
  const classNames = ['Loader', className].join(' ');

  return (
    <div style={{ ...style, zoom: scale }} className={classNames}>
      <SSmartLoader />
      {text}
    </div>
  );
}

export default Loader;
