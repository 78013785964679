import { handle } from 'redux-pack';
import { Action } from 'src/types/redux';

import { LOGOUT, RESET } from '@redux/me/actions';

import {
  WEBSOCKET_DELETE_NOTIFICATION,
  WEBSOCKET_NOTIFICATION,
} from '@redux/middlewares/webSocketMiddleware';

import { FETCH_NOTIFICATIONS } from './actions';

export default function notificationReducer(state: any = {}, action: Action) {
  const { type } = action;

  switch (type) {
    case FETCH_NOTIFICATIONS:
      return handle(state, action, {
        success: () => ({
          ...state,
          notifications: action.payload,
        }),
      });
    case WEBSOCKET_NOTIFICATION:
      return {
        ...state,
        notifications: [...new Set([action.payload, ...state.notifications])],
      };
    case WEBSOCKET_DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((notificationId) => {
          return notificationId !== action.payload;
        }),
      };

    case LOGOUT:
    case RESET:
      return {};
    default:
      return state;
  }
}
