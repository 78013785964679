export default class Guest {
  public address1 = '';
  public address2 = '';
  public bookingsyncId: number;
  public city = '';
  public company = '';
  public countryCode = '';
  public email = '';
  public firstname = '';
  public id: number;
  public lastname = '';
  public phoneNumber = '';
  public postalCode = '';
  public preferredLocale = '';
  public state = '';

  constructor(data: Partial<Guest>) {
    Object.assign(this, data);
  }

  getFullName = () => {
    return `${this.firstname} ${this.lastname}`;
  };
}
