import CONFIG from '@config';

import Room from '@redux/models/Room';

import * as schema from '@redux/schema';

export const ADD_ENTITIES = 'ADD_ENTITIES';
export const addEntities = (entities: Object) => ({
  type: ADD_ENTITIES,
  entities,
});

// SIMPLE

export const SIMPLE_FETCH_APARTMENT = 'SIMPLE_FETCH_APARTMENT';
export const simpleFetchApartment = (id: number) => ({
  type: SIMPLE_FETCH_APARTMENT,
  route: `/apartments/${id}`,
  method: 'GET',
  schema: schema.apartment,
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_PATCH_APARTMENT = 'SIMPLE_PATCH_APARTMENT';
export const simplePatchApartment = (
  id: number,
  apartmentModifications: Object,
) => ({
  type: SIMPLE_PATCH_APARTMENT,
  route: `/apartments/${id}`,
  method: 'PATCH',
  body: apartmentModifications,
  schema: schema.apartment,
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_PATCH_CONTACT = 'SIMPLE_PATCH_CONTACT';
export const simplePatchContact = (
  id: number,
  contactModifications: Object,
) => ({
  type: SIMPLE_PATCH_CONTACT,
  route: `/contacts/${id}`,
  method: 'PATCH',
  body: contactModifications,
  schema: schema.contact,
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_FETCH_LEAVING = 'SIMPLE_FETCH_LEAVING';
export const simpleFetchLeaving = (id: number) => ({
  type: SIMPLE_FETCH_LEAVING,
  route: `/leavings/${id}`,
  method: 'GET',
  schema: schema.leaving,
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_PATCH_LEAVING = 'SIMPLE_PATCH_LEAVING';
export const simplePatchLeaving = (
  id: number,
  leavingModifications: Object,
  hideLoading = false,
) => ({
  type: SIMPLE_PATCH_LEAVING,
  route: `/leavings/${id}`,
  method: 'PATCH',
  body: leavingModifications,
  schema: schema.leaving,
  hideLoading,
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_POST_ROOMS = 'SIMPLE_POST_ROOMS';
export const simplePostRooms = (
  apartmentId: number,
  rooms: Partial<Room>[],
) => ({
  type: SIMPLE_POST_ROOMS,
  route: `/apartments/${apartmentId}/rooms`,
  method: 'POST',
  body: rooms,
  schema: schema.apartment,
  meta: {
    apartmentId,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_POST_APARTMENT_FILE = 'SIMPLE_POST_APARTMENT_FILE';
export const simplePostApartmentFile = (
  id: number,
  file: Object,
  path: string,
) => ({
  type: SIMPLE_POST_APARTMENT_FILE,
  route: `/apartments/${id}/${path}`,
  method: 'POST',
  body: file,
  schema: schema.apartment,
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_DELETE_APARTMENT_FILE = 'SIMPLE_DELETE_APARTMENT_FILE';
export const simpleDeleteApartmentFile = (id: number, path: string) => ({
  type: SIMPLE_DELETE_APARTMENT_FILE,
  route: `/apartments/${id}/${path}`,
  method: 'DELETE',
  schema: schema.apartment,
  meta: {
    apartmentId: id,
    path,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_POST_APARTMENT_FILES = 'SIMPLE_POST_APARTMENT_FILES';
export const simplePostApartmentFiles = (
  id: number,
  files: Array<Object>,
  path: string,
) => ({
  type: SIMPLE_POST_APARTMENT_FILES,
  route: `/apartments/${id}/${path}`,
  method: 'POST',
  body: files,
  schema: schema.apartment,
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_DELETE_APARTMENT_FILES = 'SIMPLE_DELETE_APARTMENT_FILES';
export const simpleDeleteApartmentFiles = (
  id: number,
  path: string,
  documentId: number,
) => ({
  type: SIMPLE_DELETE_APARTMENT_FILES,
  route: `/apartments/${id}/${path}/${documentId}`,
  method: 'DELETE',
  schema: schema.apartment,
  meta: {
    apartmentId: id,
    path,
    documentId,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_POST_CONTACT_FILE = 'SIMPLE_POST_CONTACT_FILE';
export const simplePostContactFile = (
  id: number,
  file: FormData,
  path: string,
  apartmentId: number,
) => {
  file.append('apartmentId', String(apartmentId));

  return {
    type: SIMPLE_POST_CONTACT_FILE,
    route: `/contacts/${id}/${path}`,
    method: 'POST',
    body: file,
    schema: schema.contact,
    url: CONFIG.API_V2_URL,
  };
};

export const SIMPLE_DELETE_CONTACT_FILE = 'SIMPLE_DELETE_CONTACT_FILE';
export const simpleDeleteContactFile = (
  id: number,
  path: string,
  apartmentId: number,
) => ({
  type: SIMPLE_DELETE_CONTACT_FILE,
  route: `/contacts/${id}/${path}`,
  method: 'DELETE',
  schema: schema.contact,
  body: { apartmentId },
  meta: {
    contactId: id,
    path,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_POST_CONTACT_FILES = 'SIMPLE_POST_CONTACT_FILES';
export const simplePostContactFiles = (
  id: number,
  files: FormData,
  path: string,
  apartmentId: number,
) => {
  files.append('apartmentId', apartmentId as any);

  return {
    type: SIMPLE_POST_CONTACT_FILES,
    route: `/contacts/${id}/${path}`,
    method: 'POST',
    body: files,
    schema: schema.contact,
    url: CONFIG.API_V2_URL,
  };
};

export const SIMPLE_DELETE_CONTACT_FILES = 'SIMPLE_DELETE_CONTACT_FILES';
export const simpleDeleteContactFiles = (
  id: number,
  path: string,
  documentId: number,
  apartmentId: number,
) => ({
  type: SIMPLE_DELETE_CONTACT_FILES,
  route: `/contacts/${id}/${path}/${documentId}`,
  method: 'DELETE',
  body: {
    apartmentId,
  },
  schema: schema.contact,
  meta: {
    contactId: id,
    path,
    documentId,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_POST_LEAVING_FILE = 'SIMPLE_POST_LEAVING_FILE';
export const simplePostLeavingFile = (
  id: number,
  file: Object,
  path: string,
) => ({
  type: SIMPLE_POST_LEAVING_FILE,
  route: `/leavings/${id}/${path}`,
  method: 'POST',
  body: file,
  schema: schema.leaving,
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_DELETE_LEAVING_FILE = 'SIMPLE_DELETE_LEAVING_FILE';
export const simpleDeleteLeavingFile = (id: number, path: string) => ({
  type: SIMPLE_DELETE_LEAVING_FILE,
  route: `/leavings/${id}/${path}`,
  method: 'DELETE',
  schema: schema.leaving,
  meta: {
    leavingId: id,
    path,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_FETCH_PROSPECTS = 'SIMPLE_FETCH_PROSPECTS';
export const simpleFetchProspects = (
  offset?: number,
  limit?: number,
  more?: boolean,
  status: Array<string> = [],
  agencyId = '',
  saleId = '',
  text = '',
  order = '',
  isPaused = false,
  isFakeDate = false,
  rejectionMotive?: string,
  statusStart?: moment.Moment,
  statusEnd?: moment.Moment,
  eventStart?: moment.Moment,
  eventEnd?: moment.Moment,
) => ({
  type: SIMPLE_FETCH_PROSPECTS,
  route: '/prospects',
  method: 'GET',
  query: {
    offset,
    limit,
    status,
    agencyId,
    saleId,
    text,
    order,
    isPaused,
    isFakeDate,
    rejectionMotive,
    statusStart: statusStart ? statusStart.format('YYYY-MM-DD') : null,
    statusEnd: statusEnd ? statusEnd.format('YYYY-MM-DD') : null,
    eventStart: eventStart ? eventStart.format('YYYY-MM-DD') : null,
    eventEnd: eventEnd ? eventEnd.format('YYYY-MM-DD') : null,
  },
  url: CONFIG.API_V2_URL,
  meta: {
    more,
  },
});

export const SIMPLE_POST_LEAVING_LAST_CHECK = 'SIMPLE_POST_LEAVING_LAST_CHECK';
export const simplePostLeavingLastCheck = (
  leavingId: number,
  hideLoading = false,
) => ({
  type: SIMPLE_POST_LEAVING_LAST_CHECK,
  method: 'POST',
  route: `/leavings/${leavingId}/last-checks`,
  schema: schema.lastCheck,
  hideLoading,
  meta: {
    leavingId,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_DELETE_LEAVING_LAST_CHECK =
  'SIMPLE_DELETE_LEAVING_LAST_CHECK';
export const simpleDeleteLeavingLastCheck = (
  leavingId: number,
  hideLoading = false,
) => ({
  type: SIMPLE_DELETE_LEAVING_LAST_CHECK,
  method: 'DELETE',
  route: `/leavings/${leavingId}/last-checks`,
  schema: schema.lastCheck,
  hideLoading,
  meta: {
    leavingId,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_POST_LEAVING_LAST_CHECK_DOCUMENT =
  'SIMPLE_POST_LEAVING_LAST_CHECK_DOCUMENT';
export const simplePostLeavingLastCheckDocument = (
  lastCheckId: number,
  files: Object,
  hideLoading = false,
) => ({
  type: SIMPLE_POST_LEAVING_LAST_CHECK_DOCUMENT,
  method: 'POST',
  route: `/leaving-last-checks/${lastCheckId}/documents`,
  body: files,
  hideLoading,
  meta: {
    lastCheckId,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_DELETE_LEAVING_LAST_CHECK_DOCUMENT =
  'SIMPLE_DELETE_LEAVING_LAST_CHECK_DOCUMENT';
export const simpleDeleteLeavingLastCheckDocument = (
  lastCheckId: number,
  documentId: number,
) => ({
  type: SIMPLE_DELETE_LEAVING_LAST_CHECK_DOCUMENT,
  method: 'DELETE',
  route: `/leaving-last-checks/${lastCheckId}/documents/${documentId}`,
  meta: {
    lastCheckId,
    documentId,
  },
  url: CONFIG.API_V2_URL,
});

export const SIMPLE_PATCH_IDENTITY_DOCUMENT = 'SIMPLE_PATCH_IDENTITY_DOCUMENT';
export const simplePatchIdentityDocument = (id: number, file: FormData) => ({
  type: SIMPLE_PATCH_IDENTITY_DOCUMENT,
  route: `/bookings/${id}/identity-document`,
  method: 'PATCH',
  body: file,
  schema: schema.booking,
  url: CONFIG.API_V2_URL,
});

export const POST_SEMI_AUTO_MAIL_DOCUMENT = 'POST_SEMI_AUTO_MAIL_DOCUMENT';
export const postSemiAutoMailDocuments = (files: FormData) => ({
  type: POST_SEMI_AUTO_MAIL_DOCUMENT,
  route: `/documents/mail-send-documents`,
  method: 'POST',
  body: files,
  url: CONFIG.API_V2_URL,
});
