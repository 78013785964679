import CONFIG from '@config';

import * as schema from '@redux/schema';

export const FETCH_CITIES = 'FETCH_CITIES';
export const fetchCities = (
  offset = 0,
  limit = 10,
  text?: string,
  agencyId?: number,
  more = false,
) => ({
  type: FETCH_CITIES,
  route: `/cities`,
  method: 'GET',
  query: {
    limit,
    offset,
    text,
    agencyId: agencyId || '',
  },
  meta: {
    more,
  },
  hideLoading: true,
  schema: [schema.city],
  url: CONFIG.API_V2_URL,
});

export const POST_CITY = 'POST_CITY';
export const postCity = (data: Object) => ({
  type: POST_CITY,
  route: `/cities`,
  method: 'POST',
  body: data,
  url: CONFIG.API_V2_URL,
});

export const PATCH_CITY = 'PATCH_CITY';
export const patchCity = (id: number, data: Object) => ({
  type: PATCH_CITY,
  route: `/cities/${id}`,
  method: 'PATCH',
  body: data,
  url: CONFIG.API_V2_URL,
});
