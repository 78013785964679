import moment from 'moment-timezone';
import { ApartmentKeyLocation } from 'src/types/global';
import { Dispatch, GetState } from 'src/types/redux';

import CONFIG from '@config';

import ENUM from '@enum';

import Apartment from '@redux/models/Apartment';
import LastCheck from '@redux/models/LastCheck';
import { LeavingActionType } from '@redux/models/Leaving';
import Modality from '@redux/models/Modality';
import { Prerequisite } from '@redux/models/Prerequisite';

import {
  postFirstCleaning,
  patchCleaningContract,
  cancelCleaningContract,
  postLastCleaning,
} from '@redux/cleaningContracts/actions';
import {
  simpleFetchApartment,
  simplePatchApartment,
  simplePatchContact,
  simplePatchLeaving,
  simplePostLeavingFile,
  simplePostApartmentFile,
  simplePostApartmentFiles,
  simplePostContactFile,
  simplePostContactFiles,
  simpleDeleteApartmentFile,
  simpleDeleteApartmentFiles,
  simpleDeleteContactFile,
  simpleDeleteContactFiles,
  simpleDeleteLeavingFile,
  simplePostLeavingLastCheck,
  simpleDeleteLeavingLastCheck,
  simplePostLeavingLastCheckDocument,
  simpleDeleteLeavingLastCheckDocument,
  simpleFetchLeaving,
} from '@redux/entities/actions';

import { getApartmentDetails } from '@redux/apartments/selectors';

import * as schema from '@redux/schema';

import { IFetchLeavingWithKeys, IFetchLeavingsToPublish } from './types';

export const FETCH_APARTMENTS = 'FETCH_APARTMENTS';
export const fetchApartments = (
  offset?: number,
  limit?: number,
  more = false,
  agencyId = '',
  ongoing = false,
  text = '',
  capacity = '',
  areaMin = '',
  areaMax = '',
  category = '',
  order = '',
  cleaningContractorId?: number,
) => ({
  type: FETCH_APARTMENTS,
  route: '/apartments',
  method: 'GET',
  query: {
    offset,
    limit,
    agencyId,
    ongoing,
    text,
    capacity,
    areaMin,
    areaMax,
    category,
    order,
    cleaningContractorId,
  },
  meta: {
    more,
  },
  schema: [schema.apartment],
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_APARTMENTS_COUNT = 'FETCH_APARTMENTS_COUNT';
export const fetchApartmentsCount = (
  agencyId = '',
  ongoing = false,
  text = '',
  capacity = '',
  areaMin = '',
  areaMax = '',
  category = '',
  order = '',
  cleaningContractorId?: number,
) => ({
  type: FETCH_APARTMENTS_COUNT,
  route: '/apartments/count',
  method: 'GET',
  query: {
    agencyId,
    ongoing,
    text,
    capacity,
    areaMin,
    areaMax,
    category,
    order,
    cleaningContractorId,
  },
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_APARTMENT_ANALYTICS = 'FETCH_APARTMENT_ANALYTICS';
export const fetchApartmentAnalytics = (
  id: number,
  startDate: moment.Moment,
  endDate: moment.Moment,
) => ({
  type: FETCH_APARTMENT_ANALYTICS,
  method: 'GET',
  route: `/apartments/${id}/analytics`,
  query: {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  },
  url: CONFIG.API_V2_URL,
});

export const FETCH_APARTMENT = 'FETCH_APARTMENT';
export const fetchApartment = (id: number) => ({
  ...simpleFetchApartment(id),
  type: FETCH_APARTMENT,
});

export const FETCH_APARTMENT_POPOVER = 'FETCH_APARTMENT_POPOVER';
export const fetchApartmentPopover = (id: number) => ({
  ...simpleFetchApartment(id),
  type: FETCH_APARTMENT_POPOVER,
  hideLoading: true,
});

export const SET_FOCUSED_LEAVING_CHECKIN_ID = 'SET_FOCUSED_LEAVING_CHECKIN_ID';
export const setFocusedLeavingCheckinId = (id: number) => ({
  type: SET_FOCUSED_LEAVING_CHECKIN_ID,
  payload: id,
});

export const FETCH_APARTMENT_CHECKIN = 'FETCH_APARTMENT_CHECKIN';
export const fetchApartmentCheckin = (id: number, hideLoading = false) => ({
  ...simpleFetchApartment(id),
  type: FETCH_APARTMENT_CHECKIN,
  hideLoading,
});

export const SET_FOCUSED_LEAVING_CHECKOUT_ID =
  'SET_FOCUSED_LEAVING_CHECKOUT_ID';
export const setFocusedLeavingCheckoutId = (id: number) => ({
  type: SET_FOCUSED_LEAVING_CHECKOUT_ID,
  payload: id,
});

export const FETCH_APARTMENT_CHECKOUT = 'FETCH_APARTMENT_CHECKOUT';
export const fetchApartmentCheckout = (id: number) => ({
  ...simpleFetchApartment(id),
  type: FETCH_APARTMENT_CHECKOUT,
});

export const FETCH_APARTMENT_ACTIVITY = 'FETCH_APARTMENT_ACTIVITY';
export const fetchApartmentActivity = (id: number, hideLoading = false) => ({
  type: FETCH_APARTMENT_ACTIVITY,
  route: `/apartments/${id}/activities`,
  method: 'GET',
  hideLoading,
  url: CONFIG.API_V2_URL,
});

export const FETCH_LEAVINGS_TO_CHECKIN = 'FETCH_LEAVINGS_TO_CHECKIN';
export const fetchLeavingsToCheckin = (
  offset?: number,
  limit?: number,
  more = false,
  withSetup: boolean = undefined,
  agencyId = '',
  customerExperienceId = '',
  text = '',
  order = '',
) => {
  let query: Record<string, any> = {
    offset,
    limit,
    agencyId,
    customerExperienceId,
    text,
    order,
  };

  if (!withSetup) query = { ...query, isCheckinReady: false };

  return {
    type: FETCH_LEAVINGS_TO_CHECKIN,
    route: `/leavings/checkin`,
    method: 'GET',
    query,
    meta: {
      more,
    },
    schema: [schema.leaving],
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_LEAVING_CHECKOUT_SCORE = 'FETCH_LEAVING_CHECKOUT_SCORE';
export const fetchLeavingCheckoutScore = (id: number) => {
  return {
    type: FETCH_LEAVING_CHECKOUT_SCORE,
    route: `/leavings/${id}/checkout-score`,
    method: 'GET',
    meta: {
      id,
    },
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_LEAVING_CHECKIN_SCORE = 'FETCH_LEAVING_CHECKIN_SCORE';
export const fetchLeavingCheckinScore = (id: number) => {
  return {
    type: FETCH_LEAVING_CHECKIN_SCORE,
    route: `/leavings/${id}/checkin-score`,
    method: 'GET',
    meta: {
      id,
    },
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_LEAVINGS_TO_CHECKOUT = 'FETCH_LEAVINGS_TO_CHECKOUT';
export const fetchLeavingsToCheckout = (
  offset?: number,
  limit?: number,
  more = false,
  withUnsetup: boolean = undefined,
  agencyId = '',
  customerExperienceId = '',
  text = '',
  order = '',
) => {
  let query: Record<string, any> = {
    offset,
    limit,
    agencyId,
    customerExperienceId,
    text,
    order,
  };

  if (!withUnsetup) query = { ...query, isCheckoutReady: false };

  return {
    type: FETCH_LEAVINGS_TO_CHECKOUT,
    route: `/leavings/checkout`,
    method: 'GET',
    query,
    meta: {
      more,
    },
    schema: [schema.leaving],
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_LEAVINGS_INVENTORIES = 'FETCH_LEAVINGS_INVENTORIES';
export const fetchLeavingsInventories = (
  offset?: number,
  limit?: number,
  more = false,
  agencyId = '',
  text = '',
) => {
  let query: Record<string, any> = {
    offset,
    limit,
    agencyId,
    text,
  };

  return {
    type: FETCH_LEAVINGS_INVENTORIES,
    route: `/leavings/inventories`,
    method: 'GET',
    query,
    meta: {
      more,
    },
    schema: [schema.leaving],
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_WAITING_LEAVINGS_INVENTORIES =
  'FETCH_WAITING_LEAVINGS_INVENTORIES';
export const fetchWaitingLeavingsInventories = (agencyId = '', text = '') => {
  let query: Record<string, any> = {
    agencyId,
    text,
  };

  return {
    type: FETCH_WAITING_LEAVINGS_INVENTORIES,
    route: `/leavings/waiting-inventories`,
    method: 'GET',
    query,
    schema: [schema.leaving],
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_APARTMENT_PENDING_LEAVINGS =
  'FETCH_APARTMENT_PENDING_LEAVINGS';
export const fetchApartmentPendingLeavings = (
  offset = 0,
  limit = 10,
  more: boolean,
  agencyId?: number,
  saleId?: number,
  text = '',
) => {
  return {
    type: FETCH_APARTMENT_PENDING_LEAVINGS,
    route: `/leavings/pending`,
    method: 'GET',
    query: {
      offset,
      limit,
      agencyId: agencyId || '',
      saleId: saleId || '',
      text,
    },
    meta: {
      more,
    },
    schema: [schema.leaving],
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_APARTMENT_BOOKINGS = 'FETCH_APARTMENT_BOOKINGS';
export const fetchApartmentBookings = (
  id: number,
  offset = 0,
  limit = 10,
  order = '',
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  activeOnly = false,
) => ({
  type: FETCH_APARTMENT_BOOKINGS,
  route: `/apartments/${id}/bookings`,
  method: 'GET',
  query: {
    offset,
    limit,
    order,
    startDate,
    endDate,
    activeOnly,
  },
  schema: [schema.booking],
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const FETCH_APARTMENT_CLEANINGS = 'FETCH_APARTMENT_CLEANINGS';
export const fetchApartmentCleanings = (
  id: number,
  offset = 0,
  limit = 10,
  order = '',
  startDate?: moment.Moment,
  endDate?: moment.Moment,
) => ({
  type: FETCH_APARTMENT_CLEANINGS,
  route: `/apartments/${id}/cleanings`,
  method: 'GET',
  query: {
    offset,
    limit,
    order,
    startDate,
    endDate,
  },
  schema: [schema.cleaningContract],
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const PATCH_APARTMENT = 'PATCH_APARTMENT';
export const patchApartment = (
  id: number,
  apartmentModifications: Partial<Record<keyof Apartment, any>>,
  hideLoading = false,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        ...simplePatchApartment(id, apartmentModifications),
        hideLoading,
      }),
    )
    .tap(() => dispatch(fetchApartmentActivity(id)));
};

export const RESET_GEOCODE = 'RESET_GEOCODE';
export const resetGeocode = (apartmentId: number) => (dispatch?: Dispatch) => {
  return Promise.resolve().then(() =>
    dispatch({
      type: POST_SETUP_LEAVING,
      route: `/apartments/${apartmentId}/reset-geocode`,
      method: 'POST',
      hideLoading: true,
      schema: schema.apartment,
      url: CONFIG.API_V2_URL,
    }),
  );
};

export const PATCH_LEAVING = 'PATCH_LEAVING';
export const patchLeaving = (id: number, leavingModifications: Object) => (
  dispatch?: Dispatch,
  getState?: Function,
) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simplePatchLeaving(id, leavingModifications)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const PATCH_CONTACT = 'PATCH_CONTACT';
export const patchContact = (id: number, contactModifications: Object) => (
  dispatch?: Dispatch,
  getState?: Function,
) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simplePatchContact(id, contactModifications)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const PATCH_APARTMENT_CLEANING_CONTRACT =
  'PATCH_APARTMENT_CLEANING_CONTRACT';
export const patchApartmentCleaningContract = (
  id: number,
  contractModifications: Object,
) => (dispatch?: Dispatch, getState?: Function) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(patchCleaningContract(id, contractModifications)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_APARTMENT_LEAVING_PLANNING =
  'POST_APARTMENT_LEAVING_PLANNING';
export const postApartmentLeavingPlanning = (
  id: number,
  apartmentModifications: Object,
) => (dispatch?: Dispatch, getState?: Function) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() =>
      dispatch({
        type: POST_APARTMENT_LEAVING_PLANNING,
        method: 'POST',
        route: `/leavings/${id}/planning`,
        body: {
          ...apartmentModifications,
        },
        meta: {
          apartmentId,
        },
        schema: schema.leaving,
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_APARTMENT_LEAVING = 'POST_APARTMENT_LEAVING';
export const postApartmentLeaving = (
  id: number,
  apartmentModifications: Object,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: POST_APARTMENT_LEAVING,
        method: 'POST',
        route: `/apartments/${id}/leavings`,
        body: {
          ...apartmentModifications,
        },
        meta: {
          apartmentId: id,
        },
        schema: schema.leaving,
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchApartmentActivity(id)));
};

export const POST_MANDATE_TOSIGN_MAIL_LEAVING =
  'POST_MANDATE_TOSIGN_MAIL_LEAVING';
export const postMandateToSignMailLeaving = (id: number, isRenewal = false) => (
  dispatch?: Dispatch,
) => {
  return Promise.resolve().then(() =>
    dispatch({
      type: POST_SETUP_LEAVING,
      route: `/leavings/${id}/mandate-tosign-mail`,
      method: 'POST',
      hideLoading: true,
      url: CONFIG.API_V2_URL,
      body: {
        isRenewal,
      },
    }),
  );
};

export const POST_SETUP_LEAVING = 'POST_SETUP_LEAVING';
export const postSetupLeaving = (id: number) => (
  dispatch?: Dispatch,
  getState?: Function,
) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(completeSetupLeaving(id)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_UNSETUP_LEAVING = 'POST_UNSETUP_LEAVING';
export const postUnSetupLeaving = (id: number) => (
  dispatch?: Dispatch,
  getState?: Function,
) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(completeUnSetupLeaving(id)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_ARCHIVE_LEAVING = 'POST_ARCHIVE_LEAVING';
export const postArchiveLeaving = (id: number) => (
  dispatch?: Dispatch,
  getState?: Function,
) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: POST_ARCHIVE_LEAVING,
        route: `/leavings/${id}/archived`,
        method: 'POST',
        hideLoading: true,
        schema: schema.leaving,
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => {
      const { id: apartmentId } = getApartmentDetails(getState());

      return dispatch(fetchApartmentActivity(apartmentId));
    });
};

export const POST_CANCEL_MANDATE = 'POST_CANCEL_MANDATE';
export const postCancelMandate = (id: number) => (
  dispatch?: Dispatch,
  getState?: Function,
) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: POST_CANCEL_MANDATE,
        route: `/leavings/${id}/canceled`,
        method: 'POST',
        hideLoading: true,
        schema: schema.leaving,
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => {
      const { id: apartmentId } = getApartmentDetails(getState());

      return dispatch(fetchApartmentActivity(apartmentId));
    });
};

export const POST_MANUAL_SIGNED_LEAVING = 'POST_MANUAL_SIGNED_LEAVING';
export const postManualSignedLeaving = (id: number, file: Object) => (
  dispatch?: Dispatch,
  getState?: Function,
) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simplePostLeavingFile(id, file, 'signedmandate')))
    .tap(() => dispatch(fetchApartment(apartmentId)));
};

export const POST_BOOKINGSYNC_APARTMENT = 'POST_BOOKINGSYNC_APARTMENT';
export const postBookingsyncApartment = (id: number) => ({
  type: POST_BOOKINGSYNC_APARTMENT,
  route: `/apartments/${id}/bookingsync`,
  method: 'POST',
  hideLoading: true,
  schema: schema.apartment,
  url: CONFIG.API_V2_URL,
});

export const PATCH_BOOKINGSYNC_APARTMENT = 'PATCH_BOOKINGSYNC_APARTMENT';
export const patchBookingsyncApartment = (
  id: number,
  bookingsyncId: number,
) => ({
  type: PATCH_BOOKINGSYNC_APARTMENT,
  route: `/apartments/${id}/bookingsync`,
  method: 'PATCH',
  hideLoading: true,
  body: {
    bookingsyncId,
  },
  schema: schema.apartment,
  url: CONFIG.API_V2_URL,
});

export const POST_BOOKINGSYNC_SYNCHRONIZE_APARTMENT =
  'POST_BOOKINGSYNC_SYNCHRONIZE_APARTMENT';
export const postBookingsyncSynchronizeApartment = (id: number) => ({
  type: POST_BOOKINGSYNC_SYNCHRONIZE_APARTMENT,
  route: `/apartments/${id}/bookingsync/synchronize`,
  method: 'POST',
  schema: schema.apartment,
  url: CONFIG.API_V2_URL,
});

export const POST_UNARCHIVE_APARTMENT = 'POST_UNARCHIVE_APARTMENT';
export const postUnarchiveApartment = (id: number) => ({
  type: POST_UNARCHIVE_APARTMENT,
  route: `/apartments/${id}/unarchive`,
  method: 'POST',
  hideLoading: false,
  schema: schema.apartment,
  url: CONFIG.API_V2_URL,
});

export const POST_APARTMENT_COMMENT = 'POST_APARTMENT_COMMENT';
export const postApartmentComment = (id: number, comment: string) => (
  dispatch?: Dispatch,
) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: POST_APARTMENT_COMMENT,
        route: `/apartments/${id}/comments`,
        method: 'POST',
        body: {
          comment,
        },
        meta: {
          apartmentId: id,
        },
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchApartmentActivity(id)));
};

export const POST_APARTMENT_APT_FILE = 'POST_APARTMENT_APT_FILE';
export const postApartmentAptFile = (
  id: number,
  file: Object,
  path: string,
) => (dispatch: Dispatch, getState: GetState) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simplePostApartmentFile(id, file, path)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_APARTMENT_APT_FILES = 'POST_APARTMENT_APT_FILES';
export const postApartmentAptFiles = (
  id: number,
  files: Array<Object>,
  path: string,
) => (dispatch: Dispatch, getState: GetState) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simplePostApartmentFiles(id, files, path)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const DELETE_APARTMENT_APT_FILE = 'DELETE_APARTMENT_APT_FILE';
export const deleteApartmentAptFile = (id: number, path: string) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simpleDeleteApartmentFile(id, path)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const DELETE_APARTMENT_APT_FILES = 'DELETE_APARTMENT_APT_FILES';
export const deleteApartmentAptFiles = (
  id: number,
  path: string,
  documentId: number,
) => (dispatch: Dispatch, getState: GetState) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simpleDeleteApartmentFiles(id, path, documentId)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_APARTMENT_CONTACT_FILE = 'POST_APARTMENT_CONTACT_FILE';
export const postApartmentContactFile = (
  id: number,
  file: FormData,
  path: string,
) => (dispatch: Dispatch, getState: GetState) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simplePostContactFile(id, file, path, apartmentId)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_APARTMENT_CONTACT_FILES = 'POST_APARTMENT_CONTACT_FILES';
export const postApartmentContactFiles = (
  id: number,
  files: FormData,
  path: string,
) => (dispatch: Dispatch, getState: GetState) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simplePostContactFiles(id, files, path, apartmentId)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const DELETE_APARTMENT_CONTACT_FILE = 'DELETE_APARTMENT_CONTACT_FILE';
export const deleteApartmentContactFile = (id: number, path: string) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simpleDeleteContactFile(id, path, apartmentId)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const DELETE_APARTMENT_CONTACT_FILES = 'DELETE_APARTMENT_CONTACT_FILES';
export const deleteApartmentContactFiles = (
  id: number,
  path: string,
  documentId: number,
) => (dispatch: Dispatch, getState: GetState) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() =>
      dispatch(simpleDeleteContactFiles(id, path, documentId, apartmentId)),
    )
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_APARTMENT_LEAVING_FILE = 'POST_APARTMENT_LEAVING_FILE';
export const postApartmentLeavingFile = (
  id: number,
  file: Object,
  path: string,
) => (dispatch: Dispatch, getState: GetState) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simplePostLeavingFile(id, file, path)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_APARTMENT_FIRST_CLEANING = 'POST_APARTMENT_FIRST_CLEANING';
export const postApartmentFirstCleaning = (
  id: number,
  date: moment.Moment,
  contractorId?: number,
) => (dispatch: Dispatch) => {
  return Promise.resolve()
    .then(() => dispatch(postFirstCleaning(id, date, contractorId)))
    .tap(() => dispatch(fetchLeavingCheckinScore(id)));
};

export const CANCEL_APARTMENT_FIRST_CLEANING =
  'CANCEL_APARTMENT_FIRST_CLEANING';
export const cancelFirstCleaning = (cleaningId: number, leavingId: number) => (
  dispatch?: Dispatch,
) => {
  return Promise.resolve()
    .then(() => dispatch(cancelCleaningContract(cleaningId)))
    .tap(() => dispatch(fetchLeavingCheckinScore(leavingId)));
};

export const CANCEL_APARTMENT_LAST_CLEANING = 'CANCEL_APARTMENT_LAST_CLEANING';
export const cancelLastCleaning = (cleaningId: number, leavingId: number) => (
  dispatch?: Dispatch,
) => {
  return Promise.resolve()
    .then(() => dispatch(cancelCleaningContract(cleaningId)))
    .tap(() => dispatch(fetchLeavingCheckoutScore(leavingId)));
};

export const POST_APARTMENT_LAST_CLEANING = 'POST_APARTMENT_LAST_CLEANING';
export const postApartmentLastCleaning = (
  id: number,
  date: moment.Moment,
  contractorId?: number,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() => dispatch(postLastCleaning(id, date, contractorId)))
    .tap(() => dispatch(fetchLeavingCheckinScore(id)));
};

export const DELETE_APARTMENT_LEAVING_FILE = 'DELETE_APARTMENT_LEAVING_FILE';
export const deleteApartmentLeavingFile = (id: number, path: string) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const { id: apartmentId } = getApartmentDetails(getState());

  return Promise.resolve()
    .then(() => dispatch(simpleDeleteLeavingFile(id, path)))
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const SET_APARTMENTS_LIST_TEXT_FILTER =
  'SET_APARTMENTS_LIST_TEXT_FILTER';
export const setApartmentsListTextFilter = (text: string) => ({
  type: SET_APARTMENTS_LIST_TEXT_FILTER,
  text,
});

export const SET_APARTMENTS_LIST_CAPACITY_FILTER =
  'SET_APARTMENTS_LIST_CAPACITY_FILTER';
export const setApartmentsListCapacityFilter = (capacity: number) => ({
  type: SET_APARTMENTS_LIST_CAPACITY_FILTER,
  capacity,
});

export const SET_APARTMENTS_LIST_AREAMIN_FILTER =
  'SET_APARTMENTS_LIST_AREAMIN_FILTER';
export const setApartmentsListAreaMinFilter = (areaMin: number) => ({
  type: SET_APARTMENTS_LIST_AREAMIN_FILTER,
  areaMin,
});

export const SET_APARTMENTS_LIST_AREAMAX_FILTER =
  'SET_APARTMENTS_LIST_AREAMAX_FILTER';
export const setApartmentsListAreaMaxFilter = (areaMax: number) => ({
  type: SET_APARTMENTS_LIST_AREAMAX_FILTER,
  areaMax,
});

export const SET_APARTMENTS_LIST_CATEGORY_FILTER =
  'SET_APARTMENTS_LIST_CATEGORY_FILTER';
export const setApartmentsListCategoryFilter = (category: string) => ({
  type: SET_APARTMENTS_LIST_CATEGORY_FILTER,
  category,
});

export const SET_CHECKIN_TEXT_FILTER = 'SET_CHECKIN_TEXT_FILTER';
export const setCheckinTextFilter = (text: string) => ({
  type: SET_CHECKIN_TEXT_FILTER,
  text,
});

export const SET_CHECKOUT_TEXT_FILTER = 'SET_CHECKOUT_TEXT_FILTER';
export const setCheckoutTextFilter = (text: string) => ({
  type: SET_CHECKOUT_TEXT_FILTER,
  text,
});

export const POST_APARTMENT_BOOKING = 'POST_APARTMENT_BOOKING';
export const postApartmentBooking = (
  apartmentId: number,
  shouldBeSyncNow: boolean,
  startDate: moment.Moment,
  endDate: moment.Moment,
  type: string,
  chargeHost?: boolean,
  notes?: string,
  finalPrice?: number,
  adults?: number,
  children?: number,
  firstname?: string,
  lastname?: string,
  email?: string,
  phoneNumber?: string,
  expectedCheckinTime?: moment.Moment,
  expectedCheckoutTime?: moment.Moment,
) => {
  return {
    type: POST_APARTMENT_BOOKING,
    route: `/apartments/${apartmentId}/bookings`,
    method: 'POST',
    hideLoading: false,
    body: {
      shouldBeSyncNow,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      type,
      ...(type === ENUM.BOOKING_TYPE.MAINTENANCE
        ? { chargeHost: !!chargeHost }
        : {}),
      notes,
      ...(type === ENUM.BOOKING_TYPE.BOOKING
        ? {
            finalPrice,
            guestsCount: {
              adults,
              children,
            },
            guest: {
              firstname,
              lastname,
              email,
              phoneNumber,
            },
            expectedCheckinTime:
              expectedCheckinTime && expectedCheckinTime.format('LT'),
            expectedCheckoutTime:
              expectedCheckoutTime && expectedCheckoutTime.format('LT'),
          }
        : {}),
    },
    url: CONFIG.API_V2_URL,
  };
};

export const POST_APARTMENT_KEYWORDS = 'POST_APARTMENT_KEYWORDS';
export const postApartmentKeywords = (
  apartmentId: number,
  keywords: Array<number>,
) => {
  return {
    type: POST_APARTMENT_KEYWORDS,
    route: `/apartments/${apartmentId}/keywords`,
    method: 'POST',
    schema: schema.apartment,
    hideLoading: false,
    body: {
      keywords,
    },
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_APARTMENT_GENERATED_DESCRIPTION =
  'FETCH_APARTMENT_GENERATED_DESCRIPTION';
export const fetchApartmentGeneratedDescription = (
  apartmentId: number,
  keywords: Array<number>,
) => {
  return {
    type: FETCH_APARTMENT_GENERATED_DESCRIPTION,
    route: `/apartments/${apartmentId}/announcement`,
    method: 'GET',
    hideLoading: false,
    query: {
      keywords,
    },
    url: CONFIG.API_V2_URL,
  };
};

export const POST_APARTMENT_KEY = 'POST_APARTMENT_KEY';
export const postApartmentKey = (
  apartmentId: number,
  denomination: string,
  type?: string,
  location?: ApartmentKeyLocation,
  keyName?: string,
  badgeCode?: string,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: POST_APARTMENT_KEY,
        route: `/apartments/${apartmentId}/keys`,
        method: 'POST',
        schema: schema.apartment,
        hideLoading: false,
        body: {
          denomination,
          type,
          locationId: location && location.id,
          locationName: location && location.name,
          locationAddress: location && location.address,
          locationTimeSchedule: location && location.openingHours,
          keyName,
          badgeCode,
        },
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const DELETE_APARTMENT_KEY = 'DELETE_APARTMENT_KEY';
export const deleteApartmentKey = (keyId: number, apartmentId: number) => (
  dispatch?: Dispatch,
) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: DELETE_APARTMENT_KEY,
        route: `/apartment-keys/${keyId}`,
        method: 'DELETE',
        hideLoading: false,
        meta: {
          keyId,
          apartmentId,
        },
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_APARTMENT_RENTAL_AGENCY = 'POST_APARTMENT_RENTAL_AGENCY';
export const postApartmentRentalAgency = (
  apartmentId: number,
  rentalAgencyData: {
    name: string;
    contactName: string;
    address: string;
    email: string;
    phoneNumber: string;
    description: string;
  },
) => {
  return {
    type: POST_APARTMENT_RENTAL_AGENCY,
    route: `/apartments/${apartmentId}/rental-agency`,
    method: 'POST',
    hideLoading: false,
    schema: schema.apartment,
    body: rentalAgencyData,
    url: CONFIG.API_V2_URL,
  };
};

export const PATCH_APARTMENT_RENTAL_AGENCY = 'PATCH_APARTMENT_RENTAL_AGENCY';
export const patchApartmentRentalAgency = (
  rentalAgencyId: number,
  rentalAgencyData: Partial<{
    name: string;
    contactName: string;
    address: string;
    email: string;
    phoneNumber: string;
    description: string;
  }>,
) => {
  return {
    type: PATCH_APARTMENT_RENTAL_AGENCY,
    route: `/rental-agencies/${rentalAgencyId}`,
    schema: schema.rentalAgency,
    method: 'PATCH',
    hideLoading: false,
    body: rentalAgencyData,
    url: CONFIG.API_V2_URL,
  };
};

export const DELETE_APARTMENT_RENTAL_AGENCY = 'DELETE_APARTMENT_RENTAL_AGENCY';
export const deleteApartmentRentalAgency = (apartmentId: number) => {
  return {
    type: DELETE_APARTMENT_RENTAL_AGENCY,
    route: `/apartments/${apartmentId}/rental-agency`,
    schema: schema.apartment,
    method: 'DELETE',
    hideLoading: false,
    url: CONFIG.API_V2_URL,
    meta: {
      apartmentId,
    },
  };
};

export const POST_APARTMENT_HIGHLIGHT_ACTIVITY =
  'POST_APARTMENT_HIGHLIGHT_ACTIVITY';
export const postApartmentHighlightActivity = (
  apartmentId: number,
  activityId: number,
) => ({
  type: POST_APARTMENT_HIGHLIGHT_ACTIVITY,
  schema: schema.apartment,
  route: `/apartments/${apartmentId}/highlighted-activities`,
  method: 'POST',
  body: {
    activityId,
  },
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const POST_LEAVING_INVENTORY = 'POST_LEAVING_INVENTORY';
export const postLeavingInventory = (leavingId: number, type: string) => ({
  type: POST_LEAVING_INVENTORY,
  route: `/leavings/${leavingId}/inventories`,
  method: 'POST',
  body: {
    type,
  },
  meta: {
    leavingId,
    type,
  },
  schema: schema.inventory,
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const FETCH_INVENTORY_URL = 'FETCH_INVENTORY_URL';
export const fetchInventoryUrl = (inventoryId: number) => ({
  type: FETCH_INVENTORY_URL,
  route: `/inventories/${inventoryId}/url`,
  method: 'GET',
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const PATCH_APARTMENT_TRANSLATED_TEXT =
  'PATCH_APARTMENT_TRANSLATED_TEXT';
export const putApartmentTranslatedText = (
  apartmentId: number,
  translatedTextId: number,
  text: string,
  sourceLanguage: string,
  updatedField: string,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: PATCH_APARTMENT_TRANSLATED_TEXT,
        route: `/apartments/${apartmentId}/translated-text/${translatedTextId}`,
        method: 'PATCH',
        hideLoading: false,
        body: {
          text,
          sourceLanguage,
          updatedField,
        },
        schema: schema.translatedText,
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)))
    .then(() => dispatch(fetchApartment(apartmentId)));
};

export const FETCH_APARTMENT_KEY_LOCATION = 'FETCH_APARTMENT_KEY_LOCATION';
export const fetchApartmentKeyLocation = (
  apartmentId: number,
  type: string,
) => ({
  type: FETCH_APARTMENT_KEY_LOCATION,
  method: 'GET',
  route: `/apartments/${apartmentId}/key-locations`,
  query: {
    type,
  },
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_APARTMENT_ACCESS_KEY_INFOS =
  'FETCH_APARTMENT_ACCESS_KEY_INFOS';
export const fetchApartmentAccessKeyInfos = (
  apartmentKeyIds: Array<number>,
) => ({
  type: FETCH_APARTMENT_ACCESS_KEY_INFOS,
  method: 'GET',
  route: `/access-key-infos`,
  query: {
    apartmentKeyIds,
  },
  schema: [schema.accessKeyInfo],
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_APARTMENT_KEYS = 'FETCH_APARTMENT_KEYS';
export const fetchApartmentKeys = (apartmentId: number) => ({
  type: FETCH_APARTMENT_KEYS,
  method: 'GET',
  route: `/apartments/${apartmentId}/keys`,
  schema: [schema.apartmentKey],
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_APARTMENT_KEYSETUPS = 'FETCH_APARTMENT_KEYSETUPS';
export const fetchKeySetups = (
  apartmentId: number,
  leavingsId: Array<number>,
  status: Array<string>,
) => ({
  type: FETCH_APARTMENT_KEYSETUPS,
  method: 'GET',
  route: `/key-setups`,
  query: {
    apartmentId,
    leavingsId,
    status,
  },
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const POST_LEAVING_LAST_CHECK = 'POST_LEAVING_LAST_CHECK';
export const postLeavingLastCheck = (leavingId: number) => (
  dispatch?: Dispatch,
) => {
  return Promise.resolve()
    .then(() => dispatch(simplePostLeavingLastCheck(leavingId, true)))
    .tap(() => dispatch(fetchLeavingCheckoutScore(leavingId)));
};

export const PATCH_LEAVING_LAST_CHECK = 'PATCH_LEAVING_LAST_CHECK';
export const patchLeavingLastCheck = (
  lastCheckId: number,
  data: Partial<LastCheck>,
) => ({
  type: PATCH_LEAVING_LAST_CHECK,
  method: 'PATCH',
  route: `/leaving-last-checks/${lastCheckId}`,
  schema: schema.lastCheck,
  body: {
    comment: data.comment,
    type: data.type,
    level: data.level,
  },
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const DELETE_LEAVING_LAST_CHECK = 'DELETE_LEAVING_LAST_CHECK';
export const deleteLeavingLastCheck = (leavingId: number) => (
  dispatch?: Dispatch,
) => {
  return Promise.resolve()
    .then(() => dispatch(simpleDeleteLeavingLastCheck(leavingId, true)))
    .tap(() => dispatch(fetchLeavingCheckoutScore(leavingId)));
};

export const POST_LEAVING_LAST_CHECK_DOCUMENT =
  'POST_LEAVING_LAST_CHECK_DOCUMENT';
export const postLeavingLastCheckDocument = (
  lastCheckId: number,
  files: Object,
) => ({
  ...simplePostLeavingLastCheckDocument(lastCheckId, files, true),
});

export const DELETE_LEAVING_LAST_CHECK_DOCUMENT =
  'DELETE_LEAVING_LAST_CHECK_DOCUMENT';
export const deleteLeavingLastCheckDocument = (
  lastCheckId: number,
  documentId: number,
) => ({
  ...simpleDeleteLeavingLastCheckDocument(lastCheckId, documentId),
});

export const PATCH_CHECKOUT = 'PATCH_CHECKOUT';
export const patchCheckout = (
  id: number,
  leavingModifications: Object,
  hideLoading = false,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch(simplePatchLeaving(id, leavingModifications, hideLoading)),
    )
    .tap(() => dispatch(fetchLeavingCheckoutScore(id)));
};

export const PATCH_CHECKIN = 'PATCH_CHECKIN';
export const patchCheckin = (
  id: number,
  leavingModifications: Object,
  hideLoading: boolean = false,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch(simplePatchLeaving(id, leavingModifications, hideLoading)),
    )
    .tap(() => dispatch(fetchLeavingCheckinScore(id)));
};

export const COMPLETE_SETUP_LEAVING = 'COMPLETE_SETUP_LEAVING';
export const completeSetupLeaving = (leavingId: number) => ({
  type: COMPLETE_SETUP_LEAVING,
  route: `/leavings/${leavingId}`,
  method: 'PATCH',
  body: { isCheckinReady: true },
  hideLoading: true,
  schema: schema.leaving,
  meta: {
    leavingId,
  },
  url: CONFIG.API_V2_URL,
});

export const COMPLETE_UNSETUP_LEAVING = 'COMPLETE_UNSETUP_LEAVING';
export const completeUnSetupLeaving = (
  leavingId: number,
  hideLoading: boolean = true,
) => ({
  type: COMPLETE_UNSETUP_LEAVING,
  route: `/leavings/${leavingId}`,
  method: 'PATCH',
  body: { isCheckoutReady: true },
  hideLoading,
  schema: schema.leaving,
  meta: {
    leavingId,
  },
  url: CONFIG.API_V2_URL,
});

export const POST_KEY_TAG = 'POST_KEY_TAG';
export const postKeyTag = (
  apartmentKeyId: number,
  code: string,
  apartmentId: number,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: POST_KEY_TAG,
        route: `/apartment-keys/${apartmentKeyId}/tag`,
        method: 'POST',
        hideLoading: true,
        body: {
          code,
        },
        meta: {
          apartmentKeyId,
        },
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_KEY_TAGS = 'POST_KEY_TAGS';
export const postKeyTags = (apartmentKeyId: number, tags: string[]) => (
  dispatch?: Dispatch,
) => {
  return Promise.resolve().then(() =>
    dispatch({
      type: POST_KEY_TAGS,
      route: `/apartment-keys/${apartmentKeyId}/tags`,
      method: 'POST',
      body: {
        tags,
      },
      schema: schema.apartmentKey,
      meta: {
        apartmentKeyId,
      },
      url: CONFIG.API_V2_URL,
    }),
  );
};

export const DELETE_KEY_TAG = 'DELETE_KEY_TAG';
export const deleteKeyTag = (
  apartmentKeyId: number,
  code: string,
  apartmentId: number,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: DELETE_KEY_TAG,
        route: `/apartment-keys/${apartmentKeyId}/tag/${code}`,
        method: 'DELETE',
        hideLoading: true,
        meta: {
          apartmentKeyId,
        },
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchApartmentActivity(apartmentId)));
};

export const POST_KEY_SITE = 'POST_KEY_SITE';
export const postKeySite = (apartmentKeyId: number, keySite: string) => (
  dispatch?: Dispatch,
) => {
  return Promise.resolve().then(() =>
    dispatch({
      type: POST_KEY_SITE,
      route: `/apartment-keys/${apartmentKeyId}/key-site`,
      method: 'POST',
      body: {
        keySite,
      },
      schema: schema.apartmentKey,
      meta: {
        apartmentKeyId,
      },
      url: CONFIG.API_V2_URL,
    }),
  );
};

export const POST_APARTMENT_ACCESS = 'POST_APARTMENT_ACCESS';
export const postApartmentAccess = (
  apartmentId: number,
  accessType: string,
  steps: Array<{
    position: number;
    type: string;
    value: any;
  }>,
) => ({
  type: POST_APARTMENT_ACCESS,
  route: `/apartments/${apartmentId}/accesses`,
  method: 'POST',
  schema: schema.apartment,
  meta: {
    apartmentId,
  },
  body: {
    type: accessType,
    steps,
  },
  url: CONFIG.API_V2_URL,
});

export const FETCH_APARTMENT_ACCESS_STEPS = 'FETCH_APARTMENT_ACCESS_STEPS';
export const fetchApartmentAccessSteps = (accessId: number) => ({
  type: FETCH_APARTMENT_ACCESS_STEPS,
  route: `/accesses/${accessId}/steps`,
  url: CONFIG.API_V2_URL,
});

export const DELETE_APARTMENT_ACCESS = 'DELETE_APARTMENT_ACCESS';
export const deleteApartmentAccess = (
  apartmentId: number,
  accessId: number,
) => ({
  type: DELETE_APARTMENT_ACCESS,
  route: `/accesses/${accessId}`,
  method: 'DELETE',
  meta: {
    accessId,
    apartmentId,
  },
  url: CONFIG.API_V2_URL,
});

export const PATCH_APARTMENT_ACCESS = 'PATCH_APARTMENT_ACCESS';
export const patchApartmentAccess = (
  apartmentId: number,
  accessId: number,
  accessType: string,
  steps: Array<{
    position: number;
    type: any;
    value: any;
  }>,
) => ({
  type: PATCH_APARTMENT_ACCESS,
  route: `/accesses/${accessId}`,
  method: 'PATCH',
  meta: {
    accessId,
    apartmentId,
  },
  body: {
    type: accessType,
    steps,
  },
  url: CONFIG.API_V2_URL,
});

export const RESET_APARTMENT_ACTIVITIES = 'RESET_APARTMENT_ACTIVITIES';
export const resetApartmentActivities = () => ({
  type: RESET_APARTMENT_ACTIVITIES,
});

export const PATCH_LEAVING_APARTMENT = 'PATCH_LEAVING_APARTMENT';
export const patchLeavingApartment = (
  leavingId: number,
  apartmentId: number,
  apartmentModifications: Partial<Record<keyof Apartment, any>>,
  hideLoading: boolean = false,
) => (dispatch?: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        ...simplePatchApartment(apartmentId, apartmentModifications),
        hideLoading,
      }),
    )
    .tap(() => dispatch(simpleFetchLeaving(leavingId)));
};

export const POST_APARTMENT_ASSIGN_CLEANING_CONTRACTOR =
  'POST_APARTMENT_ASSIGN_CLEANING_CONTRACTOR';
export const postApartmentAssignCleaningContractor = (
  apartmentIds: number[],
  contractorId: number,
) => ({
  type: POST_APARTMENT_ASSIGN_CLEANING_CONTRACTOR,
  route: `/apartments/assign-cleaning-contractor`,
  method: 'POST',
  body: {
    apartmentIds,
    contractorId,
  },
  url: CONFIG.API_V2_URL,
});

export const GET_APARTMENT_RENT_ESTIMATION = 'GET_APARTMENT_RENT_ESTIMATION';
export const getApartmentRentEstimation = (apartmentId: number) => (
  dispatch?: Dispatch,
) =>
  dispatch({
    type: GET_APARTMENT_RENT_ESTIMATION,
    route: `/apartments/${apartmentId}/rent-estimation`,
    method: 'GET',
    url: CONFIG.API_V2_URL,
    hideLoading: false,
  });

export const FETCH_LEAVINGS_TO_PUBLISH = 'FETCH_LEAVINGS_TO_PUBLISH';
export const fetchLeavingsToPublish = ({
  isPublished,
  agencyId,
  startDate = null,
  endDate = null,
  limit,
  offset,
  text,
  more = false,
}: IFetchLeavingsToPublish) => {
  return {
    type: FETCH_LEAVINGS_TO_PUBLISH,
    route: '/leavings/topublish',
    method: 'GET',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    schema: [schema.leaving],
    meta: {
      more,
    },
    query: { isPublished, agencyId, startDate, endDate, limit, offset, text },
  };
};

export const POST_LEAVING_ACTION = 'POST_LEAVING_ACTION';
export const postLeavingAction = (
  leavingId: number,
  action: LeavingActionType,
) => (dispatch?: Dispatch) =>
  dispatch({
    type: POST_LEAVING_ACTION,
    route: `/leavings/${leavingId}/leaving-action`,
    body: { action },
    method: 'POST',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    schema: schema.leaving,
  });

export const DELETE_LEAVING_ACTION = 'DELETE_LEAVING_ACTION';
export const deleteLeavingAction = (
  leavingId: number,
  action: LeavingActionType,
) => (dispatch?: Dispatch) =>
  dispatch({
    type: DELETE_LEAVING_ACTION,
    route: `/leavings/${leavingId}/leaving-action`,
    body: { action },
    method: 'DELETE',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    schema: schema.leaving,
  });

export const POST_LEAVING_PUBLISH = 'POST_LEAVING_PUBLISH';
export const postLeavingPublish = (leavingId: number) => {
  return {
    type: POST_LEAVING_PUBLISH,
    route: `/leavings/${leavingId}/publish`,
    method: 'POST',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    meta: {
      leavingId,
    },
    schema: schema.leaving,
  };
};

export const POST_PUBLISH_COMMENT = 'POST_PUBLISH_COMMENT';
export const postPublishComment = (leavingId: number, comment: string) => {
  return {
    type: POST_PUBLISH_COMMENT,
    route: `/leavings/${leavingId}/publish-comment`,
    method: 'POST',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    body: { comment },
    schema: schema.leaving,
  };
};

export const TREAT_PUBLISH_COMMENT = 'TREAT_PUBLISH_COMMENT';
export const treatPublishComment = (id: number) => {
  return {
    type: TREAT_PUBLISH_COMMENT,
    route: `/publish-comments/${id}/treated`,
    method: 'POST',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    schema: schema.publishComment,
  };
};

export const PUSH_PUBLISH_COMMENT = 'PUSH_PUBLISH_COMMENT';
export const pushPublishComment = (id: number) => {
  return {
    type: PUSH_PUBLISH_COMMENT,
    route: `/publish-comments/${id}/pushed`,
    method: 'POST',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    schema: schema.publishComment,
  };
};

export const FETCH_LIST_APARTMENT_KEYS = 'FETCH_LIST_APARTMENT_KEYS';
export const fetchListApartmentKeys = ({
  limit,
  offset,
  text,
  denomination,
  keySite,
  tag,
  more,
  status,
  contractor,
  showKeysError,
  startDate,
  endDate,
}: IFetchLeavingWithKeys) => {
  return {
    type: FETCH_LIST_APARTMENT_KEYS,
    method: 'GET',
    route: `/leavings/keys`,
    query: {
      limit,
      offset,
      denomination,
      keySite,
      tag,
      text,
      status,
      contractor,
      showKeysError,
      startDate,
      endDate,
    },
    meta: {
      more,
    },
    hideLoading: true,
    schema: [schema.leaving],
    url: CONFIG.API_V2_URL,
  };
};

export const POST_APARTMENT_KEY_COMMENT = 'POST_APARTMENT_KEY_COMMENT';
export const postApartmentKeyComment = (
  apartmentId: number,
  comment: string,
) => {
  return {
    type: POST_APARTMENT_KEY_COMMENT,
    route: `/apartments/${apartmentId}/key-comment`,
    method: 'POST',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    body: { comment },
    schema: schema.apartment,
  };
};

export const TREAT_APARTMENT_KEY_COMMENT = 'TREAT_APARTMENT_KEY_COMMENT';
export const treatApartmentKeyComment = (id: number) => {
  return {
    type: TREAT_APARTMENT_KEY_COMMENT,
    route: `/key-comments/${id}/treated`,
    method: 'POST',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    schema: schema.apartmentKeyComment,
  };
};

export const PUSH_APARTMENT_KEY_COMMENT = 'PUSH_APARTMENT_KEY_COMMENT';
export const pushApartmentKeyComment = (id: number) => {
  return {
    type: PUSH_APARTMENT_KEY_COMMENT,
    route: `/key-comments/${id}/pushed`,
    method: 'POST',
    url: CONFIG.API_V2_URL,
    hideLoading: true,
    schema: schema.apartmentKeyComment,
  };
};

export const SEND_APARTMENT_SEMI_AUTO_MAIL = 'SEND_APARTMENT_SEMI_AUTO_MAIL';
export const sendApartmentSemiAutoMail = (
  apartmentId: number,
  content: string,
  type: string,
  subject: string,
  documents: any = [],
) => ({
  type: SEND_APARTMENT_SEMI_AUTO_MAIL,
  route: `/apartments/${apartmentId}/mail-send`,
  method: 'POST',
  body: {
    content,
    type,
    subject,
    documents,
  },
  url: CONFIG.API_V2_URL,
});

export const GET_SEMI_AUTO_MAIL = 'GET_SEMI_AUTO_MAIL';
export const getSemiAutoMail = (apartmentId: number, type: string) => ({
  type: GET_SEMI_AUTO_MAIL,
  route: `/apartments/${apartmentId}/mail-template`,
  method: 'GET',
  query: {
    type,
  },
  url: CONFIG.API_V2_URL,
});

export const PATCH_PREREQUISITE = 'PATCH_PREREQUISITE';
export const patchPrerequisites = (
  apartmentId: number,
  prerequisites: Prerequisite[],
) => ({
  type: PATCH_PREREQUISITE,
  route: `/apartments/${apartmentId}/prerequisites`,
  method: 'PATCH',
  body: {
    prerequisites,
  },
  url: CONFIG.API_V2_URL,
  schema: schema.apartment,
});

export const PATCH_MODALITY = 'PATCH_MODALITY';
export const patchModality = (modalityId: number, data: Partial<Modality>) => ({
  type: PATCH_MODALITY,
  route: `/modalities/${modalityId}`,
  body: data,
  method: 'PATCH',
  url: CONFIG.API_V2_URL,
  hideLoading: true,
  schema: schema.modality,
});
