import moment from 'moment-timezone';
import { Dispatch } from 'src/types/redux';

import CONFIG from '@config';

import ENUM from '@enum';

import * as schema from '@redux/schema';

const { PHOTO_CONTRACT_STATUS } = ENUM;

export const FETCH_PHOTO_CONTRACTS = 'FETCH_PHOTO_CONTRACTS';
export const fetchPhotoContracts = (
  offset?: number,
  limit?: number,
  more?: boolean,
  startDate: moment.Moment = null,
  endDate: moment.Moment = null,
  contractorId: number | '' = '',
  agencyId: number | '' = '',
  text = '',
  status: Array<string> = Object.values(PHOTO_CONTRACT_STATUS),
  order = 'date',
) => {
  const query = {
    offset,
    limit,
    startDate: startDate?.toISOString?.(),
    endDate: endDate?.toISOString?.(),
    agencyId,
    contractorId,
    text,
    status,
    order,
  };

  return {
    type: FETCH_PHOTO_CONTRACTS,
    route: `/photo-contracts`,
    method: 'GET',
    query,
    meta: {
      more,
    },
    schema: [schema.photoContract],
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_PHOTO_CONTRACT = 'FETCH_PHOTO_CONTRACT';
export const fetchPhotoContract = (id: number | string, hideLoading = true) => {
  return {
    type: FETCH_PHOTO_CONTRACT,
    route: `/photo-contracts/${id}`,
    method: 'GET',
    schema: schema.photoContract,
    hideLoading,
    url: CONFIG.API_V2_URL,
  };
};

export const CREATE_PHOTO_CONTRACT_PICTHOUSE_ORDER =
  'CREATE_PHOTO_CONTRACT_PICTHOUSE_ORDER';
export const createPhotoContractPicthouseOrder = (
  id: number | string,
  date: moment.Moment | Date,
  providerId?: number,
  directive?: string,
  observation?: string,
) => (dispatch: Dispatch) => {
  const dateStr = moment(date).format('YYYY-MM-DD');
  const timeStr = moment(date).format('HH:mm');

  return Promise.resolve()
    .then(() =>
      dispatch({
        type: CREATE_PHOTO_CONTRACT_PICTHOUSE_ORDER,
        route: `/photo-contracts/${id}/picthouse/order`,
        method: 'POST',
        body: {
          providerId,
          date: dateStr,
          time: timeStr,
          directive: directive || '',
          observation: observation || '',
        },
        schema: schema.photoContract,
        hideLoading: true,
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchPhotoContract(id)));
};

export const PATCH_PHOTO_CONTRACT = 'PATCH_PHOTO_CONTRACT';
export const patchPhotoContract = (id: number | string, data: Object) => (
  dispatch: Dispatch,
) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: PATCH_PHOTO_CONTRACT,
        route: `/photo-contracts/${id}`,
        method: 'PATCH',
        body: data,
        schema: schema.photoContract,
        hideLoading: true,
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchPhotoContract(id)));
};

export const FETCH_PICTHOUSE_AVAILABILITIES = 'FETCH_PICTHOUSE_AVAILABILITIES';
export const fetchPicthouseAvailabilities = (id: number | string) => {
  return {
    type: FETCH_PICTHOUSE_AVAILABILITIES,
    route: `/photo-contracts/${id}/picthouse/availabilities`,
    method: 'GET',
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_PICTHOUSE_SLOTS = 'FETCH_PICTHOUSE_SLOTS';
export const fetchPicthouseSlots = (
  id: number,
  date: moment.Moment,
  cityId: number,
  packId: number,
) => {
  return {
    type: FETCH_PICTHOUSE_SLOTS,
    route: `/photo-contracts/${id}/picthouse/slots`,
    query: {
      date: date.toISOString(),
      cityId,
      packId,
    },
    method: 'GET',
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_PICTHOUSE_AVAILABLE_PROVIDERS =
  'FETCH_PICTHOUSE_AVAILABLE_PROVIDERS';
export const fetchPicthouseAvailableProviders = (
  id: number | string,
  date: string,
  time: string,
) => {
  return {
    type: FETCH_PICTHOUSE_AVAILABLE_PROVIDERS,
    route: `/photo-contracts/${id}/picthouse/providers`,
    query: {
      date,
      time,
    },
    method: 'GET',
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const POST_DELIVER_PHOTO_CONTRACT = 'POST_DELIVER_PHOTO_CONTRACT';
export const postDeliverPhotoContract = (id: number, observation = '') => ({
  type: POST_DELIVER_PHOTO_CONTRACT,
  route: `/photo-contracts/${id}/deliver`,
  body: {
    observation,
  },
  method: 'POST',
  hideLoading: false,
  schema: schema.photoContract,
  url: CONFIG.API_V2_URL,
});

export const CANCEL_PHOTO_CONTRACT = 'CANCEL_PHOTO_CONTRACT';
export const cancelPhotoContract = (id: number | string) => ({
  type: CANCEL_PHOTO_CONTRACT,
  route: `/photo-contracts/${id}/cancel`,
  method: 'POST',
  schema: schema.photoContract,
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const RESCHEDULE_PHOTO_CONTRACT = 'RESCHEDULE_PHOTO_CONTRACT';
export const reschedulePhotoContract = (
  id: number | string,
  date?: moment.Moment,
  contactName?: string,
  contactNumber?: string,
  directive?: string,
  observation?: string,
) => ({
  type: RESCHEDULE_PHOTO_CONTRACT,
  route: `/photo-contracts/${id}/re-schedule`,
  method: 'POST',
  body: {
    date,
    contactName,
    contactNumber,
    directive: directive || '',
    observation: observation || '',
  },
  schema: schema.photoContract,
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});
