import React, { useState } from 'react';

import { GenericInput } from '@components';

/*
 * https://react-bootstrap.github.io/components/dropdowns/#custom-dropdown-components
 */

type CustomMenuProps = {
  children: JSX.Element | JSX.Element[];
  t: Function;
  onSelect: Function;
  style: Object;
  className: string;
  'aria-labelledby': string;
};

// Those functions are test requested
export const filterValue = (value: string) => (child: any) =>
  !value || new RegExp(value, 'ui').test(child.props.filter);

export const mapValue = (onSelect: Function) => (value: any) =>
  React.cloneElement(value, { onClick: () => onSelect(value.props.elem) });

const CustomMenu = React.forwardRef<CustomMenuProps, any>(
  (
    {
      children,
      t,
      onSelect,
      style,
      className,
      'aria-labelledby': labeledBy,
    }: CustomMenuProps,
    ref,
  ) => {
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref as any}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <GenericInput
          autoFocus
          className="mx-3 my-2 w-auto"
          placeholder={t('common.search')}
          onChange={setValue}
          value={value}
        />
        <ul className="list-unstyled">
          {React.Children.toArray(children)
            .filter(filterValue(value))
            .map(mapValue(onSelect))}
        </ul>
      </div>
    );
  },
);

CustomMenu.displayName = 'CustomMenu';

export default CustomMenu;
