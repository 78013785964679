import React, { Component } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

type Props = {
  label?: React.ReactNode;
  required?: boolean;
  children?: any;
  controlId?: string;
  className?: string;
  prepend?: string;
  append?: string;
};

class GenericFormGroup extends Component<Props> {
  static defaultProps = {
    controlId: null,
    className: '',
    prepend: '',
    append: '',
  };

  render() {
    const { required, controlId, className } = this.props;
    const tempClass = className || '';

    return (
      <Form.Group
        controlId={controlId}
        className={required ? `form-group--required ${tempClass}` : tempClass}
      >
        {this.renderLabel()}
        {this.renderContent()}
      </Form.Group>
    );
  }

  renderContent = () => {
    const { children, prepend, append } = this.props;

    if (!prepend && !append) return children;

    return (
      <InputGroup>
        {this.renderPrepend()}
        {children}
        {this.renderAppend()}
      </InputGroup>
    );
  };

  renderLabel = () => {
    const { label } = this.props;

    if (!label) return null;

    return <Form.Label className="mr-1">{label}</Form.Label>;
  };

  renderPrepend = () => {
    const { prepend } = this.props;

    if (!prepend) return false;

    return (
      <InputGroup.Prepend>
        <InputGroup.Text>{prepend}</InputGroup.Text>
      </InputGroup.Prepend>
    );
  };

  renderAppend = () => {
    const { append } = this.props;

    if (!append) return false;

    return (
      <InputGroup.Append>
        <InputGroup.Text>{append}</InputGroup.Text>
      </InputGroup.Append>
    );
  };
}

export default GenericFormGroup;
