import React, { Component, FormEvent } from 'react';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { SButton } from '@smartrenting/smartomic';

import { GenericTextarea } from '@components';

type Props = {
  page: string;
  id: number;
  postComment: Function;
  t: Function;
  withTitle?: boolean;
};

type State = {
  commentText: string;
};

export class GenericCommentZone extends Component<Props, State> {
  static defaultProps = {
    page: '',
    withTitle: true,
  };

  state = {
    commentText: '',
  };

  render() {
    const { page, t, withTitle } = this.props;
    const { commentText } = this.state;

    return (
      <Form
        className={`form ${page}-page__comment-form`}
        onSubmit={this.handleSubmitComment}
      >
        {withTitle && (
          <p>
            <span>{t('comment.addComment')}</span>
          </p>
        )}
        <GenericTextarea
          value={commentText}
          placeholder={t('comment.writeComment')}
          required
          onChange={this.handleInputComment}
          controlId="commentText"
        />
        <div className="text-right mb-2 mb-md-0">
          <SButton
            type="submit"
            className="full-mobile"
            variant="secondary"
            disabled={!commentText.length}
          >
            {t('common.send')}
          </SButton>
        </div>
      </Form>
    );
  }

  handleInputComment = (value: string) => {
    this.setState({
      commentText: value,
    });
  };

  handleSubmitComment = (event: FormEvent) => {
    event.preventDefault();

    const { postComment, id } = this.props;
    const { commentText } = this.state;

    return postComment(id, commentText).then(() =>
      this.setState({ commentText: '' }),
    );
  };
}

export default withTranslation()(GenericCommentZone);
