import * as React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

export function SuspenseComponent({
  children,
}: {
  children: React.ReactElement<any>;
}) {
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  return (
    <React.Suspense fallback={<div />}>
      {React.cloneElement(children, {
        match,
        location,
        history,
      })}
    </React.Suspense>
  );
}

export default SuspenseComponent;
