import React, { Component } from 'react';
import Datetime from 'react-datetime';

import moment from 'moment-timezone';
import 'moment/locale/fr';

import { GenericFormGroup } from '@components';

type Props = {
  value: string | moment.Moment;
  label?: string;
  placeholder: string;
  required?: boolean;
  onChange: Function;
  controlId?: string;
  disabled?: boolean;
  viewDate?: moment.Moment;
  className?: string;
  prepend?: string;
  append?: string;
};

class GenericMonthPicker extends Component<Props> {
  static defaultProps = {
    controlId: null,
    disabled: false,
    viewDate: moment(),
    className: undefined,
    prepend: '',
    append: '',
  };

  render() {
    const {
      label,
      required,
      controlId,
      className,
      prepend,
      append,
    } = this.props;

    return (
      <GenericFormGroup
        className={className}
        label={label}
        required={required}
        controlId={controlId}
        prepend={prepend}
        append={append}
      >
        {this.renderMonthPicker()}
      </GenericFormGroup>
    );
  }

  renderMonthPicker = () => {
    const {
      value,
      viewDate,
      label,
      placeholder,
      required,
      disabled,
    } = this.props;

    return (
      <Datetime
        locale="fr"
        inputProps={{ placeholder: placeholder || label, required, disabled }}
        closeOnSelect
        timeFormat={false}
        value={value}
        initialViewDate={viewDate}
        onChange={this.handleChange}
        dateFormat="MMMM YYYY"
      />
    );
  };

  handleChange = (date: string | moment.Moment) => {
    const { onChange } = this.props;

    if (date === '' || (moment.isMoment(date) && moment(date).isValid()))
      onChange(date);
  };
}

export default GenericMonthPicker;
