import React, { Component } from 'react';
import Datetime from 'react-datetime';

import moment from 'moment-timezone';
import 'moment/locale/fr';

import { GenericFormGroup } from '@components';

type Props = {
  value?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: Function;
  controlId?: string;
  disabled?: boolean;
  viewDate?: moment.Moment;
  className?: string;
  min?: string;
  max?: string;
  prepend?: string;
  append?: string;
};

class GenericTimePicker extends Component<Props> {
  static defaultProps = {
    controlId: null,
    disabled: false,
    viewDate: moment(),
    className: undefined,
    min: '',
    max: '',
    prepend: '',
    append: '',
  };

  render() {
    const {
      label,
      required,
      controlId,
      className,
      prepend,
      append,
    } = this.props;

    return (
      <GenericFormGroup
        className={className}
        label={label}
        required={required}
        controlId={controlId}
        prepend={prepend}
        append={append}
      >
        {this.renderDatePicker()}
      </GenericFormGroup>
    );
  }

  renderDatePicker = () => {
    const {
      value,
      viewDate,
      label,
      placeholder,
      required,
      disabled,
    } = this.props;

    return (
      <Datetime
        locale="fr"
        inputProps={{ placeholder: placeholder || label, required, disabled }}
        closeOnSelect
        dateFormat={false}
        value={value}
        initialViewDate={viewDate}
        isValidDate={this.onHandleValid}
        onChange={this.handleChange}
      />
    );
  };

  handleChange = (date: string | moment.Moment) => {
    const { onChange } = this.props;

    if (date === '' || (moment.isMoment(date) && moment(date).isValid()))
      onChange(date);
  };

  onHandleValid = (current: moment.Moment): boolean => {
    const { min, max } = this.props;

    let valid = true;

    if (min) valid = current.isSameOrAfter(min, 'date');

    if (valid && max) valid = current.isSameOrBefore(max, 'date');

    return valid;
  };
}

export default GenericTimePicker;
