import moment from 'moment-timezone';

import { BOOKING_CHECK_TYPE, BOOKING_TYPE } from '@enum';

import AccessKeyInfo from './AccessKeyInfo';
import Apartment from './Apartment';
import ApartmentKey from './ApartmentKey';
import Employee from './Employee';
import Guest from './Guest';
import Tag from './Tag';

export default class Booking {
  public id: number;

  public adults = 0;
  public children = 0;

  public apartment: Apartment;
  public apartmentId: number;

  public booked = false;
  public bookingsyncId: number;

  public cancelledDate: moment.Moment;
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public date: moment.Moment;

  public currency = '';
  public discount = '';
  public expectedCheckinTime: string;
  public expectedCheckoutTime: string;

  public guest?: Guest;
  public guestId?: number;

  public initialPrice = 0;
  public finalPrice = 0;
  public computedPrice = 0;

  public isCheckIn: boolean;
  public notes: string;
  public source = '';
  public status: string;
  public type: typeof BOOKING_TYPE[keyof typeof BOOKING_TYPE];
  public depositStatus = '';
  public identityCheckStatus = '';
  public accessCheckStatus = '';
  public chargeHost: boolean;
  public tags: Tag[] = [];
  public commentsCount = 0;

  public apartmentKey?: ApartmentKey;
  public accessKeyInfo: AccessKeyInfo;

  public reminders: number;
  public lastReminderDate?: moment.Moment;
  public accessMailContent: string;
  public accessMailDate: moment.Moment;
  public swiklyAcceptUrl: string;
  public createdById: number;
  public createdBy: Employee;

  public blockOnCancel: boolean;

  // These fields are only available on booking list
  public previousCleaningId?: number;
  public previousCleaningStatus?: string;
  public checkType?: typeof BOOKING_CHECK_TYPE[keyof typeof BOOKING_CHECK_TYPE];

  constructor(data: Partial<Booking>) {
    Object.assign(this, data);
    this.apartment = data.apartment ? new Apartment(data.apartment) : null;
    this.cancelledDate = data.cancelledDate
      ? moment(data.cancelledDate)
      : undefined;
    this.date = data.date ? moment(data.date) : null;
    this.endDate = data.endDate ? moment(data.endDate) : undefined;
    this.guest = data.guest ? new Guest(data.guest) : null;
    this.startDate = data.startDate ? moment(data.startDate) : undefined;
    this.tags = (data.tags || []).map((tag) => new Tag(tag));
    this.apartmentKey = data.apartmentKey
      ? new ApartmentKey(data.apartmentKey)
      : null;
    this.accessKeyInfo = data.accessKeyInfo
      ? new AccessKeyInfo(data.accessKeyInfo)
      : null;

    this.createdBy = data.createdBy ? new Employee(data.createdBy) : null;
  }

  getRelevantDate = () => (this.isCheckIn ? this.startDate : this.endDate);
}
