import React, { Component, FormEvent } from 'react';
import { Badge } from 'react-bootstrap';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './GenericTag.scss';

type Props = {
  label: string;
  color: string;
  closable?: boolean;
  onClose?: Function;
  className?: string;
};

class GenericTag extends Component<Props> {
  static defaultProps = {
    color: '22216d',
    closable: false,
    onClose: null,
    className: '',
  };

  render() {
    const { label, color, closable, className } = this.props;

    return (
      <Badge
        className={`GenericTag ${className || ''}`}
        pill
        style={{
          backgroundColor: `#${color || ''}`,
        }}
      >
        <div className="GenericTag__label">{label}</div>
        <div className="GenericTag__icon">
          {closable && (
            <FontAwesomeIcon
              className="fa-lg"
              icon={faTimes}
              onClick={this.handleCloseClick}
            />
          )}
        </div>
      </Badge>
    );
  }

  handleCloseClick = (e: FormEvent) => {
    const { onClose } = this.props;

    e.stopPropagation();

    if (onClose && typeof onClose === 'function') onClose();
  };
}

export default GenericTag;
