import AccessStep from '@redux/models/AccessStep';

import TranslatedText from './TranslatedText';

export default class Access {
  public id: number;
  public apartmentId: number;
  public type: 'foot' | 'car';
  public steps: AccessStep[];
  public translation: TranslatedText;

  constructor(data: Partial<Access>) {
    Object.assign(this, data);
    this.steps = data.steps && data.steps.map((step) => new AccessStep(step));
    this.translation = new TranslatedText(data.translation);
  }
}
