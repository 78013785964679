import React, { Component } from 'react';

import GenericTableBody from './components/GenericTableBody';
import GenericTableHeader from './components/GenericTableHeader';

import { Fields, Data, DataMapper, FieldOrder } from './GenericTableTypes.d';

import './GenericTable.scss';

type Props<T extends Data> = {
  fields: Fields;
  data: Array<Data>;
  order?: FieldOrder;
  sort?: string;
  handleTableRef?: (ref: any) => any;
  onSort?: (newOrder: string) => any;
  loading?: boolean;
  dataMapper?: DataMapper<T>;
  headerClasses?: Partial<{
    container: string;
    row: string;
    rowCell: string;
  }>;
  bodyClasses?: Partial<{
    container: string;
    row: string | ((element?: any) => string);
    rowCell: string;
  }>;
  filters: React.ReactNode;

  hover?: boolean;
  onRowClick?: (element: Data) => any;

  renderSeparator?: (
    element: Data,
    index: number,
    data: Array<Data>,
  ) => JSX.Element | JSX.Element[];
  renderHeader?: (props: Props<T>, State: State) => JSX.Element | JSX.Element[];
  renderBody?: (data: Array<Data>) => JSX.Element | JSX.Element[];
  renderFilters?: () => JSX.Element | JSX.Element[];
  renderNoResults?: () => JSX.Element | JSX.Element[];
  renderKey?: (element: T) => string;
};

type State = {};

class GenericTable extends Component<Props<Data>, State> {
  static defaultProps = {
    sort: undefined,
    headerClasses: undefined,
    bodyClasses: undefined,
    loading: false,
    order: undefined,
    hover: true,
    dataMapper: undefined,
    renderBody: undefined,
    renderHeader: undefined,
    renderFilters: undefined,
    renderSeparator: undefined,
    renderNoResults: undefined,
    renderKey: undefined,
    filters: null,
    onSort: () => {},
    onRowClick: () => {},
    handleTableRef: () => {},
  };

  render() {
    const { handleTableRef } = this.props;

    return (
      <div className="generic-table">
        {this.renderFilters()}
        <div className="table-bloc generic-table__table-bloc">
          <div ref={handleTableRef} className="table generic-table__table">
            {this.renderHeader()}
            {this.renderBody()}
          </div>
        </div>
      </div>
    );
  }

  renderHeader = () => {
    const {
      fields,
      sort,
      onSort,
      order,
      renderHeader,
      headerClasses,
    } = this.props;

    if (renderHeader && typeof renderHeader === 'function')
      return renderHeader(this.props, this.state);

    if (!fields) return null;

    return (
      <GenericTableHeader
        fields={fields}
        sort={sort}
        onSort={onSort}
        order={order}
        containerClassName={headerClasses && headerClasses.container}
        rowClassName={headerClasses && headerClasses.row}
        rowCellClassName={headerClasses && headerClasses.rowCell}
      />
    );
  };

  renderBody = () => {
    const {
      renderBody,
      hover,
      onRowClick,
      fields,
      loading,
      dataMapper,
      data,
      order,
      bodyClasses,
      renderSeparator,
      renderNoResults,
      renderKey,
    } = this.props;

    if (renderBody && typeof renderBody === 'function') {
      return (
        <div
          className={
            bodyClasses && bodyClasses.container
              ? bodyClasses.container
              : 'table__body generic-table__body'
          }
        >
          {renderBody(data)}
        </div>
      );
    }

    return (
      <GenericTableBody
        data={data}
        renderSeparator={renderSeparator}
        fields={fields}
        loading={loading}
        order={order}
        hover={hover}
        onRowClick={onRowClick}
        dataMapper={dataMapper}
        containerClassName={bodyClasses && bodyClasses.container}
        rowClassName={bodyClasses && bodyClasses.row}
        rowCellClassName={bodyClasses && bodyClasses.rowCell}
        renderNoResults={renderNoResults}
        renderKey={renderKey}
      />
    );
  };

  renderFilters = () => {
    const { renderFilters, filters } = this.props;

    if (renderFilters && typeof renderFilters === 'function')
      return renderFilters();

    return filters;
  };
}

export default GenericTable;
