import { handle } from 'redux-pack';

import Activity from '@redux/models/Activity';
import Prospects from '@redux/models/Prospects';
import Reminder from '@redux/models/Reminder';
import Statistics from '@redux/models/Statistics';

import { LOGOUT, RESET } from '@redux/me/actions';
import {
  DELETE_PROSPECT,
  FETCH_NEW_PROSPECTS,
  FETCH_PROSPECT,
  FETCH_PROSPECTS,
  FETCH_PROSPECTS_STATISTIC,
  FETCH_PROSPECT_ACTIVITY,
  FETCH_PROSPECT_REMINDERS,
  FETCH_SIGNED_PROSPECTS,
  POST_PROSPECT,
  SET_PROSPECTS_LIST_TEXT_FILTER,
} from '@redux/prospects/actions';

const initialState = new Prospects({});

export default function prospectsReducer(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case FETCH_PROSPECTS:
      return handle(state, action, {
        success: () => {
          return {
            ...state,
            prospects: action.meta.more
              ? state.prospects.concat(action.payload.result)
              : action.payload.result,
            prospectsTotal: action.payload.total,
          };
        },
      });
    case FETCH_NEW_PROSPECTS:
      return handle(state, action, {
        success: () => ({
          ...state,
          prospectsNew: action.payload.result,
          prospectsNewTotal: action.payload.total,
        }),
      });
    case FETCH_SIGNED_PROSPECTS:
      return handle(state, action, {
        success: () => ({
          ...state,
          prospectsSigned: action.payload.result,
          prospectsSignedTotal: action.payload.total,
        }),
      });
    case FETCH_PROSPECT:
      return handle(state, action, {
        success: () => ({
          ...state,
          prospectDetails: action.payload,
        }),
      });
    case FETCH_PROSPECT_ACTIVITY:
      return handle(state, action, {
        success: () => {
          return {
            ...state,
            prospectActivity: action.payload.map(
              (activity) => new Activity(activity),
            ),
          };
        },
      });
    case FETCH_PROSPECT_REMINDERS:
      return handle(state, action, {
        success: () => {
          return {
            ...state,
            prospectReminders: action.payload.map(
              (reminder) => new Reminder(reminder),
            ),
          };
        },
      });
    case FETCH_PROSPECTS_STATISTIC:
      return handle(state, action, {
        success: () => {
          return {
            ...state,
            statistics: new Statistics(action.payload),
          };
        },
      });
    case POST_PROSPECT:
      return handle(state, action, {
        success: () => ({
          ...state,
          prospects: [action.payload, ...state.prospects],
        }),
      });
    case DELETE_PROSPECT:
      return handle(state, action, {
        success: () => ({
          ...state,
          prospectDetails: null,
        }),
      });
    case SET_PROSPECTS_LIST_TEXT_FILTER:
      return {
        ...state,
        prospectsListTextFilter: action.text,
      };
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
