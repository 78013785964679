export default class Toast {
  public autoClose = 0;
  public text: string;
  public variant: string;
  public textParams: Record<string, string | number> = undefined;

  constructor(data: Partial<Toast>) {
    Object.assign(this, data);
  }
}
