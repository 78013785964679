import moment from 'moment-timezone';
import { handle } from 'redux-pack';

import ENUM, { BOOKING_CHECK_TYPE } from '@enum';

import { Tools } from '@utils/tools';

import Leaving from '@redux/models/Leaving';

import {
  POST_APARTMENT_LEAVING_PLANNING,
  POST_APARTMENT_LEAVING,
  POST_LEAVING_INVENTORY,
  POST_APARTMENT_COMMENT,
  DELETE_APARTMENT_KEY,
  FETCH_LEAVING_CHECKOUT_SCORE,
  POST_KEY_TAG,
  DELETE_KEY_TAG,
  DELETE_APARTMENT_ACCESS,
  PATCH_APARTMENT_ACCESS,
  FETCH_LEAVING_CHECKIN_SCORE,
  DELETE_APARTMENT_RENTAL_AGENCY,
} from '@redux/apartments/actions';
import {
  PATCH_BOOKING,
  POST_BOOKING_ACCESS_MAIL,
  POST_BOOKING_ACCESS_KEY_INFO,
} from '@redux/bookings/actions';
import {
  POST_CLEANING_TAG,
  DELETE_CLEANING_TAG,
} from '@redux/cleaningContracts/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

import {
  ADD_ENTITIES,
  SIMPLE_POST_LEAVING_LAST_CHECK,
  SIMPLE_DELETE_APARTMENT_FILE,
  SIMPLE_DELETE_APARTMENT_FILES,
  SIMPLE_DELETE_LEAVING_FILE,
  SIMPLE_DELETE_LEAVING_LAST_CHECK,
  SIMPLE_POST_LEAVING_LAST_CHECK_DOCUMENT,
  SIMPLE_DELETE_LEAVING_LAST_CHECK_DOCUMENT,
  SIMPLE_DELETE_CONTACT_FILE,
  SIMPLE_DELETE_CONTACT_FILES,
  SIMPLE_PATCH_IDENTITY_DOCUMENT,
} from './actions';

const initialState = {
  apartments: {},
  bookings: {},
  cleaningContracts: {},
  photoContracts: {},
  guests: {},
  prospects: {},
  users: {},
  leavings: {},
  contacts: {},
  rooms: {},
  contractors: {},
  cities: {},
  inventories: {},
  neighborhoods: {},
  apartmentKeys: {},
  accessKeyInfos: {},
  bookingsCheckinCheckout: {},
  rentalAgencies: {},
  hostRents: {},
  hostRentRows: {},
  hostRentSepas: {},
  translatedTexts: {},
  keySetups: {},
  lastChecks: {},
  employees: {},
  contractorEmployees: {},
  notifications: {},
};

const leavingFilePathToKey = {
  authorizationdocument: 'authorizationDocument',
  'departure-document': 'departureDocument',
};

const apartmentFilePathToKey = {
  receipts: 'receipts',
  proofdocument: 'proofDocument',
  documents: 'documents',
  'usage-document': 'usageDocument',
};

const contactFilePathToKey = {
  rib: 'rib',
  idcard: 'identityDocuments',
};

export default function entitiesReducer(
  state: any = initialState,
  action: any,
) {
  const { type } = action;

  switch (type) {
    case ADD_ENTITIES: {
      let additional = {
        apartmentKeys: {},
        apartments: {},
      };

      /**
       * Maybe we should fetch key with their accesskeyinfo instead of this hack
       */
      if (action.entities.accessKeyInfos) {
        Object.keys(action.entities.accessKeyInfos).forEach(
          (accessKeyInfoKey) => {
            const accessKeyInfo =
              action.entities.accessKeyInfos[accessKeyInfoKey];
            const apartmentKey =
              state.apartmentKeys[accessKeyInfo.apartmentKeyId];

            if (apartmentKey) {
              additional['apartmentKeys'][accessKeyInfo.apartmentKeyId] = {
                accessKeyInfos: [
                  ...new Set([
                    ...(additional['apartmentKeys'][
                      accessKeyInfo.apartmentKeyId
                    ]?.accessKeyInfos || []),
                    ...(apartmentKey.accessKeyInfos || []),
                    accessKeyInfo.id,
                  ]),
                ],
              };
            }
          },
        );
      }

      if (action.entities.apartmentKeys) {
        Object.keys(action.entities.apartmentKeys).forEach((apartmentKeyId) => {
          const apartmentKey = action.entities.apartmentKeys[apartmentKeyId];
          if (state.apartments[apartmentKey.apartmentId]) {
            additional['apartments'][apartmentKey.apartmentId] = {
              keys: [
                ...new Set([
                  ...(additional['apartments'][apartmentKey.apartmentId]
                    ?.keys || []),
                  ...(state.apartments[apartmentKey.apartmentId].keys || []),
                  apartmentKey.id,
                ]),
              ],
            };
          }
        });
      }

      return Tools.mergeDeep({}, state, action.entities, additional);
    }

    case DELETE_APARTMENT_KEY:
      return handle(state, action, {
        success: () => {
          const { apartmentId, keyId } = action.meta;

          return {
            ...state,
            apartments: {
              ...state.apartments,
              [apartmentId]: {
                ...state.apartments[apartmentId],
                keys: [
                  ...state.apartments[apartmentId].keys.filter(
                    (key: number) => key !== keyId,
                  ),
                ],
              },
            },
          };
        },
      });
    case POST_APARTMENT_LEAVING:
    case POST_APARTMENT_LEAVING_PLANNING:
      return handle(state, action, {
        success: () => {
          const { apartmentId } = action.meta;

          return {
            ...state,
            apartments: {
              ...state.apartments,
              [apartmentId]: {
                ...state.apartments[apartmentId],
                leavings: [
                  ...state.apartments[apartmentId].leavings,
                  action.payload,
                ],
              },
            },
          };
        },
      });
    case POST_APARTMENT_COMMENT:
      return handle(state, action, {
        success: () => {
          const { apartmentId } = action.meta;

          if (!state.apartments[apartmentId]) return state;

          return {
            ...state,
            apartments: {
              ...state.apartments,
              [apartmentId]: {
                ...state.apartments[apartmentId],
                commentCount:
                  (state.apartments[apartmentId].commentCount || 0) + 1,
              },
            },
            leavings: Object.entries(state.leavings).reduce(
              (acc, [key, value]) => {
                const leaving = (value as any) as Leaving;
                if (leaving.apartment?.id === apartmentId) {
                  leaving.apartment.commentCount =
                    leaving.apartment.commentCount + 1;
                }

                return { ...acc, [key]: leaving };
              },
              {},
            ),
          };
        },
      });
    case PATCH_BOOKING:
    case SIMPLE_PATCH_IDENTITY_DOCUMENT:
      return handle(state, action, {
        success: () => {
          const booking = state.bookings[action.payload];

          if (!booking) return state;

          const checkin =
            state.bookingsCheckinCheckout[`${booking.id}_checkin`] || {};
          const checkout =
            state.bookingsCheckinCheckout[`${booking.id}_checkout`] || {};

          return {
            ...state,
            bookingsCheckinCheckout: {
              ...state.bookingsCheckinCheckout,
              [`${booking.id}_checkin`]: {
                ...checkin,
                ...booking,
                guest: booking.guest || checkin.guest,
                apartment: booking.apartment || checkin.apartment,
                checkType: BOOKING_CHECK_TYPE.CHECKIN,
                commentsCount: action.meta.comment
                  ? (checkout.commentsCount || 0) + 1
                  : checkout.commentsCount || 0,
                tags: [
                  ...(action.meta.tags || state.bookings[action.payload].tags),
                ],
              },
              [`${booking.id}_checkout`]: {
                ...checkout,
                ...booking,
                guest: booking.guest || checkout.guest,
                apartment: booking.apartment || checkout.apartment,
                checkType: BOOKING_CHECK_TYPE.CHECKOUT,
                commentsCount: action.meta.comment
                  ? (checkout.commentsCount || 0) + 1
                  : checkout.commentsCount || 0,
                tags: [
                  ...(action.meta.tags || state.bookings[action.payload].tags),
                ],
              },
            },
          };
        },
      });
    case POST_BOOKING_ACCESS_MAIL:
      return handle(state, action, {
        success: () => {
          const { bookingId, contentMail } = action.meta;
          const checkin =
            state.bookingsCheckinCheckout[`${bookingId}_checkin`] || {};
          const checkout =
            state.bookingsCheckinCheckout[`${bookingId}_checkout`] || {};

          return {
            ...state,
            bookingsCheckinCheckout: {
              ...state.bookingsCheckinCheckout,
              [`${bookingId}_checkin`]: {
                ...checkin,
                accessCheckStatus: ENUM.BOOKING_CHECK_STATUS.CHECKED,
                accessMailContent: contentMail,
                accessMailDate: moment(),
              },
              [`${bookingId}_checkout`]: {
                ...checkout,
                accessCheckStatus: ENUM.BOOKING_CHECK_STATUS.CHECKED,
                accessMailContent: contentMail,
                accessMailDate: moment(),
              },
            },
          };
        },
      });
    case POST_BOOKING_ACCESS_KEY_INFO:
      return handle(state, action, {
        success: () => {
          const { bookingId } = action.meta;
          const checkin =
            state.bookingsCheckinCheckout[`${bookingId}_checkin`] || {};
          const checkout =
            state.bookingsCheckinCheckout[`${bookingId}_checkout`] || {};

          return {
            ...state,
            bookingsCheckinCheckout: {
              ...state.bookingsCheckinCheckout,
              [`${bookingId}_checkin`]: {
                ...checkin,
                accessKeyInfo: action.payload.accessKeyInfo,
                apartmentKey: action.payload.apartmentKey,
              },
              [`${bookingId}_checkout`]: {
                ...checkout,
                accessKeyInfo: action.payload.accessKeyInfo,
                apartmentKey: action.payload.apartmentKey,
              },
            },
          };
        },
      });
    case POST_LEAVING_INVENTORY:
      return handle(state, action, {
        success: () => {
          const { leavingId } = action.meta;

          const leaving = state.leavings[leavingId];

          if (!leaving) return state;

          return {
            ...state,
            leavings: {
              ...state.leavings,
              [leavingId]: {
                ...leaving,
                inventories: [...leaving.inventories, action.payload],
              },
            },
          };
        },
      });
    case SIMPLE_DELETE_APARTMENT_FILE:
      return handle(state, action, {
        success: () => {
          const { apartmentId, path } = action.meta;

          const apartment = state.apartments[apartmentId];
          const key = apartmentFilePathToKey[path];

          if (!apartment || !key) return state;

          return {
            ...state,
            apartments: {
              ...state.apartments,
              [apartmentId]: {
                ...apartment,
                [key]: null,
              },
            },
          };
        },
      });
    case SIMPLE_DELETE_APARTMENT_FILES:
      return handle(state, action, {
        success: () => {
          const { apartmentId, path, documentId } = action.meta;

          const apartment = state.apartments[apartmentId];
          const key = apartmentFilePathToKey[path];

          if (!apartment || !key) return state;

          return {
            ...state,
            apartments: {
              ...state.apartments,
              [apartmentId]: {
                ...apartment,
                [key]: apartment[key].filter(
                  (document) => document.id !== documentId,
                ),
              },
            },
          };
        },
      });
    case SIMPLE_DELETE_CONTACT_FILE:
      return handle(state, action, {
        success: () => {
          const { contactId, path } = action.meta;

          const contact = state.contacts[contactId];
          const key = contactFilePathToKey[path];

          if (!contact || !key) return state;

          return {
            ...state,
            contacts: {
              ...state.contacts,
              [contactId]: {
                ...contact,
                [key]: null,
              },
            },
          };
        },
      });
    case SIMPLE_DELETE_CONTACT_FILES:
      return handle(state, action, {
        success: () => {
          const { contactId, path, documentId } = action.meta;

          const contact = state.contacts[contactId];
          const key = contactFilePathToKey[path];

          if (!contact || !key) return state;

          return {
            ...state,
            contacts: {
              ...state.contacts,
              [contactId]: {
                ...contact,
                [key]: contact[key].filter(
                  (document) => document.id !== documentId,
                ),
              },
            },
          };
        },
      });
    case SIMPLE_DELETE_LEAVING_FILE:
      return handle(state, action, {
        success: () => {
          const { leavingId, path } = action.meta;

          const leaving = state.leavings[leavingId];
          const key = leavingFilePathToKey[path];

          if (!leaving || !key) return state;

          return {
            ...state,
            leavings: {
              ...state.leavings,
              [leavingId]: {
                ...leaving,
                [key]: null,
              },
            },
          };
        },
      });
    case SIMPLE_POST_LEAVING_LAST_CHECK:
      return handle(state, action, {
        success: () => {
          const { leavingId } = action.meta;

          return {
            ...state,
            leavings: {
              ...state.leavings,
              [leavingId]: {
                ...state.leavings[leavingId],
                lastCheckId: action.payload,
                lastCheck: action.payload,
              },
            },
          };
        },
      });
    case SIMPLE_DELETE_LEAVING_LAST_CHECK:
      return handle(state, action, {
        success: () => {
          const { leavingId } = action.meta;

          return {
            ...state,
            leavings: {
              ...state.leavings,
              [leavingId]: {
                ...state.leavings[leavingId],
                lastCheckId: null,
                lastCheck: null,
              },
            },
          };
        },
      });
    case SIMPLE_POST_LEAVING_LAST_CHECK_DOCUMENT:
      return handle(state, action, {
        success: () => {
          const { lastCheckId } = action.meta;

          return {
            ...state,
            lastChecks: {
              ...state.lastChecks,
              [lastCheckId]: {
                ...state.lastChecks[lastCheckId],
                documents: [
                  ...(state.lastChecks[lastCheckId] &&
                  state.lastChecks[lastCheckId].documents &&
                  Array.isArray(state.lastChecks[lastCheckId].documents)
                    ? state.lastChecks[lastCheckId].documents
                    : []),
                  ...action.payload,
                ],
              },
            },
          };
        },
      });
    case SIMPLE_DELETE_LEAVING_LAST_CHECK_DOCUMENT:
      return handle(state, action, {
        success: () => {
          const { lastCheckId, documentId } = action.meta;

          return {
            ...state,
            lastChecks: {
              ...state.lastChecks,
              [lastCheckId]: {
                ...state.lastChecks[lastCheckId],
                documents: state.lastChecks[lastCheckId].documents.filter(
                  (file) => file.id !== documentId,
                ),
              },
            },
          };
        },
      });
    case FETCH_LEAVING_CHECKIN_SCORE:
    case FETCH_LEAVING_CHECKOUT_SCORE:
      return handle(state, action, {
        success: () => {
          const { id } = action.meta;

          const leaving = state.leavings[id];

          if (!leaving) return state;

          return {
            ...state,
            leavings: {
              ...state.leavings,
              [id]: {
                ...leaving,
                ...action.payload,
              },
            },
          };
        },
      });
    case POST_KEY_TAG:
      return handle(state, action, {
        success: () => {
          const { apartmentKeyId } = action.meta;

          const apartmentKey = state.apartmentKeys[apartmentKeyId];

          if (!apartmentKey) return state;

          return {
            ...state,
            apartmentKeys: {
              ...state.apartmentKeys,
              [apartmentKeyId]: {
                ...apartmentKey,
                tags: [...(apartmentKey.tags || []), action.payload],
              },
            },
          };
        },
      });
    case DELETE_KEY_TAG:
      return handle(state, action, {
        success: () => {
          const { apartmentKeyId } = action.meta;

          const apartmentKey = state.apartmentKeys[apartmentKeyId];

          if (!apartmentKey) return state;

          return {
            ...state,
            apartmentKeys: {
              ...state.apartmentKeys,
              [apartmentKeyId]: {
                ...apartmentKey,
                tags: (apartmentKey.tags || []).filter(
                  (tag) => tag.code !== action.payload.code,
                ),
              },
            },
          };
        },
      });
    case POST_CLEANING_TAG:
      return handle(state, action, {
        success: () => {
          const { cleaningContractId } = action.meta;

          const cleaningContract = state.cleaningContracts[cleaningContractId];

          if (!cleaningContract) return state;

          return {
            ...state,
            cleaningContracts: {
              ...state.cleaningContracts,
              [cleaningContractId]: {
                ...cleaningContract,
                tags: [...(cleaningContract.tags || []), action.payload],
              },
            },
          };
        },
      });
    case DELETE_CLEANING_TAG:
      return handle(state, action, {
        success: () => {
          const { cleaningContractId } = action.meta;

          const cleaningContract = state.cleaningContracts[cleaningContractId];

          if (!cleaningContract) return state;

          return {
            ...state,
            cleaningContracts: {
              ...state.cleaningContracts,
              [cleaningContractId]: {
                ...cleaningContract,
                tags: (cleaningContract.tags || []).filter(
                  (tag) => tag.code !== action.payload.code,
                ),
              },
            },
          };
        },
      });
    case PATCH_APARTMENT_ACCESS:
      return handle(state, action, {
        success: () => {
          const { apartmentId, accessId } = action.meta;

          const apartment = state.apartments[apartmentId];

          if (!apartment) return state;

          return {
            ...state,
            apartments: {
              ...state.apartments,
              [apartmentId]: {
                ...apartment,
                accesses: (apartment.accesses || []).map((access) =>
                  access.id === accessId ? action.payload : access,
                ),
              },
            },
          };
        },
      });
    case DELETE_APARTMENT_ACCESS:
      return handle(state, action, {
        success: () => {
          const { apartmentId, accessId } = action.meta;

          const apartment = state.apartments[apartmentId];

          if (!apartment) return state;

          return {
            ...state,
            apartments: {
              ...state.apartments,
              [apartmentId]: {
                ...apartment,
                accesses: (apartment.accesses || []).filter(
                  (access) => access.id !== accessId,
                ),
              },
            },
          };
        },
      });
    case DELETE_APARTMENT_RENTAL_AGENCY:
      return handle(state, action, {
        success: () => {
          const { apartmentId } = action.meta;

          const apartment = state.apartments[apartmentId];

          if (!apartment) return state;

          return {
            ...state,
            apartments: {
              ...state.apartments,
              [apartmentId]: {
                ...apartment,
                rentalAgency: undefined,
              },
            },
          };
        },
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
