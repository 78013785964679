import { normalize } from 'normalizr';
import { Action, Store } from 'src/types/redux';

import CONFIG from '@config';

import Toast from '@redux/models/Toast';

import { setToast, endLoading, startLoading } from '@redux/common/actions';
import { addEntities } from '@redux/entities/actions';
import { logout, LOGOUT } from '@redux/me/actions';

import { getMyToken } from '@redux/me/selectors';

const CONTENT_TYPE = 'Content-Type';

function buildQueryString(obj: {}): string {
  if (!obj || !Object.keys(obj).length) return '';

  return `?${Object.keys(obj)
    .filter((key) => ![null, undefined].includes(obj[key]))
    .map((k) => {
      if (Array.isArray(obj[k]))
        return obj[k].map((i) => `${k}=${i}`).join('&');

      return `${k}=${obj[k]}`;
    })
    .join('&')}`;
}

function getRequestUrl(action: Action) {
  const { route, query } = action;
  let url = route;

  if (route && route.charAt(0) === '/') url = CONFIG.API_URL;
  if (action.url) url = action.url;

  return url + route + buildQueryString(query);
}

function handleResponse(store: Store, action: Action, res: Response) {
  const contentType = res.headers.get(CONTENT_TYPE);

  let promise: Promise<any> = Promise.resolve();

  if (!contentType) {
    store.dispatch(endLoading());

    return promise;
  }

  if (
    contentType.match(/^application\/json/u) &&
    res.statusText !== 'No Content'
  )
    promise = res.json();

  if (contentType.match(/^text\//u) && res.statusText !== 'No Content')
    promise = res.text();

  return promise.then((result: any) => {
    store.dispatch(endLoading());

    if (!res.ok) handleError(store, action, result);

    if (!action.schema) return result;

    const normalized = normalize(result, action.schema);

    store.dispatch(addEntities(normalized.entities));

    return normalized.result;
  });
}

function handleError(store: Store, action: Action, e: any) {
  if (e instanceof Error && e.message === 'Network request failed') throw e;

  const status = (e && e.error && e.error.status) || e.statusCode;
  const message = (e && e.error && e.error.message) || e.message;
  const params = (e && e.error && e.error.messageParams) || e.messageParams;

  if (status && !action.hideError) {
    switch (status) {
      case 400: {
        store.dispatch(
          setToast(
            new Toast({
              variant: 'danger',
              text: message,
              autoClose: 5000,
              textParams: params,
            }),
          ),
        );
        break;
      }
      case 401: {
        if (action.type !== LOGOUT) store.dispatch(logout());
        break;
      }
      case 403: {
        store.dispatch(
          setToast(
            new Toast({
              variant: 'danger',
              text: 'common.errors.forbidden',
              autoClose: 5000,
            }),
          ),
        );
        break;
      }
      case 404: {
        store.dispatch(
          setToast(
            new Toast({
              variant: 'danger',
              text: 'common.errors.notFound',
              autoClose: 5000,
            }),
          ),
        );
        break;
      }
      default: {
        store.dispatch(
          setToast(
            new Toast({
              variant: 'danger',
              text: 'common.errors.unknown',
              autoClose: 5000,
            }),
          ),
        );
      }
    }
  }

  throw e.error;
}

// Cognitive complexity is here artificially high because of the three nested arrow functions
const apiMiddleware = (store: Store) => (next: Function) => (
  action?: Action,
) => {
  if (!action) return null;

  if (!action.route) return next(action);

  // TODO: code smell (action: any)
  const { getState } = store;
  const { baseUrl, ...opts } = action;
  const token = action.token || (baseUrl ? '' : getMyToken(getState()));
  const requestUrl = getRequestUrl(action);

  let tempHeader = new Headers();

  if (opts.headers && !(opts.headers instanceof Headers))
    tempHeader = new Headers(opts.headers);
  else if (opts.headers && opts.headers instanceof Headers)
    tempHeader = opts.headers;

  opts.headers = tempHeader;

  if (typeof opts.body === 'object' && !(opts.body instanceof FormData)) {
    if (!opts.headers.has(CONTENT_TYPE))
      opts.headers.append(CONTENT_TYPE, opts.contentType || 'application/json');

    if (
      opts.headers.has(CONTENT_TYPE) &&
      opts.headers.get(CONTENT_TYPE) === 'application/json'
    )
      opts.body = JSON.stringify(opts.body) as any;
  }

  if (token && !opts.headers.has('Authorization'))
    opts.headers.append('Authorization', token);

  let promise = Promise.resolve();

  promise = promise
    .then(() =>
      fetch(requestUrl, ({
        ...opts,
        credentials: 'include',
      } as any) as RequestInit),
    )
    .catch(handleError.bind(null, store, action))
    .then(handleResponse.bind(null, store, action));

  if (!action.hideLoading) store.dispatch(startLoading());

  return next({ ...action, promise });
};

export default apiMiddleware;
