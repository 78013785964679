export default class Neighborhood {
  public id: number;
  public name = '';
  public description = '';
  public transport = '';
  public agencyId: number;
  public isArchived = false;

  constructor(data: Partial<Neighborhood>) {
    Object.assign(this, data);
  }
}
