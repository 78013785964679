import * as React from 'react';

import { STitle } from '@smartrenting/smartomic';

import './GenericTablePage.scss';

type Props = {
  table: React.ReactNode;
  title: React.ReactNode;
};

export default class GenericTablePage extends React.PureComponent<Props, null> {
  static defaultProps = {
    table: null,
    title: null,
  };

  render() {
    const { table, title } = this.props;

    return (
      <div className="GenericTablePage">
        {typeof title === 'string' ? <STitle title={title} /> : title}
        <div className="GenericTablePage__table">{table}</div>
      </div>
    );
  }
}
