import { handle } from 'redux-pack';

import HostRents from '@redux/models/HostRents';

import { FETCH_HOST_RENTS } from '@redux/hostRents/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

const initialState = new HostRents({});

export default function hostRentsReducer(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case FETCH_HOST_RENTS:
      return handle(state, action, {
        success: () => ({
          ...state,
          hostRents: action.payload,
        }),
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
