import { handle } from 'redux-pack';

import Contractors from '@redux/models/Contractors';

import {
  DELETE_CONTRACTOR,
  FETCH_CONTRACTOR,
  FETCH_CONTRACTORS,
  POST_CONTRACTOR,
} from '@redux/contractors/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

const initialState = new Contractors({});

export default function contractorsReducer(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case FETCH_CONTRACTORS:
      return handle(state, action, {
        success: () => ({
          ...state,
          contractors: action.payload,
        }),
      });
    case FETCH_CONTRACTOR:
      return handle(state, action, {
        success: () => ({
          ...state,
          contractors: [
            ...state.contractors.filter(
              (contractor) => contractor !== action.payload,
            ),
            action.payload,
          ],
        }),
      });
    case POST_CONTRACTOR:
      return handle(state, action, {
        success: () => ({
          ...state,
          contractors: [...state.contractors, action.payload],
        }),
      });
    case DELETE_CONTRACTOR:
      return handle(state, action, {
        success: () => ({
          ...state,
          contractors: state.contractors.filter(
            (contractor) => contractor !== action.meta.contractorId,
          ),
        }),
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
