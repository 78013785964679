import moment from 'moment-timezone';

export default class RentalAgency {
  public id: number;
  public name = '';
  public contactName = '';
  public address = '';
  public phoneNumber = '';
  public email = '';
  public description = '';
  public createdAt: moment.Moment;

  constructor(data: Partial<RentalAgency>) {
    Object.assign(this, data);

    this.createdAt = data.createdAt ? moment(data.createdAt) : null;
  }
}
