import CityStatistic from './CityStatistic';

export default class Statistic {
  public total: number;
  public result: any;

  constructor(data: any) {
    this.total = data.total || 0;

    this.result =
      data.result &&
      data.result
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((result) => new CityStatistic(result));
  }
}
