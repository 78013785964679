import moment from 'moment-timezone';
import { Document } from 'src/types/global';

import ENUM from '@enum';

import Apartment from './Apartment';
import CleaningContract from './CleaningContract';
import { PublishComment } from './Comment';
import Commission from './Commission';
import Employee from './Employee';
import { Inventory } from './Inventory';
import KeySetup from './KeySetup';
import LastCheck from './LastCheck';
import Modality from './Modality';

interface LeavingData {
  createdAt?: string;
  endDate?: string;
  hostReturnDate?: string;
  signatureDate?: string;
  startDate?: string;
  updatedAt?: string;
  expectedInventoryInReviewDate?: string;
  expectedInventoryOutDate?: string;
  expectedInventoryOutValidationDate?: string;
}

export type LeavingActionType =
  | 'airbnb'
  | 'booking-com'
  | 'lloyd'
  | 'order-photo'
  | 'bookingsync';

export default class Leaving {
  public allowance = 0;
  public apartment: Apartment;
  public authorizationDocument: Document;
  public authorizationDocumentId: number;
  public departureDocument: Document;
  public departureDocumentId: number;
  public inventoryInOperationalId: number;
  public inventoryOutOperationalId: number;
  public inventoryOutValidationOperationalId: number;
  public inventoryInOperational: Employee;
  public inventoryOutOperational: Employee;
  public inventoryOutValidationOperational: Employee;
  public commission: Commission;
  public createdAt: moment.Moment;
  public endDate: moment.Moment;
  public hostReturnDate: moment.Moment;
  public hostReturnTime: string;
  public hostHasKey = false;
  public id: number;
  public isCheckinReady = false;
  public isCheckoutReady = false;
  public mandateExternalId: number;
  public mandateId: number | null;
  public mandate: Document;
  public monthlyCharges = 0;
  public parentId: number;
  public paymentDay: string;
  public rent = 0;
  public checkoutStatus: string;
  public signatureDate: moment.Moment;
  public startDate: moment.Moment;
  public status: string;
  public firstCleaningId: number;
  public firstCleaning: CleaningContract;
  public updatedAt: moment.Moment;
  public checkoutIncidentDescription: string;
  public incidentDocuments: Array<Document>;
  public keySetups: Array<KeySetup>;
  public lastCheck: LastCheck | null;
  public expectedInventoryInReviewDate: moment.Moment;
  public expectedInventoryOutDate: moment.Moment;
  public expectedInventoryOutValidationDate: moment.Moment;
  public lastCheckId: number | null;
  public lastCleaningId: number | null;
  public lastCleaning: CleaningContract;
  public checkinScore: number | null;
  public checkoutScore: number | null;
  public leavingReason: string;
  public country: string;
  public city: string;
  public details: string;
  public inventories: Inventory[];
  public isWaiting: boolean;
  public ft_new_mandate_bo: boolean;
  public isRenewal: boolean;
  public isExtension: boolean;
  public isPublished: boolean;
  public publishedAgentId: number;
  public publishedAgent: Employee;
  public leavingActions: LeavingActionType[] = [];
  public publishComments: PublishComment[] = [];
  public isFakeDate: boolean;
  public modality: Modality;

  constructor(data: Partial<Leaving | LeavingData>) {
    Object.assign(this, data);

    const {
      apartment,
      commission,
      firstCleaning,
      lastCleaning,
      keySetups,
      lastCheck,
      inventories,
      inventoryInOperational,
      inventoryOutOperational,
      inventoryOutValidationOperational,
      modality,
    } = data as Partial<Leaving>;

    this.apartment = apartment
      ? new Apartment({ ...apartment, leavings: [] })
      : null;
    this.commission = commission ? new Commission(commission) : null;
    this.createdAt = data.createdAt ? moment(data.createdAt) : null;
    this.endDate = data.endDate ? moment(data.endDate) : null;
    this.hostReturnDate = data.hostReturnDate
      ? moment(data.hostReturnDate)
      : null;
    this.signatureDate = data.signatureDate ? moment(data.signatureDate) : null;
    this.startDate = data.startDate ? moment(data.startDate) : null;
    this.firstCleaning = firstCleaning
      ? new CleaningContract(firstCleaning)
      : null;
    this.lastCleaning = lastCleaning
      ? new CleaningContract(lastCleaning)
      : null;
    this.updatedAt = data.updatedAt ? moment(data.updatedAt) : null;
    this.keySetups = keySetups || [];
    this.inventories = (inventories || []).map(
      (it) => new Inventory(it as any),
    );
    this.lastCheck = lastCheck ? new LastCheck(lastCheck) : null;
    this.expectedInventoryInReviewDate = data.expectedInventoryInReviewDate
      ? moment(data.expectedInventoryInReviewDate)
      : null;
    this.expectedInventoryOutDate = data.expectedInventoryOutDate
      ? moment(data.expectedInventoryOutDate)
      : null;
    this.expectedInventoryOutValidationDate = data.expectedInventoryOutValidationDate
      ? moment(data.expectedInventoryOutValidationDate)
      : null;

    this.inventoryInOperational = inventoryInOperational
      ? new Employee(inventoryInOperational)
      : null;
    this.inventoryOutOperational = inventoryOutOperational
      ? new Employee(inventoryOutOperational)
      : null;
    this.inventoryOutValidationOperational = inventoryOutValidationOperational
      ? new Employee(inventoryOutValidationOperational)
      : null;

    this.publishedAgent = this.publishedAgent
      ? new Employee(this.publishedAgent)
      : null;

    this.modality = modality ? new Modality(modality) : null;
  }

  getStartDateImportance() {
    const daysLeft = this.startDate.diff(moment(), 'd');

    if (daysLeft > 30) return ENUM.IMPORTANCE.LOW;

    if (daysLeft > 15) return ENUM.IMPORTANCE.MEDIUM;

    return ENUM.IMPORTANCE.HIGH;
  }

  getRemindingDays() {
    return moment(this.endDate).diff(moment(), 'days');
  }
}
