import { handle } from 'redux-pack';

import Activity from '@redux/models/Activity';
import Apartments from '@redux/models/Apartments';

import {
  FETCH_APARTMENT,
  FETCH_APARTMENT_POPOVER,
  FETCH_APARTMENT_CHECKIN,
  FETCH_APARTMENT_CHECKOUT,
  FETCH_APARTMENTS,
  FETCH_APARTMENT_ACTIVITY,
  FETCH_LEAVINGS_TO_CHECKIN,
  FETCH_LEAVINGS_TO_CHECKOUT,
  SET_APARTMENTS_LIST_TEXT_FILTER,
  SET_APARTMENTS_LIST_CAPACITY_FILTER,
  SET_APARTMENTS_LIST_AREAMIN_FILTER,
  SET_APARTMENTS_LIST_AREAMAX_FILTER,
  SET_APARTMENTS_LIST_CATEGORY_FILTER,
  SET_CHECKIN_TEXT_FILTER,
  SET_CHECKOUT_TEXT_FILTER,
  FETCH_APARTMENT_PENDING_LEAVINGS,
  FETCH_APARTMENT_BOOKINGS,
  SET_FOCUSED_LEAVING_CHECKIN_ID,
  SET_FOCUSED_LEAVING_CHECKOUT_ID,
  COMPLETE_SETUP_LEAVING,
  COMPLETE_UNSETUP_LEAVING,
  RESET_APARTMENT_ACTIVITIES,
  FETCH_APARTMENT_CLEANINGS,
  FETCH_LEAVINGS_INVENTORIES,
  FETCH_WAITING_LEAVINGS_INVENTORIES,
  FETCH_LEAVINGS_TO_PUBLISH,
  POST_LEAVING_PUBLISH,
  FETCH_LIST_APARTMENT_KEYS,
} from '@redux/apartments/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

const initialState = new Apartments({});

export default function apartmentReducer(state = initialState, action: any) {
  const { type } = action;

  // eslint-disable-next-line
  switch (type) {
    case FETCH_APARTMENTS:
      return handle(state, action, {
        start: () => ({
          ...state,
          apartmentDetails: null,
        }),
        success: () => ({
          ...state,
          apartments: action.meta.more
            ? state.apartments.concat(action.payload)
            : action.payload,
        }),
      });
    case FETCH_APARTMENT_ACTIVITY:
      return handle(state, action, {
        success: () => {
          return {
            ...state,
            apartmentActivity: action.payload.map(
              (activity) => new Activity(activity),
            ),
          };
        },
      });
    case FETCH_LEAVINGS_TO_CHECKIN:
      return handle(state, action, {
        start: () => ({
          ...state,
          apartmentDetails: null,
        }),
        success: () => ({
          ...state,
          leavingsToCheckin: action.meta.more
            ? state.leavingsToCheckin.concat(action.payload)
            : action.payload,
        }),
      });
    case FETCH_LEAVINGS_TO_CHECKOUT:
      return handle(state, action, {
        start: () => ({
          ...state,
          apartmentDetails: null,
        }),
        success: () => ({
          ...state,
          leavingsToCheckout: action.meta.more
            ? state.leavingsToCheckout.concat(action.payload)
            : action.payload,
        }),
      });
    case FETCH_LEAVINGS_INVENTORIES:
      return handle(state, action, {
        start: () => ({
          ...state,
          apartmentDetails: null,
        }),
        success: () => ({
          ...state,
          leavingsInventories: action.meta.more
            ? [...new Set(state.leavingsInventories.concat(action.payload))]
            : action.payload,
        }),
      });
    case FETCH_WAITING_LEAVINGS_INVENTORIES:
      return handle(state, action, {
        start: () => ({
          ...state,
          apartmentDetails: null,
        }),
        success: () => ({
          ...state,
          waitingLeavingsInventories: action.payload,
        }),
      });
    case FETCH_APARTMENT_PENDING_LEAVINGS:
      return handle(state, action, {
        start: () => ({
          ...state,
          apartmentDetails: null,
        }),
        success: () => ({
          ...state,
          apartmentsPendingLeavings: action.meta.more
            ? state.apartmentsPendingLeavings.concat(action.payload)
            : action.payload,
        }),
      });
    case FETCH_APARTMENT_BOOKINGS:
      return handle(state, action, {
        success: () => ({
          ...state,
          apartmentsBookings: action.payload,
        }),
      });
    case FETCH_APARTMENT_CLEANINGS:
      return handle(state, action, {
        success: () => ({
          ...state,
          apartmentsCleanings: action.payload,
        }),
      });
    case FETCH_APARTMENT:
      return {
        ...state,
        apartmentDetails: action.payload,
      };
    case FETCH_APARTMENT_POPOVER:
      return {
        ...state,
        apartmentPopover: action.payload,
      };
    case FETCH_APARTMENT_CHECKIN:
      return {
        ...state,
        apartmentCheckinDetails: action.payload,
      };
    case FETCH_APARTMENT_CHECKOUT:
      return {
        ...state,
        apartmentCheckoutDetails: action.payload,
      };
    case SET_APARTMENTS_LIST_TEXT_FILTER:
      return {
        ...state,
        apartmentsListTextFilter: action.text,
      };
    case SET_APARTMENTS_LIST_CAPACITY_FILTER:
      return {
        ...state,
        apartmentsListCapacityFilter: action.capacity,
      };
    case SET_APARTMENTS_LIST_AREAMIN_FILTER:
      return {
        ...state,
        apartmentsListAreaMinFilter: action.areaMin,
      };
    case SET_APARTMENTS_LIST_AREAMAX_FILTER:
      return {
        ...state,
        apartmentsListAreaMaxFilter: action.areaMax,
      };
    case SET_APARTMENTS_LIST_CATEGORY_FILTER:
      return {
        ...state,
        apartmentsListCategoryFilter: action.category,
      };
    case SET_CHECKIN_TEXT_FILTER:
      return {
        ...state,
        leavingsToCheckinTextFilter: action.text,
      };
    case SET_CHECKOUT_TEXT_FILTER:
      return {
        ...state,
        leavingsToCheckoutTextFilter: action.text,
      };
    case SET_FOCUSED_LEAVING_CHECKIN_ID:
      return {
        ...state,
        focusedLeavingCheckinId: action.payload,
      };
    case SET_FOCUSED_LEAVING_CHECKOUT_ID:
      return {
        ...state,
        focusedLeavingCheckoutId: action.payload,
      };
    case COMPLETE_UNSETUP_LEAVING:
      return handle(state, action, {
        success: () => {
          const { leavingId } = action.meta;

          return {
            ...state,
            leavingsToCheckout: state.leavingsToCheckout.filter(
              (leavingIdToCheckout) => leavingIdToCheckout !== leavingId,
            ),
          };
        },
      });
    case COMPLETE_SETUP_LEAVING:
      return handle(state, action, {
        success: () => {
          const { leavingId } = action.meta;

          return {
            ...state,
            leavingsToCheckin: state.leavingsToCheckin.filter(
              (leavingIdToCheckin) => leavingIdToCheckin !== leavingId,
            ),
          };
        },
      });
    case RESET_APARTMENT_ACTIVITIES:
      return {
        ...state,
        apartmentActivity: [],
      };
    case FETCH_LEAVINGS_TO_PUBLISH:
      return handle(state, action, {
        start: () => ({
          ...state,
          apartmentDetails: null,
        }),
        success: () => ({
          ...state,
          leavingsToPublish: action.meta.more
            ? [...new Set(state.leavingsToPublish.concat(action.payload))]
            : action.payload,
        }),
      });
    case POST_LEAVING_PUBLISH:
      return handle(state, action, {
        success: () => {
          const { leavingId } = action.meta;

          return {
            ...state,
            leavingsToPublish: state.leavingsToPublish.filter(
              (leaving) => leaving !== leavingId,
            ),
          };
        },
      });
    case FETCH_LIST_APARTMENT_KEYS:
      return handle(state, action, {
        start: () => ({
          ...state,
          apartmentDetails: null,
        }),
        success: () => ({
          ...state,
          leavingsWithKeys: action.meta.more
            ? [...new Set(state.leavingsWithKeys.concat(action.payload))]
            : action.payload,
        }),
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
