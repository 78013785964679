import React, { Component, FormEvent } from 'react';

import { faDownload, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CONFIG from '@config';

import './GenericLink.scss';

type Props = {
  id: number;
  text: string;
  token: string;
  onRemove?: Function;
};

class GenericLink extends Component<Props> {
  static defaultProps = {
    onRemove: null,
  };

  render() {
    const { text } = this.props;

    return (
      <div className="Generic-Link">
        <div className="Generic-Link__download">
          <FontAwesomeIcon
            className="Generic-Link__download__icon"
            icon={faDownload}
          />
          <a
            className="Generic-Link__download__link"
            onClick={this.handleClick}
            href="#"
          >
            {text}
          </a>
        </div>
        {this.renderDelete()}
      </div>
    );
  }

  renderDelete = () => {
    const { onRemove } = this.props;

    if (!onRemove) return null;

    return (
      <div className="Generic-Link__trash" onClick={this.handleDelete}>
        <FontAwesomeIcon icon={faTrashAlt} />
      </div>
    );
  };

  handleClick = (event: FormEvent) => {
    const { id, token, text } = this.props;

    event.preventDefault();

    const myHeaders = new Headers({ authorization: token });

    let fileName = '';

    return fetch(`${CONFIG.API_V2_URL}/documents/${id}`, { headers: myHeaders })
      .then((result) => {
        const contentDisposition =
          result.headers.get('Content-Disposition') || '';

        [, fileName] = contentDisposition.match(/filename="(.*)"/u) || [
          '',
          text,
        ];

        return result.blob();
      })
      .then((file: Blob) => {
        const a = document.createElement('a');

        a.href = URL.createObjectURL(file);
        a.download = fileName;

        if (document.body) document.body.appendChild(a);

        a.click();

        if (document.body) document.body.removeChild(a);
      });
  };

  handleDelete = (event: FormEvent) => {
    const { id, onRemove } = this.props;

    if (onRemove) onRemove(id);

    event.preventDefault();
  };
}

export default GenericLink;
