import PublishAction from './PublishAction';
import { KeyDenomiationsReferenceType, KeySitesReferenceType } from './types';

export default class References {
  public accessStepData: any;
  public accessStepTypes: any;
  public accessTypes: any;
  public agencies: any;
  public apartmentCategories: any;
  public apartmentEquipments: any;
  public apartmentOwnerships: any;
  public apartmentHousingTypes: any;
  public apartmentResidenceTypes: any;
  public apartmentKeyTypes: any;
  public apartmentKeyStatuses: any;
  public apartmentKeyDenominations: KeyDenomiationsReferenceType;
  public apartmentRisks: any;
  public apartmentStatuses: any;
  public bookingCheckStatuses: any;
  public bookingTags: any;
  public checkoutStatuses: any;
  public cleaningContractStatuses: any;
  public cleaningTags: any;
  public contractorTypes: any;
  public deliveryContractors: any;
  public internetFacilities: any;
  public keyProcesses: any;
  public keyTags: any;
  public keywordTypes: any;
  public keywords: any;
  public languages: any;
  public leavingLastCheckLevels: any;
  public leavingLastCheckTypes: any;
  public prospectImportances: any;
  public prospectOrigins: any;
  public prospectStatuses: any;
  public prospectVisitTypes: any;
  public publishActions: any;
  public rejectionMotives: any;
  public roomItemTypes: any;
  public roomTypes: any;
  public leavingReasons: any;
  public apartmentKeySites: KeySitesReferenceType;
  public notificationGroups: Array<{ code: string }>;

  constructor(data: any) {
    this.accessStepData = (data && data.accessStepData) || [];
    this.accessStepTypes = (data && data.accessStepTypes) || [];
    this.accessTypes = (data && data.accessTypes) || [];
    this.agencies = (data && data.agencies) || [];
    this.apartmentCategories = (data && data.apartmentCategories) || [];
    this.apartmentEquipments = (data && data.apartmentEquipments) || [];
    this.apartmentOwnerships = (data && data.apartmentOwnerships) || [];
    this.apartmentHousingTypes = (data && data.apartmentHousingTypes) || [];
    this.apartmentResidenceTypes = (data && data.apartmentResidenceTypes) || [];
    this.apartmentKeyTypes = (data && data.apartmentKeyTypes) || [];
    this.apartmentKeyStatuses = (data && data.apartmentKeyStatuses) || [];
    this.apartmentKeyDenominations =
      (data && data.apartmentKeyDenominations) || [];
    this.apartmentRisks = (data && data.apartmentRisks) || [];
    this.apartmentStatuses = (data && data.apartmentStatuses) || [];
    this.bookingCheckStatuses = (data && data.bookingCheckStatuses) || [];
    this.bookingTags = (data && data.bookingTags) || [];
    this.checkoutStatuses = (data && data.checkoutStatuses) || [];
    this.cleaningContractStatuses =
      (data && data.cleaningContractStatuses) || [];
    this.cleaningTags = (data && data.cleaningTags) || [];
    this.contractorTypes = (data && data.contractorTypes) || [];
    this.deliveryContractors = (data && data.deliveryContractors) || [];
    this.internetFacilities = (data && data.internetFacilities) || [];
    this.keyProcesses = (data && data.keyProcesses) || [];
    this.keyTags = (data && data.keyTags) || [];
    this.keywordTypes = (data && data.keywordTypes) || [];
    this.keywords = (data && data.keywords) || [];
    this.languages = (data && data.languages) || [];
    this.leavingLastCheckLevels = (data && data.leavingLastCheckLevels) || [];
    this.leavingLastCheckTypes = (data && data.leavingLastCheckTypes) || [];
    this.prospectImportances = (data && data.prospectImportances) || [];
    this.prospectOrigins = (data && data.prospectOrigins) || [];
    this.prospectStatuses = (data && data.prospectStatuses) || [];
    this.prospectVisitTypes = (data && data.prospectVisitTypes) || [];
    this.publishActions =
      (data &&
        data.publishActions &&
        data.publishActions.map(
          (publishAction) => new PublishAction(publishAction),
        )) ||
      [];
    this.rejectionMotives = (data && data.rejectionMotives) || [];
    this.roomItemTypes = (data && data.roomItemTypes) || [];
    this.roomTypes = (data && data.roomTypes) || [];
    this.leavingReasons = (data && data.leavingReasons) || [];
    this.apartmentKeySites = (data && data.apartmentKeySites) || [];
    this.notificationGroups = (data && data.notificationGroups) || [];

    this.publishActions.push(
      new PublishAction({
        code: 'type-apartment-immatriculation',
        category: 'general',
        position: 7,
        static: true,
      }),
    );
  }
}
