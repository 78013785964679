import moment from 'moment-timezone';
import type { Document } from 'src/types/global';

import Employee from './Employee';

export class Inventory {
  id: number;
  inventoryId: number;
  leavingId: number;
  parentId: number;
  status: string;
  type: string;
  contractorId: number;
  operationalId: number;
  operational?: Employee;
  documentId: number;
  document: Document;
  createdAt: moment.Moment;

  constructor(props: Partial<Inventory>) {
    Object.assign(this, props);

    this.operational = props.operational
      ? new Employee(props.operational)
      : null;
  }
}
