import { handle } from 'redux-pack';

import Neighborhoods from '@redux/models/Neighborhoods';

import { LOGOUT, RESET } from '@redux/me/actions';

import { FETCH_NEIGHBORHOODS } from './actions';

const initialState = new Neighborhoods({});

export default function neighborhoodsReducer(
  state = initialState,
  action: any,
) {
  const { type } = action;

  switch (type) {
    case FETCH_NEIGHBORHOODS:
      return handle(state, action, {
        success: () => ({
          ...state,
          neighborhoods: action.meta.more
            ? state.neighborhoods.concat(action.payload)
            : action.payload,
        }),
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
