import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { State } from 'src/types/redux';

import Activity from '@redux/models/Activity';
import Booking from '@redux/models/Booking';
import Bookings from '@redux/models/Bookings';

import { getEntities } from '@redux/entities/selectors';

import { booking, bookingCheckinCheckout } from '@redux/schema';

export const getBookingsState = (state: State): Bookings => state.bookings;

export const getBookingsListTextFilter = createSelector(
  getBookingsState,
  (bookingState) => bookingState.bookingsListTextFilter,
);

export const getBookingsList = createSelector(
  getEntities,
  getBookingsState,
  (entities, bookingState) =>
    denormalize(bookingState.bookings, [booking], entities).map(
      (b) => new Booking(b),
    ),
);

export const getBookingsCheckinCheckoutList = createSelector(
  getEntities,
  getBookingsState,
  (entities, bookingState) =>
    denormalize(
      bookingState.checkinCheckout,
      [bookingCheckinCheckout],
      entities,
    ).map((b) => new Booking(b)),
);

export const getBookingDetails = createSelector(
  getEntities,
  getBookingsState,
  (entities, { bookingDetails }) => {
    const bookingData = denormalize(bookingDetails, booking, entities);

    return bookingData && new Booking(bookingData);
  },
);

export const getBookingGuest = createSelector(
  getBookingDetails,
  (booking) => booking.guest,
);

export const getBooking = (bookingId: number) =>
  createSelector(getBookingsList, (bookings) => {
    return bookings.find((booking) => booking.id === bookingId);
  });

export const getBookingFromCheckinCheckout = (
  bookingId: number,
  bookingType?: string,
) =>
  createSelector(getBookingsCheckinCheckoutList, (bookings) => {
    return !bookingType
      ? bookings.find((booking) => booking.id === bookingId)
      : bookings.find(
          (booking) =>
            booking.id === bookingId && booking.checkType === bookingType,
        );
  });

export const getBookingActivities = createSelector(
  getBookingsState,
  (bookingState) =>
    bookingState.bookingActivities.map((activity) => new Activity(activity)),
);

export const getBookingDepositFilter = createSelector(
  getBookingsState,
  (bookingState) => bookingState.bookingDepositFilter,
);

export const getBookingAccessFilter = createSelector(
  getBookingsState,
  (bookingState) => bookingState.bookingAccessStatusFilter,
);

export const getBookingIdentityFilter = createSelector(
  getBookingsState,
  (bookingState) => bookingState.bookingIdentityCheckFilter,
);

export const getBookingTypeFilter = createSelector(
  getBookingsState,
  (bookingState) => bookingState.bookingTypeFilter,
);
