import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { State } from 'src/types/redux';

import Prospect from '@redux/models/Prospect';
import Prospects from '@redux/models/Prospects';

import { getEntities } from '@redux/entities/selectors';

import { prospect } from '@redux/schema';

export const getProspectState = (state: State): Prospects => state.prospects;

export const getProspectsListTextFilter = createSelector(
  getProspectState,
  (prospectState) => prospectState.prospectsListTextFilter,
);

export const getProspectsList = createSelector(
  getEntities,
  getProspectState,
  (entities, prospectState): Prospect[] => {
    return denormalize(prospectState.prospects, [prospect], entities).map(
      (p) => new Prospect(p),
    );
  },
);

export const getProspectsTotal = createSelector(
  getEntities,
  getProspectState,
  (entities, prospectState): number => prospectState.prospectsTotal,
);

export const getNewProspectsList = createSelector(
  getEntities,
  getProspectState,
  (entities, prospectState) => {
    return denormalize(prospectState.prospectsNew, [prospect], entities).map(
      (p) => new Prospect(p),
    );
  },
);

export const getSignedProspectsList = createSelector(
  getEntities,
  getProspectState,
  (entities, prospectState) => {
    return denormalize(prospectState.prospectsSigned, [prospect], entities).map(
      (p) => new Prospect(p),
    );
  },
);

export const getProspectDetails = createSelector(
  getEntities,
  getProspectState,
  (entities, { prospectDetails }) => {
    const prospectData = denormalize(prospectDetails, prospect, entities);

    return prospectData && new Prospect(prospectData);
  },
);

export const getProspectDetailsId = createSelector(
  getProspectDetails,
  (prospect) => prospect.id,
);

export const getProspectStatus = createSelector(
  getProspectDetails,
  (prospect) => prospect.status,
);

export const getProspectActivity = createSelector(
  getProspectState,
  (prospect) => prospect.prospectActivity,
);

export const getProspectReminders = createSelector(
  getProspectState,
  (prospect) => prospect.prospectReminders,
);

export const getProspectsStatistics = createSelector(
  getProspectState,
  (prospect) => prospect.statistics,
);

export const getProspectApartment = createSelector(
  getProspectDetails,
  (prospect) => prospect.apartment,
);

export const getProspectRentalAgency = createSelector(
  getProspectApartment,
  (apartment) => apartment.rentalAgency,
);

export const getProspectContact = createSelector(
  getProspectApartment,
  (apartment) => apartment.contact,
);

export const getProspectLeaving = createSelector(
  getProspectApartment,
  (apartment) => apartment.leavings[0],
);
