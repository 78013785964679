import moment from 'moment-timezone';

import ENUM from '@enum';

import Activity from './Activity';
import Apartment from './Apartment';
import ProspectStatusHistory from './ProspectStatusHistory';

export default class Prospect {
  public apartment: Apartment;
  public createdAt: moment.Moment;
  public updatedAt: moment.Moment;
  public discountCode = '';
  public id: number;
  public isAuthorized: boolean;
  public source: string = null;
  public origin: string = null;
  public originDetails = '';
  public phoneCallDate: moment.Moment;
  public status: string;
  public visitType: string;
  public visitDate: moment.Moment;
  public visitedDate: moment.Moment;
  public rejectionMotive: string;
  public highlightedActivity: Activity;
  public highlightedActivityId: number;
  public importance: string = ENUM.IMPORTANCE.HIGH;
  public statusesHistory: Array<ProspectStatusHistory>;
  public statusSince: moment.Moment;
  public reminders: number;
  public pausedUntil: moment.Moment;
  public isPaused: boolean;
  public nbIdentityDocuments: number;
  public firstCallGlobalInformation?: string;
  public firstCallAttentionPoints?: string;
  public visitGlobalInformation?: string;
  public visitAttentionPoints?: string;
  public utmSource?: string;
  public utmContent?: string;
  public utmCampaign?: string;
  public utmMedium?: string;
  public utmTerm?: string;

  constructor(
    data: Partial<
      | Prospect
      | {
          createdAt?: string;
          updatedAt?: string;
          phoneCallDate?: string;
          visitDate?: string;
          visitedDate?: string;
          statusSince?: string;
          pausedUntil?: string;
          reminders?: number;
        }
    > = {},
  ) {
    Object.assign(this, data);

    const {
      apartment,
      highlightedActivity,
      importance,
      statusesHistory,
    } = data as Partial<Prospect>;

    this.apartment = apartment ? new Apartment(apartment) : undefined;
    this.createdAt = data.createdAt && moment(data.createdAt);
    this.updatedAt = data.updatedAt && moment(data.updatedAt);
    this.phoneCallDate = data.phoneCallDate && moment(data.phoneCallDate);
    this.visitDate = data.visitDate && moment(data.visitDate);
    this.visitedDate = data.visitedDate && moment(data.visitedDate);
    this.statusSince = data.statusSince && moment(data.statusSince);
    this.pausedUntil = data.pausedUntil && moment(data.pausedUntil);

    this.highlightedActivity = highlightedActivity
      ? new Activity(highlightedActivity)
      : null;
    this.statusesHistory = (statusesHistory || []).map(
      (statusHistory) => new ProspectStatusHistory(statusHistory),
    );
    this.reminders = data.reminders;

    const now = moment();
    const diffDays = now.diff(
      moment(this.apartment ? this.apartment.leavings[0].startDate : now),
      'd',
    );
    if (!importance && diffDays < -15) this.importance = ENUM.IMPORTANCE.MEDIUM;
    if (!importance && diffDays < -30) this.importance = ENUM.IMPORTANCE.LOW;
  }

  getMandatoryDocuments = () => {
    const mandatoryDocuments = {
      identityDocuments: this.nbIdentityDocuments,
      [`proofDocument${this.apartment?.ownership}`]: this.apartment
        ?.proofDocumentId,
    };

    if (!this.apartment.isOwner())
      mandatoryDocuments.authorizationDocument = this.apartment.leavings[0].authorizationDocumentId;

    return mandatoryDocuments;
  };

  getMissingMandatoryDocumentsKeys = () => {
    const mandatoryDocuments = this.getMandatoryDocuments();

    return Object.keys(mandatoryDocuments).filter(
      (it) => mandatoryDocuments[it] === null,
    );
  };
}
