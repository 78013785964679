import { handle } from 'redux-pack';

import Users from '@redux/models/Users';

import { LOGOUT, RESET } from '@redux/me/actions';
import { FETCH_USERS, POST_USER, DELETE_USER } from '@redux/users/actions';

const initialState = new Users({});

export default function usersReducer(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case FETCH_USERS:
      return handle(state, action, {
        success: () => ({
          ...state,
          users: action.payload,
        }),
      });
    case POST_USER:
      return handle(state, action, {
        success: () => ({
          ...state,
          users: [...state.users, action.payload],
        }),
      });
    case DELETE_USER:
      return handle(state, action, {
        success: () => {
          return {
            ...state,
            users: [
              ...state.users.filter((user: number) => user !== action.payload),
            ],
          };
        },
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
