import React, { Component } from 'react';

import { GenericPopover } from '@components';

import './GenericInitial.scss';

type Props = {
  firstname: string;
  lastname: string;
  tooltip?: string;
};

type State = {
  code: string;
};

class GenericInitial extends Component<Props, State> {
  static defaultProps = {
    tooltip: null,
  };

  state = {
    code: '#000000',
  };

  componentDidMount() {
    this.setColor();
  }

  componentDidUpdate(prevProps: Props) {
    const { firstname, lastname } = this.props;

    if (prevProps.firstname !== firstname || prevProps.lastname !== lastname)
      this.setColor();
  }

  render() {
    const { firstname, lastname, tooltip } = this.props;
    const { code } = this.state;

    const style = {
      backgroundColor: code,
    };

    return (
      <GenericPopover
        placement="bottom"
        popover={<div>{tooltip || `${firstname} ${lastname}`}</div>}
      >
        <span className="Generic-Initial" style={style}>
          {`${firstname.substring(0, 1)}${lastname.substring(0, 1)}`}
        </span>
      </GenericPopover>
    );
  }

  setColor = () => {
    const { firstname, lastname } = this.props;
    const { code } = this.state;

    const name = lastname.substr(0, 3) + firstname.substr(0, 3);

    let hexaCode = [...code];

    switch (name) {
      case '+': {
        hexaCode = [...'#ef476b'];
        break;
      }
      case '?': {
        hexaCode = [...'#8e8e8e'];
        break;
      }
      default: {
        [...name].forEach((char, i) => {
          hexaCode[i + 1] = (char.charCodeAt(0) % 16)
            .toString(16)
            .toUpperCase();
        });
      }
    }
    this.setState({ code: hexaCode.join('') });
  };
}

export default GenericInitial;
