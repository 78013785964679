import CONFIG from '@config';

import { hostRent } from '@redux/schema';

export const FETCH_HOST_RENTS = 'FETCH_HOST_RENTS';
export const fetchHostRents = (
  dueDateYear: number,
  dueDateMonth: number,
  day?: number,
  agencyId = '',
  text = '',
  type?: string,
) => ({
  type: FETCH_HOST_RENTS,
  route: '/host-rents',
  method: 'GET',
  query: {
    dueDateYear,
    dueDateMonth,
    day,
    agencyId,
    text,
    type,
  },
  schema: [hostRent],
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const FETCH_HOST_RENT = 'FETCH_HOST_RENT';
export const fetchHostRent = (id: number) => ({
  type: FETCH_HOST_RENT,
  route: `/host-rents/${id}`,
  method: 'GET',
  schema: hostRent,
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const POST_VALIDATE_HOST_RENT = 'POST_VALIDATE_HOST_RENT';
export const postValidateHostRent = (hostRentId: number) => ({
  type: POST_VALIDATE_HOST_RENT,
  route: `/host-rents/${hostRentId}/validate`,
  method: 'POST',
  schema: hostRent,
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const POST_INVALIDATE_HOST_RENT = 'POST_INVALIDATE_HOST_RENT';
export const postInvalidateHostRent = (hostRentId: number) => ({
  type: POST_INVALIDATE_HOST_RENT,
  route: `/host-rents/${hostRentId}/invalidate`,
  method: 'POST',
  schema: hostRent,
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});
