import { useSelector } from 'react-redux';

import { ROLE } from '@enum';

import { getMyContractor } from '@redux/me/selectors';

export const useHomePathFromRole = (
  role: typeof ROLE[keyof typeof ROLE],
): string => {
  const contractor = useSelector(getMyContractor);
  let path = '';

  switch (role) {
    case ROLE.ADMIN:
    case ROLE.HEAD_OF_SALES:
    case ROLE.ACCOUNT_MANAGER:
    case ROLE.CUSTOMER_EXPERIENCE:
    case ROLE.SALE: {
      path = '/prospects/dashboard';
      break;
    }
    case ROLE.HEAD_OF_OPS:
    case ROLE.CUSTOMER_EXPERIENCE:
    case ROLE.PROBLEM_SOLVER:
    case ROLE.GUEST_MANAGER:
    case ROLE.INVENTORY_AGENT: {
      path = '/apartments';
      break;
    }
    case ROLE.CLEANING_CONTRACTOR: {
      const missions = contractor?.missions || [];

      if (missions.length === 0) {
        path = '/settings/user';
        break;
      }

      path = '/contracts';
      break;
    }
    case ROLE.CLEANING_CONTRACTOR_ADMIN: {
      const missions = contractor?.missions || [];

      if (missions?.length === 0) {
        path = '/settings/user';
        break;
      }

      path = '/contracts';
      break;
    }
    default: {
      path = '/settings/user';
      break;
    }
  }

  return path;
};
