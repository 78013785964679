import { handle } from 'redux-pack';

import Bookings from '@redux/models/Bookings';

import {
  FETCH_BOOKING,
  FETCH_BOOKINGS,
  FETCH_BOOKING_ACTIVITIES,
  FETCH_BOOKINGS_CHECKIN_CHECKOUT,
  SET_BOOKINGS_LIST_TEXT_FILTER,
  SET_BOOKING_FILTER_IDENTITY,
  SET_BOOKING_FILTER_ACCESS,
  SET_BOOKING_FILTER_DEPOSIT,
  SET_BOOKING_FILTER_TYPE,
  POST_BOOKING_ACTIVITY,
} from '@redux/bookings/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

const initialState = new Bookings({});

export default function bookingsReducer(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case FETCH_BOOKINGS:
      return handle(state, action, {
        start: () => ({
          ...state,
          bookingDetails: null,
        }),
        success: () => ({
          ...state,
          bookings: action.meta.more
            ? state.bookings.concat(action.payload)
            : action.payload,
        }),
      });
    case FETCH_BOOKINGS_CHECKIN_CHECKOUT:
      return handle(state, action, {
        start: () => ({
          ...state,
          bookingDetails: null,
        }),
        success: () => ({
          ...state,
          checkinCheckout: action.meta.more
            ? state.checkinCheckout.concat(action.payload)
            : action.payload,
        }),
      });
    case FETCH_BOOKING:
      return handle(state, action, {
        start: () => ({
          ...state,
          bookingDetails: null,
        }),
        success: () => ({
          ...state,
          bookingDetails: action.payload,
        }),
      });
    case FETCH_BOOKING_ACTIVITIES:
      return handle(state, action, {
        success: () => {
          return {
            ...state,
            bookingActivities: action.payload,
          };
        },
      });
    case POST_BOOKING_ACTIVITY:
      return handle(state, action, {
        success: () => {
          return {
            ...state,
            bookingActivities: [action.payload, ...state.bookingActivities],
          };
        },
      });
    case SET_BOOKINGS_LIST_TEXT_FILTER:
      return {
        ...state,
        bookingsListTextFilter: action.text,
      };
    case SET_BOOKING_FILTER_IDENTITY:
      return {
        ...state,
        bookingIdentityCheckFilter: action.identityFilter,
      };
    case SET_BOOKING_FILTER_ACCESS:
      return {
        ...state,
        bookingAccessStatusFilter: action.accessFilter,
      };
    case SET_BOOKING_FILTER_DEPOSIT:
      return {
        ...state,
        bookingDepositFilter: action.depositFilter,
      };
    case SET_BOOKING_FILTER_TYPE:
      return {
        ...state,
        bookingTypeFilter: action.typeFilter,
      };
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
