import { handle } from 'redux-pack';

import Agency from '@redux/models/Agency';
import References from '@redux/models/References';

import {
  FETCH_AGENCIES,
  FETCH_REFERENCES,
  PATCH_AGENCY,
  POST_AGENCY,
} from '@redux/config/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

const initialState = new References({});

export default function configReducer(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case FETCH_REFERENCES:
      return handle(state, action, {
        success: () => new References({ ...state, ...action.payload }),
      });
    case FETCH_AGENCIES:
      return handle(state, action, {
        success: () => ({
          ...state,
          agencies: action.payload.map((agency) => new Agency(agency)),
        }),
      });
    case PATCH_AGENCY:
      return handle(state, action, {
        success: () => ({
          ...state,
          agencies: state.agencies.map((agency) =>
            agency.id === action.payload.id
              ? new Agency(action.payload)
              : agency,
          ),
        }),
      });
    case POST_AGENCY:
      return handle(state, action, {
        success: () => ({
          ...state,
          agencies: [new Agency(action.payload), ...state.agencies],
        }),
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
