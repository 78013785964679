import { handle } from 'redux-pack';

import {
  ENTER_EMAIL,
  LOGIN,
  LOGOUT,
  PATCH_ME,
  PATCH_PROFILE_PICTURE,
  RESET,
  SET_TOKEN,
} from '@redux/me/actions';

export default function meReducer(state: any = {}, action: any) {
  const { type } = action;

  switch (type) {
    case ENTER_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case PATCH_ME:
    case PATCH_PROFILE_PICTURE:
    case LOGIN:
      return handle(state, action, {
        success: () => ({
          ...state,
          meId: action.payload,
        }),
      });
    case SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };
    case LOGOUT:
    case RESET:
      return {};
    default:
      return state;
  }
}
