import React, { ChangeEvent, Component } from 'react';
import { Form } from 'react-bootstrap';
import Datetime from 'react-datetime';

import moment from 'moment-timezone';
import 'moment/locale/fr';

import { faCalendarDay } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GenericFormGroup } from '@components';

import './GenericDatePicker.scss';

type Props = {
  value?: string | moment.Moment;
  onChange: Function;
  label?: string;
  placeholder?: string;
  required?: boolean;
  controlId?: string;
  disabled?: boolean;
  viewDate?: moment.Moment;
  className?: string;
  min?: string | moment.Moment;
  max?: string | moment.Moment;
  prepend?: string;
  append?: string;
};

class GenericDatePicker extends Component<Props> {
  static defaultProps = {
    controlId: null,
    disabled: false,
    viewDate: moment(),
    className: '',
    min: '',
    max: '',
    prepend: '',
    append: '',
  };

  render() {
    const {
      label,
      required,
      controlId,
      className,
      prepend,
      append,
    } = this.props;

    return (
      <GenericFormGroup
        className={`GenericDatePicker ${className || ''}`}
        label={label}
        required={required}
        controlId={controlId}
        prepend={prepend}
        append={append}
      >
        {this.renderDatePicker()}
      </GenericFormGroup>
    );
  }

  renderDatePicker = () => {
    const {
      value,
      viewDate,
      label,
      placeholder,
      required,
      disabled,
    } = this.props;

    return (
      <div className="GenericDatePicker__input">
        <Datetime
          {...{
            viewDate,
          }}
          className="GenericDatePicker__input__picker GenericDatePicker__input__picker--screen"
          locale="fr"
          inputProps={{
            placeholder: placeholder || label,
            required,
            disabled,
          }}
          closeOnSelect
          timeFormat={false}
          value={value}
          isValidDate={this.onHandleValid}
          onChange={this.handleChange as any}
        />
        <FontAwesomeIcon
          className="GenericDatePicker__input__icon"
          icon={faCalendarDay}
        />
        <Form.Control
          {...{
            locale: 'fr',
          }}
          className="GenericDatePicker__input__picker GenericDatePicker__input__picker--mobile"
          placeholder={placeholder || label}
          required={required}
          disabled={disabled}
          type="date"
          value={
            moment.isMoment(value) ? moment(value).format('YYYY-MM-DD') : ''
          }
          onChange={this.handleChange}
        />
      </div>
    );
  };

  handleChange = (event: string | moment.Moment | ChangeEvent<any>) => {
    const { onChange } = this.props;

    if (moment.isMoment(event) || typeof event === 'string') {
      const date = event;

      if (date === '' || (moment.isMoment(date) && moment(date).isValid()))
        return onChange(date);
    }

    const mouseEvent = event as ChangeEvent<any>;
    if (
      mouseEvent.currentTarget &&
      mouseEvent.currentTarget.value &&
      moment(mouseEvent.currentTarget.value).isValid()
    )
      return onChange(moment(mouseEvent.currentTarget.value, 'YYYY-MM-DD'));

    return null;
  };

  onHandleValid = (current: moment.Moment): boolean => {
    const { min, max } = this.props;

    let valid = true;

    if (min) valid = current.isSameOrAfter(min, 'date');

    if (valid && max) valid = current.isSameOrBefore(max, 'date');

    return valid;
  };
}

export default GenericDatePicker;
