import { Dispatch } from '@types/redux';

import moment from 'moment-timezone';

import CONFIG from '@config';

import ENUM from '@enum';

import * as schema from '@redux/schema';

const { BOOKING_CHECK_STATUS, DEPOSIT_STATUS } = ENUM;

export const FETCH_BOOKINGS = 'FETCH_BOOKINGS';
export const fetchBookings = (
  offset = 0,
  limit = 10,
  more = false,
  agencyId = '',
  text = '',
  order = '',
) => ({
  type: FETCH_BOOKINGS,
  route: '/bookings',
  method: 'GET',
  query: {
    offset,
    limit,
    agencyId,
    text,
    order,
  },
  meta: {
    more,
  },
  schema: [schema.booking],
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_BOOKINGS_CHECKIN_CHECKOUT =
  'FETCH_BOOKINGS_CHECKIN_CHECKOUT';
export const fetchBookingsCheckinCheckout = (
  offset = 0,
  limit = 10,
  more = false,
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  agencyId = '',
  text = '',
  depositStatus: Array<string> = Object.values(DEPOSIT_STATUS),
  identityCheckStatus: Array<string> = Object.values(BOOKING_CHECK_STATUS),
  accessCheckStatus: Array<string> = Object.values(BOOKING_CHECK_STATUS),
  checkType = '',
  type = '',
  onlyCanceled = false,
) => {
  const query = {
    offset,
    limit,
    startDate,
    endDate,
    agencyId,
    text,
    depositStatus,
    identityCheckStatus,
    accessCheckStatus,
    checkType,
    type,
    onlyCanceled,
  };

  return {
    type: FETCH_BOOKINGS_CHECKIN_CHECKOUT,
    route: '/bookings/checkincheckout',
    method: 'GET',
    query,
    meta: {
      more,
    },
    schema: [schema.bookingCheckinCheckout],
    hideLoading: true,
    url: CONFIG.API_V2_URL,
  };
};

export const FETCH_BOOKING = 'FETCH_BOOKING';
export const fetchBooking = (id: number) => ({
  type: FETCH_BOOKING,
  route: `/bookings/${id}`,
  method: 'GET',
  schema: schema.booking,
  url: CONFIG.API_V2_URL,
});

export const FETCH_BOOKING_ACTIVITIES = 'FETCH_BOOKING_ACTIVITIES';
export const fetchBookingActivities = (id: number) => ({
  type: FETCH_BOOKING_ACTIVITIES,
  route: `/bookings/${id}/activities`,
  method: 'GET',
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const POST_BOOKING_ACTIVITY = 'POST_BOOKING_ACTIVITY';
export const postBookingActivity = (id: number, formData: FormData) => (
  dispatch: Dispatch,
) =>
  dispatch({
    type: POST_BOOKING_ACTIVITY,
    route: `/bookings/${id}/activity`,
    method: 'POST',
    body: formData,
    url: CONFIG.API_V2_URL,
  });

export const FETCH_BOOKING_MAILS = 'FETCH_BOOKING_MAILS';
export const fetchBookingMails = (id: number) => ({
  type: FETCH_BOOKING_MAILS,
  route: `/bookings/${id}/mails`,
  method: 'GET',
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const SET_BOOKINGS_LIST_TEXT_FILTER = 'SET_BOOKINGS_LIST_TEXT_FILTER';
export const setBookingsListTextFilter = (text: string) => ({
  type: SET_BOOKINGS_LIST_TEXT_FILTER,
  text,
});

export const PATCH_BOOKING = 'PATCH_BOOKING';
export const patchBooking = (
  id: number,
  bookingModifications: Partial<{
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    notes?: string;
    finalPrice?: number;
    adults?: number;
    children?: number;
    firstname?: string;
    lastname?: string;
    email?: string;
    phoneNumber?: string;
    expectedCheckinTime?: string;
    expectedCheckoutTime?: string;
    identityCheckStatus?: string;
    comment?: string;
    tags: { code: string }[];
  }>,
) => (dispatch: Dispatch) =>
  dispatch({
    type: PATCH_BOOKING,
    route: `/bookings/${id}`,
    method: 'PATCH',
    hideLoading: false,
    body: bookingModifications,
    schema: schema.booking,
    url: CONFIG.API_V2_URL,
    meta: {
      tags: bookingModifications.tags,
      comment: bookingModifications.comment,
    },
  }).then(() => dispatch(fetchBookingActivities(id)));

export const DELETE_BOOKING = 'DELETE_BOOKING';
export const deleteBooking = (id: number) => ({
  type: DELETE_BOOKING,
  route: `/bookings/${id}`,
  method: 'DELETE',
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const SET_BOOKING_FILTER_IDENTITY = 'SET_BOOKING_FILTER_IDENTITY';
export const setBookingIdentityFilter = (identityFilter?: Array<string>) => ({
  type: SET_BOOKING_FILTER_IDENTITY,
  identityFilter,
});

export const SET_BOOKING_FILTER_ACCESS = 'SET_BOOKING_FILTER_ACCESS';
export const setBookingAccessFilter = (accessFilter?: Array<string>) => ({
  type: SET_BOOKING_FILTER_ACCESS,
  accessFilter,
});

export const SET_BOOKING_FILTER_DEPOSIT = 'SET_BOOKING_FILTER_DEPOSIT';
export const setBookingDepositFilter = (depositFilter?: string) => ({
  type: SET_BOOKING_FILTER_DEPOSIT,
  depositFilter,
});

export const SET_BOOKING_FILTER_TYPE = 'SET_BOOKING_FILTER_TYPE';
export const setBookingTypeFilter = (typeFilter?: string) => ({
  type: SET_BOOKING_FILTER_TYPE,
  typeFilter,
});

export const FETCH_BOOKING_TAGS = 'FETCH_BOOKING_TAGS';
export const fetchBookingTags = (id: number) => ({
  type: FETCH_BOOKING_TAGS,
  route: `/bookings/${id}/tags`,
  method: 'GET',
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_BOOKING_ACCESS_MAIL = 'FETCH_BOOKING_ACCESS_MAIL';
export const fetchBookingAccessMail = (id: number) => ({
  type: FETCH_BOOKING_ACCESS_MAIL,
  route: `/bookings/${id}/access-mail`,
  method: 'GET',
  url: CONFIG.API_V2_URL,
});

export const POST_BOOKING_ACCESS_MAIL = 'POST_BOOKING_ACCESS_MAIL';
export const postBookingAccessMail = (id: number, contentMail: string) => ({
  type: POST_BOOKING_ACCESS_MAIL,
  route: `/bookings/${id}/access-mail`,
  method: 'POST',
  body: {
    contentMail,
  },
  meta: {
    bookingId: id,
    contentMail,
  },
  url: CONFIG.API_V2_URL,
});

export const POST_BOOKING_ACCESS_KEY_INFO = 'POST_BOOKING_ACCESS_KEY_INFO';
export const postBookingAccessKeyInfo = (
  id: number,
  apartmentKeyId: number,
  accessStartDate: Date,
  accessEndDate: Date,
) => ({
  type: POST_BOOKING_ACCESS_KEY_INFO,
  route: `/bookings/${id}/access-key-infos`,
  method: 'POST',
  body: {
    apartmentKeyId,
    accessStartDate,
    accessEndDate,
  },
  meta: {
    bookingId: id,
  },
  url: CONFIG.API_V2_URL,
});
