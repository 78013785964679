import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import { GenericModal } from '@components';

import './GenericConfirmationModal.scss';

type Props = {
  title: string;
  body: string;
  confirm: string;
  cancel: string;
  onConfirm: Function;
  onExited?: Function;
  isOpen?: boolean;
};

type State = {
  show: boolean;
};

class GenericConfirmationModal extends Component<Props, State> {
  static defaultProps = {
    onExited: () => {},
    isOpen: null,
  };

  state = {
    show: false,
  };

  render() {
    const { body, title, isOpen } = this.props;
    const { show } = this.state;

    return (
      <GenericModal
        isOpen={show || isOpen}
        title={title}
        footer={this.renderFooter()}
        onExited={this.handleCloseModal}
      >
        <div className="GenericConfirmationModal">{body}</div>
      </GenericModal>
    );
  }

  renderFooter = () => {
    const { confirm, cancel } = this.props;

    return (
      <React.Fragment>
        <Button
          form="userForm"
          type="submit"
          className="full-mobile"
          variant="primary"
          onClick={this.handleConfirm}
        >
          {confirm}
        </Button>
        <Button
          form="userForm"
          type="submit"
          className="full-mobile"
          variant="secondary"
          onClick={this.handleCloseModal}
        >
          {cancel}
        </Button>
      </React.Fragment>
    );
  };

  handleConfirm = () => {
    const { onConfirm } = this.props;

    if (onConfirm) onConfirm();
    this.handleCloseModal();
  };

  handleCloseModal = () => {
    const { onExited } = this.props;

    this.setState({ show: false });

    if (onExited) onExited();
  };
}

export default GenericConfirmationModal;
