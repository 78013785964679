import { CONTRACTOR_TYPES } from '@enum';

export default class Contractor {
  public address = '';
  public agencyId: number;
  public contactName = '';
  public description = '';
  public email = '';
  public id: number;
  public name = '';
  public phoneNumber = '';
  public missions: string[] = [];

  constructor(data: Partial<Contractor>) {
    Object.assign(this, data);
  }

  isCleaningContractor() {
    return this.missions.includes(CONTRACTOR_TYPES.CLEANING);
  }

  isPhotoContractor() {
    return this.missions.includes(CONTRACTOR_TYPES.PHOTO);
  }
}
