import React, { ChangeEventHandler } from 'react';

import './GenericToggle.scss';

type Props = {
  enabled: boolean;
  handleChange: ChangeEventHandler<HTMLInputElement>;
  id: string;
  label: string;
};

const GenericToggle = (props: Props) => {
  const { enabled, handleChange, id, label } = props;

  return (
    <div className="GenericToggle">
      <input
        className="GenericToggle__input"
        id={id}
        type="checkbox"
        onChange={handleChange}
        checked={enabled}
      />
      <label
        className={`GenericToggle__label ${
          enabled ? 'GenericToggle__label--enabled' : ''
        }`}
        htmlFor={id}
      >
        <span className="GenericToggle__label__button" />
      </label>

      <label className="ml-1">{label}</label>
    </div>
  );
};

export default GenericToggle;
