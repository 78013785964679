import User from './User';

export default class Employee {
  public id: number;
  public agencyId: number;
  public calendlyUrl: string;
  public userId: number;
  public user: User;

  constructor(data: Partial<User> | any = {}) {
    Object.assign(this, data);

    this.user = data.user && new User(data.user);
  }

  getFullName() {
    return this.user.getFullName();
  }

  getShortName() {
    return this.user.getShortName();
  }
}
