import moment from 'moment-timezone';
import { Document } from 'src/types/global';

import ENUM, { ACCESS_TYPE, LEAVING_STATUS } from '@enum';

import { KeywordType } from '@scopes/Common/ApartmentKeywords/ApartmentKeywords.d';

import Access from './Access';
import Activity from './Activity';
import Agency from './Agency';
import ApartmentKey from './ApartmentKey';
import Booking from './Booking';
import CleaningContract from './CleaningContract';
import { ApartmentKeyComment } from './Comment';
import Contact from './Contact';
import Employee from './Employee';
import Leaving from './Leaving';
import Neighborhood from './Neighborhood';
import PhotoContract from './PhotoContract';
import { Prerequisite } from './Prerequisite';
import Prospect from './Prospect';
import RentalAgency from './RentalAgency';
import Room from './Room';
import TranslatedText from './TranslatedText';

export default class Apartment {
  public id: number;
  public createdAt: moment.Moment;

  public accesses: Access[] = [];

  public accessDescription: TranslatedText;
  public contactDescription: TranslatedText;
  public description: TranslatedText;
  public neighborhoodDescription: TranslatedText;
  public title: TranslatedText;
  public summary: TranslatedText;
  public summaryLong: TranslatedText;
  public transport: TranslatedText;

  public agency: Agency;
  public agencyId: number;

  public airbnbPlatformId = '';
  public expediaPlatformId = '';
  public bookingPlatformId = '';

  public address = '';
  public city = '';
  public country = '';
  public postalCode = '';

  public area: number;
  public capacity: number;
  public category = '';
  public code = '';
  public door = '';
  public floor: number;
  public housingType = '';
  public notes = '';
  public ownership: string;
  public residenceType = '';
  public registrationNumber = '';
  public ring = '';
  public risk: number;
  public status = '';
  public wasteInstructions = '';
  public waterSupplyLocalization = '';
  public wifiInstructions = '';
  public wifi = '';
  public wifiPassword = '';

  public rooms: Room[] = [];

  public commentCount: number;
  public highlightedActivity: Activity;
  public highlightedActivityId: number;

  public bookingsyncId: number;
  public bookingsyncIsArchived: boolean;

  public cleaningContractorId: number;

  public cleaningDirective = '';

  public cleaningFees: number;
  public laundryFees: number;

  public electricitySupplyLocalization = '';

  public prospect: Prospect;

  public contact: Contact;
  public mainContact = '';

  public documents: Document[];

  public equipments: string[] = [];

  public isPublished = false;

  public keys: ApartmentKey[] = [];

  public keywords: KeywordType[] = [];

  public latitude = '';
  public longitude = '';

  public neighborhood: Neighborhood;
  public neighborhoodId: number;

  public leavings: Leaving[] = [];

  public pictures: Document[] = [];
  public professionalPictures: Document[] = [];
  public proofDocument: Document;
  public proofDocumentId: number;
  public usageDocument: Document;
  public usageDocumentId: number;
  public receipts: Document[] = [];

  public publishActions: string[] = [];

  public rentalAgency: RentalAgency;

  public sale?: Employee;
  public saleId?: number;

  public customerExperienceId?: number;
  public customerExperience?: Employee;

  /* the following properties are only there in priority request and are use in cleaningContractList */
  public doubleBeds?: number;
  public simpleBeds?: number;

  public photoContracts?: PhotoContract[];
  // WARNING: POTENTIAL LOOP
  public bookings: Booking[];
  public cleaningContract?: CleaningContract;

  public apartmentKeyComments: ApartmentKeyComment[] = [];

  public doorOpeningInstructions?: TranslatedText;
  public condominiumEmail: string;
  public condominiumPhone?: string;
  public collectiveHeating?: boolean;
  public privateHeating?: boolean;

  public prerequisites: Prerequisite[];

  constructor(data: Partial<Apartment>) {
    Object.assign(this, data);

    this.cleaningContract = data.cleaningContract
      ? new CleaningContract(data.cleaningContract)
      : null;

    this.bookings = data.bookings?.map((booking) => new Booking(booking)) || [];

    this.accesses = data.accesses
      ? data.accesses.map((access) => new Access(access))
      : [];
    this.accessDescription = data.accessDescription
      ? new TranslatedText(data.accessDescription)
      : null;
    this.agency = data.agency ? new Agency(data.agency) : null;
    this.contact = data.contact ? new Contact(data.contact) : null;
    this.prospect = data.prospect ? new Prospect(data.prospect) : null;
    this.contactDescription = data.contactDescription
      ? new TranslatedText(data.contactDescription)
      : null;
    this.createdAt = data.createdAt ? moment(data.createdAt) : null;
    this.description = data.description
      ? new TranslatedText(data.description)
      : null;
    this.highlightedActivity =
      data && data.highlightedActivity
        ? new Activity(data.highlightedActivity)
        : null;
    this.keys = data?.keys?.map((key) => new ApartmentKey(key)) || [];
    this.keywords = data?.keywords || [];
    this.leavings =
      data?.leavings?.map((leaving) => new Leaving(leaving)) || [];
    this.neighborhood = data.neighborhood
      ? new Neighborhood(data.neighborhood)
      : null;
    this.neighborhoodDescription = data.neighborhoodDescription
      ? new TranslatedText(data.neighborhoodDescription)
      : null;
    this.rentalAgency = data.rentalAgency
      ? new RentalAgency(data.rentalAgency)
      : null;
    this.rooms = data?.rooms?.map((room) => new Room(room)) || [];
    this.sale = data?.sale ? new Employee(data.sale) : null;
    this.customerExperience = data?.customerExperience
      ? new Employee(data.customerExperience)
      : null;
    this.title = data.title ? new TranslatedText(data.title) : null;
    this.summary = data.summary ? new TranslatedText(data.summary) : null;
    this.summaryLong = data.summaryLong
      ? new TranslatedText(data.summaryLong)
      : null;
    this.transport = data.transport ? new TranslatedText(data.transport) : null;

    this.equipments = data.equipments || [];
    this.publishActions = data.publishActions || [];

    this.photoContracts =
      data?.photoContracts?.map(
        (photoContract) => new PhotoContract(photoContract),
      ) || null;

    this.doorOpeningInstructions = data.doorOpeningInstructions
      ? new TranslatedText(data.doorOpeningInstructions)
      : null;
  }

  getFullAddress() {
    return `${this.address} ${this.postalCode} ${this.city}`;
  }

  isOwner() {
    return this.ownership === ENUM.OWNERSHIP.OWNER;
  }

  getPublicationPercent = (total) => {
    return Math.round((100 * this.publishActions.length) / total);
  };

  getPublicationPercentImportance = (total) => {
    const percent = this.getPublicationPercent(total);

    if (percent > 80) return ENUM.IMPORTANCE.LOW;

    if (percent > 50) return ENUM.IMPORTANCE.MEDIUM;

    return ENUM.IMPORTANCE.HIGH;
  };

  getFirstSignedLeaving() {
    return this.leavings
      .filter((leaving) => leaving.status === ENUM.LEAVING_STATUS.SIGNED)
      .sort((leaving1, leaving2) =>
        leaving1.startDate.isBefore(leaving2.startDate) ? -1 : 1,
      )[0];
  }

  getCurrentOrNextSignedLeaving() {
    return [...this.leavings]
      .filter(
        (leaving) =>
          leaving.status === ENUM.LEAVING_STATUS.SIGNED &&
          leaving.endDate.isAfter(moment()),
      )
      .sort((leaving1, leaving2) =>
        leaving1.startDate.isBefore(leaving2.startDate) ? -1 : 1,
      )[0];
  }

  getCurrentOrNextLeavings() {
    return [...this.leavings]
      .filter(
        (leaving) =>
          [LEAVING_STATUS.INIT, LEAVING_STATUS.SIGNED].includes(
            leaving.status,
          ) && leaving.endDate.isAfter(moment()),
      )
      .sort((leaving1, leaving2) =>
        leaving1.startDate.isBefore(leaving2.startDate) ? -1 : 1,
      );
  }

  getLastSignedLeaving() {
    return [...this.leavings]
      .filter(
        (leaving) =>
          leaving.status === ENUM.LEAVING_STATUS.SIGNED &&
          leaving.endDate.isBefore(moment()),
      )
      .sort((leaving1, leaving2) =>
        leaving1.startDate.isBefore(leaving2.startDate) ? 1 : -1,
      )[0];
  }

  getFirstLeaving() {
    return this.leavings.sort((leaving1, leaving2) =>
      leaving1.startDate.isBefore(leaving2.startDate) ? -1 : 1,
    )[0];
  }

  getTitleTranslation = (languageCode = ENUM.TRANSLATION_LANGUAGE.FR) => {
    return this.title ? this.title.getTranslation(languageCode) : null;
  };

  getDescriptionTranslation = (languageCode = ENUM.TRANSLATION_LANGUAGE.FR) => {
    return this.description
      ? this.description.getTranslation(languageCode)
      : null;
  };

  getAccessDescriptionTranslation = (
    languageCode = ENUM.TRANSLATION_LANGUAGE.FR,
  ) => {
    return this.accessDescription
      ? this.accessDescription.getTranslation(languageCode)
      : null;
  };

  getContactDescriptionTranslation = (
    languageCode = ENUM.TRANSLATION_LANGUAGE.FR,
  ) => {
    return this.contactDescription
      ? this.contactDescription.getTranslation(languageCode)
      : null;
  };

  getSummaryTranslation = (languageCode = ENUM.TRANSLATION_LANGUAGE.FR) => {
    return this.summary ? this.summary.getTranslation(languageCode) : null;
  };

  getSummaryLongTranslation = (languageCode = ENUM.TRANSLATION_LANGUAGE.FR) => {
    return this.summaryLong
      ? this.summaryLong.getTranslation(languageCode)
      : null;
  };

  getNeighborhoodDescriptionTranslation = (
    languageCode = ENUM.TRANSLATION_LANGUAGE.FR,
  ) => {
    return this.neighborhoodDescription
      ? this.neighborhoodDescription.getTranslation(languageCode)
      : null;
  };

  getTransportTranslation = (languageCode = ENUM.TRANSLATION_LANGUAGE.FR) => {
    return this.transport ? this.transport.getTranslation(languageCode) : null;
  };

  getFootAccessTranslation = (languageCode = ENUM.TRANSLATION_LANGUAGE.FR) => {
    return this.accesses.find(
      (access: Access) => access.type === ACCESS_TYPE.FOOT,
    )
      ? this.accesses
          .find((access: Access) => access.type === ACCESS_TYPE.FOOT)
          .translation.getTranslation(languageCode)
      : null;
  };

  get samplePictures() {
    return [this.professionalPictures || [], this.pictures || []]
      .flat()
      .slice(0, 5);
  }
}
