import React, { Component } from 'react';

import './GenericInfoRow.scss';

type Props = {
  title: string;
  value?: string | number;
  tall: any;
  unit?: string;
  multiline?: boolean;
};

class GenericInfoRow extends Component<Props> {
  static defaultProps = {
    unit: '',
    tall: false,
    multiline: false,
  };

  render() {
    const { title, value, tall, unit } = this.props;

    return (
      <div className={`GenericInfoRow ${tall ? 'GenericInfoRow--tall' : ''}`}>
        <div className="GenericInfoRow__title">{title}</div>
        <div className="GenericInfoRow__value">
          {this.renderLineBreak(value) || '-'}
          {value ? unit : ''}
        </div>
      </div>
    );
  }

  renderLineBreak = (value?: string | number) => {
    const { multiline } = this.props;

    if (!value) return null;

    if (typeof value !== 'string' || !multiline) return value;

    const lines = value.split('\n');

    return lines.reduce((acc, line, index) => {
      const key = `${line.slice(0, 10)}-${index}`;
      const elem = <span key={`group-${key}`}>{line.trim()}</span>;
      const br = <br key={`linebreak-${key}`} />;

      if (index === lines.length - 1) return [...acc, elem];

      return [...acc, elem, br];
    }, []);
  };
}

export default GenericInfoRow;
