import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import CONFIG from '@config';

import { getMyToken } from '@redux/me/selectors';

export interface UseImageOptions {
  q?: number;
  w?: number;
}

const DEFAULT_IMAGE_OPTIONS = {
  q: 60,
  w: 1000,
};

export function useImage(
  id: number,
  options: UseImageOptions = DEFAULT_IMAGE_OPTIONS,
  url: string = '/documents',
) {
  if (!id) {
    return;
  }

  const controller = useRef(new AbortController());

  const token = useSelector(getMyToken);
  const [src, setSrc] = useState<string>(null);

  const getPicture = useCallback(async () => {
    const query =
      '?' +
      Object.entries(options)
        .map(([key, value]) => key + '=' + value)
        .join('&');

    try {
      const picture = await fetch(
        `${CONFIG.API_V2_URL}${url}/${id}/image${query}`,
        {
          signal: controller.current.signal,
          headers: new Headers({ Authorization: token }),
        },
      );

      const file = await picture.blob();
      setSrc(URL.createObjectURL(file));
    } catch (error) {
      if (error.name !== 'AbortError') {
        setSrc('');
      }
    }
  }, [options]);

  useEffect(() => {
    getPicture();

    return () => {
      controller.current.abort?.();
    };
  }, [options]);

  return src;
}
