import * as React from 'react';
import { FormEvent } from 'react';

import './GenericCustomSelect.scss';

type Value = string | number | null;

type Props = {
  value: Value;
  label: JSX.Element | JSX.Element[];
  onChange: Function;
  options: Array<OptionItem>;
  className?: string;
  controlId?: string;
};

type State = {
  focus: boolean;
  hover: Value;
};

interface OptionItem {
  id: number;
  component: React.ReactElement<any>;
}

class GenericCustomSelect extends React.PureComponent<Props, State> {
  static defaultProps = {
    className: undefined,
    controlId: undefined,
    label: '',
  };

  state = {
    focus: false,
    hover: null,
  };

  render() {
    const { focus, hover } = this.state;
    const { value, options, controlId, className, label } = this.props;

    const active = options.find((option) => option.id === value);

    return (
      <>
        {label && <div className="GenericCustomSelect__label">{label}</div>}
        <div className={`GenericCustomSelect form-group ${className || ''}`}>
          <div className="rdt">
            <select className="form-control" />
            <div
              className={`GenericCustomSelect__options ${
                !focus ? 'GenericCustomSelect__options--hidden' : ''
              }`}
            >
              {options.map((option) => (
                <div
                  key={option.id}
                  onMouseEnter={this.handleHover(option.id)}
                  onClick={this.handleSelect(option.id)}
                  onKeyPress={this.handleSelect(option.id)}
                  className={`GenericCustomSelect__options__option ${
                    (active && !hover && active.id === option.id) ||
                    hover === option.id
                      ? 'GenericCustomSelect__options__option--active'
                      : ''
                  }`}
                >
                  {React.cloneElement(option.component)}
                </div>
              ))}
            </div>
            <div className="GenericCustomSelect__value">
              {active && (
                <div className="GenericCustomSelect__value__option">
                  {active.component}
                </div>
              )}
            </div>
          </div>
          <select
            onKeyPress={active ? this.handleSelect(active.id) : this.stopEvent}
            onMouseDown={this.handleToggleFocus}
            id={controlId}
            className={`GenericCustomSelect__select ${
              focus ? 'GenericCustomSelect__select--active' : ''
            }`}
          />
        </div>
        {focus && (
          <div onMouseDown={this.handleToggleBlur} className="backdrop" />
        )}
      </>
    );
  }

  handleHover = (value: Value) => (event: FormEvent) => {
    this.stopEvent(event);
    this.setState({ hover: value });
  };

  handleSelect = (value: Value) => (event: FormEvent) => {
    const { onChange } = this.props;

    this.handleToggleBlur(event);
    onChange(value);
  };

  handleToggleBlur = (event: FormEvent) => {
    this.stopEvent(event);
    this.setState({ focus: false });
  };

  handleToggleFocus = (event: FormEvent) => {
    const { focus } = this.state;

    this.stopEvent(event);
    this.setState({ focus: !focus });
  };

  stopEvent = (event: FormEvent) => {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
  };
}

export default GenericCustomSelect;
