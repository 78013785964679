import * as React from 'react';
import { withTranslation } from 'react-i18next';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../GenericTable.scss';
import {
  Data,
  DataMapper,
  Field,
  Fields,
  FieldOrder,
} from '../GenericTableTypes.d';

type Props<T> = {
  order: FieldOrder;
  fields: Fields;
  hover?: boolean;
  onRowClick?: Function;
  containerClassName?: string;
  rowClassName?: string | ((element?: any) => string);
  rowContainerClassName?: string;
  loading?: boolean;
  renderLoading?: () => JSX.Element | JSX.Element[];
  rowCellClassName?: string;
  data: Array<Data>;
  renderSeparator?: (
    element: Data,
    index: number,
    data: Array<Data>,
  ) => JSX.Element | JSX.Element[];
  dataMapper: DataMapper;
  renderNoResults: () => JSX.Element | JSX.Element[];
  t: Function;
  renderKey: (element: T) => string;
};

type State = {};
export class GenericTableBody extends React.PureComponent<
  Props<Object>,
  State
> {
  static defaultProps = {
    loading: false,
    hover: true,
    onRowClick: () => {},
    renderLoading: undefined,
    renderSeparator: undefined,
    renderKey: (element: any) => element.id,
    containerClassName: 'table__body generic-table__body',
    rowContainerClassName: 'table__row-container generic-table__row-container',
    rowClassName: 'table__row generic-table__row',
    rowCellClassName: 'table__row__cell generic-table__row__cell',
  };

  render() {
    const { containerClassName } = this.props;

    return <div className={containerClassName}>{this.renderBody()}</div>;
  }

  renderBody = () => {
    const {
      data,
      dataMapper,
      fields,
      loading,
      renderNoResults,
      rowContainerClassName,
      rowCellClassName,
      t,
    } = this.props;

    if (loading && data && Array.isArray(data) && !data.length)
      this.renderLoading();

    if (!data || !Array.isArray(data) || !data.length) {
      if (renderNoResults && typeof renderNoResults === 'function')
        return renderNoResults();

      if (loading) return this.renderLoading();

      return (
        <div className={rowContainerClassName}>
          <div className={this.renderRowClassName()}>
            <div
              className={['justify-content-center', rowCellClassName].join(' ')}
            >
              {t('common.noResults')}
            </div>
          </div>
        </div>
      );
    }

    if (!dataMapper || !fields) return null;

    return (
      <React.Fragment>
        {data.map<Data | any>(this.renderData)}
        {loading && this.renderLoading()}
      </React.Fragment>
    );
  };

  renderData = (element: Data, index: number, data: Array<Data>) => {
    const {
      rowContainerClassName,
      renderSeparator,
      dataMapper,
      fields,
      renderKey,
    } = this.props;

    if (!dataMapper || !fields) return null;

    const separator = renderSeparator
      ? renderSeparator(element, index, data)
      : null;

    return (
      <React.Fragment key={renderKey(element)}>
        {separator}
        <div
          onClick={this.handleRowClick(element)}
          className={rowContainerClassName}
        >
          <div className={this.renderRowClassName(element)}>
            {Object.keys(dataMapper)
              .sort(this.orderSort)
              .map(this.renderCell(element))}
          </div>
        </div>
      </React.Fragment>
    );
  };

  renderLoading = () => {
    const { renderLoading } = this.props;

    if (renderLoading) return renderLoading();

    return (
      <div className="generic-table__row-container generic-table__row-container--loading loader">
        <div
          className={['generic-table__row', 'generic-table__row--loading'].join(
            ' ',
          )}
        >
          <FontAwesomeIcon className="fa-pulse" icon={faSpinner} />
        </div>
      </div>
    );
  };

  renderRowClassName = (element?: Data) => {
    const { rowClassName, hover } = this.props;

    let className = '';

    if (hover) className = 'generic-table__hoverable';

    if (typeof rowClassName === 'function')
      return [rowClassName(element), className].join(' ');

    return [rowClassName, className].join(' ');
  };

  renderCell = (element: Data) => (key: string) => {
    const { fields } = this.props;

    if (!fields) return null;

    const classNames = [this.getRowCellClassName(fields[key], element)];

    if (/action/u.test(key))
      classNames.push(
        'table__row__cell--actions generic-table__row__cell--actions',
      );

    return (
      <div key={`${element.id}-${key}`} className={classNames.join(' ')}>
        {this.renderValue(element, key)}
      </div>
    );
  };

  renderValue = (element: Data, key: string): JSX.Element | JSX.Element[] => {
    const { dataMapper, data } = this.props;

    if (!dataMapper || !(key in dataMapper)) return null;

    const property = dataMapper[key];

    if (typeof property === 'function')
      return dataMapper[key](element, data) as any;

    return dataMapper[key] as any;
  };

  handleRowClick = (element: Data) => () => {
    const { onRowClick } = this.props;

    if (onRowClick && typeof onRowClick === 'function') onRowClick(element);
  };

  getRowCellClassName = (field: Field, element: Data) => {
    const { rowCellClassName } = this.props;
    const classNames = [rowCellClassName];

    if (field.className) {
      if (typeof field.className === 'function')
        classNames.push((field.className as any)(element, field));
      else classNames.push(field.className);
    }

    if (field.size)
      classNames.push(
        `table__row__cell--${field.size} generic-table__row__cell--${field.size}`,
      );

    if (field.modifier) {
      if (Array.isArray(field.modifier)) {
        field.modifier.forEach((modifier) => {
          classNames.push(
            `table__row__cell--${modifier} generic-table__row__cell--${modifier}`,
          );
        });
      } else
        classNames.push(
          `table__row__cell--${field.modifier} generic-table__row__cell--${field.modifier}`,
        );
    }

    return classNames.join(' ');
  };

  orderSort = (a: string, b: string) => {
    const { order } = this.props;

    if (!order) return 0;

    return order.indexOf(a) > order.indexOf(b) ? 1 : -1;
  };
}

export default withTranslation()(GenericTableBody);
