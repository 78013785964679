export default class Room {
  public apartmentId: number;
  public id: number;
  public items: Array<any>;
  public type: string;

  constructor(data: any) {
    this.apartmentId = data.apartmentId;
    this.id = data.id;
    this.items = data.items || [];
    this.type = data.type;
  }
}
