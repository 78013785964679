import React, { PureComponent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CustomMenu from './CustomMenu';

import './GenericAutoSuggest.scss';

type Props = {
  label?: string;
  className?: string;
  t?: Function;
  options: Array<any & { id: number }>;
  onSelect: (elem?: any) => any;
  renderItem: (elem: any) => any;
  textFilter: (elem: any) => void;
};

type State = {
  selected: any;
};

export class GenericAutoSuggest extends PureComponent<Props, State> {
  static defaultProps = {
    onSelect: () => {},
    renderItem: () => {},
    textFilter: () => {},
    options: [],
    label: '',
    className: '',
  };

  state = {
    selected: null,
  };

  render() {
    const { t, textFilter, className } = this.props;
    const { selected } = this.state;

    return (
      <Dropdown className={[className, 'GenericAutoSuggest'].join(' ')}>
        <Dropdown.Toggle className="button">
          {selected ? textFilter(selected) : this.getLabel()}
        </Dropdown.Toggle>
        {selected && (
          <div className="reset-button" onClick={this.handleResetSelect}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}

        <Dropdown.Menu
          className="container-menu"
          onSelect={this.handleSelect}
          t={t}
          as={CustomMenu}
        >
          {this.getOptions()}
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  handleSelect = (elem: any) => {
    const { onSelect } = this.props;

    this.setState({ selected: elem });
    onSelect(elem);
  };

  handleResetSelect = () => {
    const { onSelect } = this.props;

    this.setState({ selected: null });
    onSelect(null);
  };

  getLabel = () => {
    const { label, t } = this.props;
    const nbOptions = this.getOptions().length;

    return t('common.labelWithResult', { label, count: nbOptions });
  };

  getOptions = () => {
    const { options, renderItem, textFilter } = this.props;

    return options.map((e) => (
      <Dropdown.Item key={e.id} elem={e} filter={textFilter(e)}>
        {renderItem(e)}
      </Dropdown.Item>
    ));
  };
}

export default withTranslation()(GenericAutoSuggest);
