import moment from 'moment-timezone';

export default class Reminder {
  public id: number;
  public createdAt: moment.Moment;
  public updatedAt: moment.Moment;
  public type: string;
  public from: string;
  public to: string;
  public contentMail: string;

  constructor(
    data: Partial<Reminder | { createdAt?: string; updatedAt?: string }>,
  ) {
    Object.assign(this, data);
    this.createdAt = moment(data.createdAt);
    this.updatedAt = moment(data.updatedAt);
  }
}
