import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Box } from 'src/types/global';

import { GenericPopover } from '@components';

import './GenericHeaderBox.scss';

type Props = {
  box: Box;
};

class GenericHeaderBox extends Component<Props> {
  render() {
    const { box } = this.props;

    const classnames = [
      'GenericHeaderBox__box',
      box.icon ? 'GenericHeaderBox__box--icon' : '',
    ].join(' ');

    return (
      <div className={classnames}>
        {this.renderIcon()}
        <p className="GenericHeaderBox__box__title">{box.title}</p>
        {this.renderContent()}
      </div>
    );
  }

  renderIcon = () => {
    const { box } = this.props;

    if (!box.icon) return null;

    return <span className="GenericHeaderBox__box__icon">{box.icon}</span>;
  };

  renderContent = () => {
    const { box } = this.props;

    if (box.tooltip) return this.renderTooltip();

    return this.renderLink();
  };

  renderLink = () => {
    const { box } = this.props;

    if (!box.link) return this.renderValue();

    const externalPatternUrl = new RegExp('^[http|mailto:|tel:]', 'u');
    const isExternalUrl = box.link.match(externalPatternUrl);

    return (
      <React.Fragment>
        {isExternalUrl ? (
          <a href={box.link} target="_blank" rel="noreferrer">
            {this.renderValue()}
          </a>
        ) : (
          <Link to={box.link}>{this.renderValue()}</Link>
        )}
      </React.Fragment>
    );
  };

  renderTooltip = () => {
    const { box } = this.props;

    return (
      <GenericPopover
        placement="bottom"
        popover={
          <div>
            {box.tooltip && typeof box.tooltip === 'string'
              ? box.tooltip.split('\n').map((tip, i) => {
                  return (
                    <React.Fragment key={tip.slice(0, 50) + String(i)}>
                      {tip}
                      <br />
                    </React.Fragment>
                  );
                })
              : box.tooltip}
          </div>
        }
      >
        {this.renderLink()}
      </GenericPopover>
    );
  };

  renderValue = () => {
    const { box } = this.props;

    return <p className="GenericHeaderBox__box__content">{box.value}</p>;
  };
}

export default GenericHeaderBox;
