import React, { ChangeEvent, Component } from 'react';
import { Form } from 'react-bootstrap';

import { GenericFormGroup } from '@components';

type Props = {
  value?: string | number;
  onChange?: Function;
  label?: string;
  inputType?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  autocomplete?: boolean;
  autoFocus?: boolean;
  controlId?: string;
  disabled?: boolean;
  step?: string;
  min?: string | number;
  max?: string | number;
  maxlength?: string | number;
  prepend?: string;
  append?: string;
  error?: string;
};

class GenericInput extends Component<Props> {
  static defaultProps = {
    autocomplete: true,
    autoFocus: false,
    controlId: null,
    disabled: false,
    step: undefined,
    min: undefined,
    max: undefined,
    maxlength: undefined,
    className: undefined,
    prepend: '',
    append: '',
    error: undefined,
  };

  render() {
    const {
      value,
      className,
      maxlength,
      label,
      required,
      controlId,
      prepend,
      append,
      error,
    } = this.props;

    const length = value ? value.toString().length : 0;

    const max = parseInt(maxlength as string);

    let color = 'ok';

    if (max - length <= 10) color = 'warning';

    if (length >= max) color = 'error';

    return (
      <GenericFormGroup
        className={className}
        label={label}
        required={required}
        controlId={controlId}
        prepend={prepend}
        append={append}
      >
        {this.renderInput()}
        {maxlength && (
          <div className="text-right">
            <span className={`form__size form__size--${color}`}>
              {`${length}/${max}`}
            </span>
          </div>
        )}
        {error && (
          <div className="text-left">
            <span className={`form__size form__size--${color}`}>{error}</span>
          </div>
        )}
      </GenericFormGroup>
    );
  }

  renderInput = () => {
    const {
      value,
      label,
      placeholder,
      inputType,
      step,
      min,
      max,
      maxlength,
      autocomplete,
      autoFocus,
      required,
      disabled,
    } = this.props;

    return (
      <Form.Control
        placeholder={placeholder || label}
        type={inputType || 'text'}
        value={[undefined, null].includes(value) ? '' : value}
        required={required}
        autoFocus={autoFocus}
        onChange={this.handleChange}
        autoComplete={autocomplete ? 'on' : 'off'}
        step={step}
        min={parseInt(min as string) || undefined}
        max={parseInt(max as string) || undefined}
        maxLength={parseInt(maxlength as string) || undefined}
        disabled={disabled}
      />
    );
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const {
      target: { value },
    } = event;

    onChange(value || null);
  };
}

export default GenericInput;
