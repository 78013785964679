import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import moment from 'moment-timezone';

import { GenericMonthPicker } from '@components';

import NavButton from './NavButton';

type Props = {
  date: moment.Moment;
  placeholder: string;
  controlId?: string;
  onChange: (key: any) => (value: any) => any;
};

export class GenericMonthNavPicker extends Component<Props> {
  render() {
    const { date, placeholder, controlId, onChange } = this.props;

    return (
      <div className="GenericMonthNavPicker d-flex justify-content-center w-100">
        <div className="mb-4 mt-auto mr-3 d-flex justify-content-center">
          <NavButton
            direction="left"
            onClick={this.handleChange(moment(date).subtract(1, 'month'))}
          />
        </div>
        <div className="w-100">
          <GenericMonthPicker
            value={date}
            onChange={onChange}
            placeholder={placeholder}
            controlId={controlId}
          />
        </div>
        <div className="mb-4 mt-auto ml-3 d-flex justify-content-center">
          <NavButton
            direction="right"
            onClick={this.handleChange(moment(date).add(1, 'month'))}
          />
        </div>
      </div>
    );
  }

  handleChange = (value: moment.Moment) => () => {
    const { onChange } = this.props;

    onChange(value);
  };
}

export default withTranslation()(GenericMonthNavPicker);
