import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import Loader from '../Loader/Loader';

export function SuspenseRouteFallback({
  children,
}: {
  children: React.ReactElement<any>;
}) {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const text = Math.random() > 0.9 ? 'Patopesto ❤️' : t('common.loading');

  return (
    <React.Suspense fallback={<Loader text={text} />}>
      {React.cloneElement(children, {
        match,
        location,
        history,
      })}
    </React.Suspense>
  );
}

export default SuspenseRouteFallback;
