import ENUM from '@enum';

import AccessKeyInfo from './AccessKeyInfo';
import Tag from './Tag';
import User from './User';
import { KeyDenomiationType, KeySitesType } from './types';

export default class ApartmentKey {
  public id: number;
  public apartmentId: number;
  public denomination: KeyDenomiationType;
  public type: string;
  public locationId?: number;
  public locationName?: string;
  public locationAddress?: string;
  public locationTimeSchedule: any[] = [];
  public keyId?: string;
  public keyName?: string;
  public badgeCode?: string;
  public dropOffCode?: string;
  public collectionCode?: string;
  public status?: string;
  public accessKeyInfos: AccessKeyInfo[] = [];
  public updatedBy: User;
  public tags: Tag[] = [];
  public keySite: KeySitesType;
  public additionalStoreTimeInfo: string;
  public temporaryStoreTimeInfo: string;

  constructor(data: Partial<ApartmentKey>) {
    Object.assign(this, data);

    this.accessKeyInfos =
      data?.accessKeyInfos?.map(
        (accessKeyInfo) => new AccessKeyInfo(accessKeyInfo),
      ) || [];
    this.updatedBy = data.updatedBy ? new User(data.updatedBy) : null;
    this.tags = data?.tags?.map((tag) => new Tag(tag)) || [];
  }

  isKeycafeKeynest() {
    return [
      ENUM.APARTMENT_KEY_TYPE.KEYCAFE,
      ENUM.APARTMENT_KEY_TYPE.KEYNEST,
    ].includes(this.type);
  }

  isStored() {
    return this.status === ENUM.APARTMENT_KEY_STATUS.STORED;
  }
}
