import { handle } from 'redux-pack';

import HostRentSepas from '@redux/models/HostRentSepas';

import { FETCH_HOST_RENT_SEPAS } from '@redux/hostRentSepas/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

const initialState = new HostRentSepas({});

export default function hostRentSepasReducer(
  state = initialState,
  action: any,
) {
  const { type } = action;

  switch (type) {
    case FETCH_HOST_RENT_SEPAS:
      return handle(state, action, {
        success: () => ({
          ...state,
          hostRentSepas: action.payload,
        }),
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
