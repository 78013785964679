import { handle } from 'redux-pack';

import { LOGOUT, RESET } from '@redux/me/actions';

import { FETCH_CITIES } from './actions';

const initialState = { cities: [] };
export default function citiesReducer(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case FETCH_CITIES:
      return handle(state, action, {
        success: () => ({
          ...state,
          cities: action.meta.more
            ? state.cities.concat(action.payload)
            : action.payload,
        }),
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
