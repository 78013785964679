import CONFIG from '@config';

import * as schema from '@redux/schema';

export const FETCH_CONTRACTOR = 'FETCH_CONTRACTOR';
export const fetchContractor = (id: number, hideLoading = true) => ({
  type: FETCH_CONTRACTOR,
  route: `/contractors/${id}`,
  method: 'GET',
  hideLoading,
  schema: schema.contractor,
  url: CONFIG.API_V2_URL,
});

export const FETCH_CONTRACTORS = 'FETCH_CONTRACTORS';
export const fetchContractors = (hideLoading = false) => ({
  type: FETCH_CONTRACTORS,
  route: '/contractors',
  method: 'GET',
  hideLoading,
  schema: [schema.contractor],
  url: CONFIG.API_V2_URL,
});

export const PATCH_CONTRACTOR = 'PATCH_CONTRACTOR';
export const patchContractor = (
  id: number,
  contractorModifications: Object,
) => ({
  type: PATCH_CONTRACTOR,
  route: `/contractors/${id}`,
  method: 'PATCH',
  body: contractorModifications,
  schema: schema.contractor,
  url: CONFIG.API_V2_URL,
});

export const POST_CONTRACTOR = 'POST_CONTRACTOR';
export const postContractor = (contractorModifications: Object) => ({
  type: POST_CONTRACTOR,
  route: `/contractors`,
  method: 'POST',
  body: contractorModifications,
  schema: schema.contractor,
  url: CONFIG.API_V2_URL,
});

export const DELETE_CONTRACTOR = 'DELETE_CONTRACTOR';
export const deleteContractor = (contractorId: number) => ({
  type: DELETE_CONTRACTOR,
  route: `/contractors/${contractorId}`,
  method: 'DELETE',
  meta: {
    contractorId,
  },
  url: CONFIG.API_V2_URL,
});
