export default class PublishAction {
  public code: string;
  public category: string;
  public position: number;
  public static = false;

  constructor(data: Partial<PublishAction>) {
    this.code = data.code;
    this.category = data.category;
    this.position = data.position;
    this.static = data.static || false;
  }
}
