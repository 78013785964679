import Activity from './Activity';
import Apartment from './Apartment';
import Booking from './Booking';
import CleaningContract from './CleaningContract';
import Leaving from './Leaving';

export default class Apartments {
  public apartmentActivity: Activity[];
  public apartmentDetails: Apartment;
  public apartmentPopover: Apartment;
  public apartmentCheckinDetails: Apartment;
  public focusedLeavingCheckinId: number;
  public apartmentCheckoutDetails: Apartment;
  public focusedLeavingCheckoutId: number;
  public apartments: Apartment[];
  public apartmentsListTextFilter: string;
  public apartmentsListCapacityFilter: string;
  public apartmentsListAreaMinFilter: string;
  public apartmentsListAreaMaxFilter: string;
  public apartmentsListCategoryFilter: string;
  public apartmentsMapTextFilter: string;
  public leavingsToCheckin: Leaving[];
  public leavingsToCheckinTextFilter: string;
  public leavingsToCheckout: Leaving[];
  public leavingsToCheckoutTextFilter: string;
  public leavingsInventories: Leaving[];
  public waitingLeavingsInventories: Leaving[];
  public leavingsInventoriesTextFilter: string;
  public apartmentsPendingLeavings: Apartment[];
  public apartmentsBookings: Booking[];
  public apartmentsCleanings: CleaningContract[];
  public apartmentsMap: Apartment[];
  public leavingsToPublish: Leaving[];
  public leavingsWithKeys: Leaving[];

  constructor(data: any) {
    this.apartmentActivity = data.apartmentActivity || [];
    this.apartmentDetails = data.apartmentDetails || null;
    this.apartmentPopover = data.apartmentPopover || null;
    this.apartmentCheckinDetails = data.apartmentCheckinDetails || null;
    this.focusedLeavingCheckinId = data.apartmentCheckoutDetails || null;
    this.apartmentCheckoutDetails = data.apartmentCheckoutDetails || null;
    this.focusedLeavingCheckoutId = data.focusedLeavingCheckoutId || null;
    this.apartments = data.apartments || [];
    this.apartmentsListTextFilter = data.apartmentsListTextFilter || '';
    this.apartmentsListCapacityFilter = data.apartmentsListCapacityFilter || '';
    this.apartmentsListAreaMinFilter = data.apartmentsListAreaMinFilter || '';
    this.apartmentsListAreaMaxFilter = data.apartmentsListCategoryFilter || '';
    this.apartmentsListCategoryFilter = data.apartmentsListCategoryFilter || '';
    this.apartmentsMapTextFilter = data.apartmentsMapTextFilter || '';
    this.leavingsToCheckin = data.leavingsToCheckin || [];
    this.leavingsToCheckinTextFilter = data.leavingsToCheckinTextFilter || '';
    this.leavingsToCheckout = data.leavingsToCheckout || [];
    this.leavingsToCheckoutTextFilter = data.leavingsToCheckoutTextFilter || '';
    this.leavingsInventories = data.leavingsInventories || [];
    this.waitingLeavingsInventories = data.waitingLeavingsInventories || [];
    this.leavingsInventoriesTextFilter =
      data.leavingsInventoriesTextFilter || '';
    this.apartmentsPendingLeavings = data.apartmentsPendingLeavings || [];
    this.apartmentsBookings = data.apartmentsBookings || [];
    this.apartmentsCleanings = data.apartmentsCleanings || [];
    this.apartmentsMap = data.apartmentsMap || [];
    this.leavingsToPublish = data.leavingsToPublish || [];
    this.leavingsWithKeys = data.leavingsWithKeys || [];
  }
}
