import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import { GenericModal, GenericDropzone } from '@components';

import './GenericUploadModal.scss';

type Props = {
  isOpen: boolean;
  title: string;
  onCloseModal: Function;
  handleSubmit: Function;
  renderForm?: Function;
  t: Function;
};

type State = {
  acceptedFiles: Array<File>;
};

export class GenericUploadModal extends Component<Props, State> {
  static defaultProps = {
    renderForm: null,
  };

  state = {
    acceptedFiles: [],
  };

  render() {
    const { title, isOpen, t, renderForm } = this.props;

    return (
      <GenericModal
        isOpen={isOpen}
        onExited={this.handleHide}
        title={title}
        footer={this.renderModalFooter()}
      >
        <GenericDropzone
          onDrop={this.handleDrop}
          defaultText={t('common.dragHerePlural')}
          dropText={t('common.dropHerePlural')}
          unsupportedText={t('common.unsupportedFiles')}
        />
        {this.renderInfoDiv()}
        {renderForm}
      </GenericModal>
    );
  }

  renderInfoDiv = () => {
    const { t } = this.props;
    const { acceptedFiles } = this.state;

    return (
      <div className="text-pictures-info">
        {acceptedFiles.length === 0
          ? t('common.infoPictureNumber_none')
          : t('common.infoPictureNumber', { count: acceptedFiles.length })}
      </div>
    );
  };

  renderModalFooter = () => {
    const { t } = this.props;
    const { acceptedFiles } = this.state;

    return (
      <div>
        <div className="d-flex space-between">
          <Button
            id="pictureNumber"
            type="button"
            className="full-mobile"
            variant="secondary"
            onClick={this.handleHide}
          >
            {t('common.cancel', { count: acceptedFiles.length })}
          </Button>
          <Button
            id="submitBtn"
            type="submit"
            className="full-mobile"
            variant="primary"
            onClick={this.handleSubmit}
            disabled={acceptedFiles.length <= 0}
          >
            {t('common.confirm')}
          </Button>
        </div>
      </div>
    );
  };

  handleHide = () => {
    const { onCloseModal } = this.props;

    this.setState({ acceptedFiles: [] });
    onCloseModal();
  };

  handleDrop = (files: Array<File>) => {
    const { acceptedFiles } = this.state;

    this.setState({ acceptedFiles: files.concat(acceptedFiles) });
  };

  handleSubmit = () => {
    const { handleSubmit } = this.props;
    const { acceptedFiles } = this.state;

    return handleSubmit(acceptedFiles).then(this.handleHide);
  };
}

export default withRouter(withTranslation()(GenericUploadModal));
