import { Document } from 'src/types/global';

import ENUM from '@enum';

export default class CleaningObservation {
  public id: number;
  public type = '';
  public status = '';
  public roomType = '';
  public name = '';
  public skipReason = '';
  public pictures: Document[] = [];
  public comment: string;

  constructor(data: Partial<CleaningObservation>) {
    Object.assign(this, data);
  }

  hasProblems() {
    if (
      this.type === ENUM.CLEANING_OBSERVATION_TYPE.CHECKLIST &&
      this.roomType &&
      ((this.pictures && this.pictures.length > 0) || this.comment)
    ) {
      return true;
    }

    if (this.type === ENUM.CLEANING_OBSERVATION_TYPE.TODO && this.skipReason) {
      return true;
    }

    return false;
  }
}
