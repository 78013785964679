import Activity from './Activity';
import Booking from './Booking';

export default class Bookings {
  public bookingDetails: Booking;
  public bookings: Booking[];
  public bookingActivities: Activity[];
  public bookingsListTextFilter: string;
  public checkinCheckout: Booking[];
  public bookingDepositFilter: string;
  public bookingAccessStatusFilter: any;
  public bookingIdentityCheckFilter: any;
  public bookingTypeFilter: any;

  constructor(data: any) {
    this.bookingDetails = data.bookingDetails || null;
    this.bookings = data.bookings || [];
    this.bookingActivities = data.bookingActivities || [];
    this.bookingsListTextFilter = data.bookingsListTextFilter || '';
    this.checkinCheckout = data.checkinCheckout || [];
    this.bookingDepositFilter = data.bookingDepositFilter || null;
    this.bookingAccessStatusFilter = data.bookingAccessStatusFilter || null;
    this.bookingIdentityCheckFilter = data.bookingIdentityCheckFilter || null;
    this.bookingTypeFilter = data.bookingTypeFilter || null;
  }
}
