import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { State } from 'src/types/redux';

import Contractor from '@redux/models/Contractor';
import User from '@redux/models/User';

import { getEntities } from '@redux/entities/selectors';

import { contractor, user } from '@redux/schema';

export const getMeState = (state: State) => state.me;

export const getMe = createSelector(
  getEntities,
  getMeState,
  (entities, meState: any): User => {
    const playerId = meState.meId;
    const meData = denormalize(playerId, user, entities);

    return meData && new User(meData);
  },
);

export const getMyContractor = createSelector(
  getEntities,
  getMe,
  (entities, user) => {
    const contractorData = denormalize(
      user?.contractorEmployee?.contractorId,
      contractor,
      entities,
    );

    return contractorData && new Contractor(contractorData);
  },
);

export const getEmail = createSelector(getMeState, (state) => state.email);

export const getMyEmail = createSelector(getMe, (user) => user && user.email);

export const getMyToken = createSelector(
  getMeState,
  (meState) => meState.token,
);

export const getMyAgencyId = createSelector(
  getMe,
  (user) => user && user.getAgencyId(),
);

export const isAuthenticated = createSelector(getMyToken, (token) => !!token);
