import { handle } from 'redux-pack';

import HostRentRows from '@redux/models/HostRentRows';

import {
  FETCH_HOST_RENT_ROWS,
  POST_HOST_RENT_ROW,
  DELETE_HOST_RENT_ROW,
} from '@redux/hostRentRows/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

const initialState = new HostRentRows({});
export default function hostRentRowsReducer(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case POST_HOST_RENT_ROW:
      return handle(state, action, {
        success: () => ({
          ...state,
          hostRentRows: [...state.hostRentRows, action.payload],
        }),
      });
    case DELETE_HOST_RENT_ROW:
      return handle(state, action, {
        success: () => ({
          ...state,
          hostRentRows: state.hostRentRows.filter(
            (hostRentRow) => hostRentRow !== action.payload,
          ),
        }),
      });
    case FETCH_HOST_RENT_ROWS:
      return handle(state, action, {
        success: () => ({
          ...state,
          hostRentRows: action.payload,
        }),
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
