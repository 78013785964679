export default {
  ROOT: '/',

  //auth
  AUTH: '/auth',
  AUTH_LOGIN: '/auth/login',
  AUTH_FORGOT_PASSWORD: '/auth/forgot-password',
  AUTH_CHECK_MAIL: '/auth/check-mail',
  AUTH_NEW_PASSWORD: '/auth/new-password',

  //prospects
  PROSPECTS: '/prospects',
  PROSPECTS_DASHBOARD: '/prospects/dashboard',
  PROSPECTS_ANALYZE: '/prospects/analyze',
  PROSPECTS_VISIT: '/prospects/:id([0-9]+)/visit',
  PROSPECTS_DETAILS: '/prospects/:id([0-9]+)',

  //apartments
  APARTMENTS: '/apartments',
  APARTMENTS_DETAILS: '/apartments/:id([0-9]+)/:leavingId([0-9]+)?',
  APARTMENTS_DASHBOARD: '/apartments/dashboard',
  APARTMENTS_PENDING: '/apartments/pending',
  APARTMENTS_PHOTOSHOOT: '/apartments/photoshoot',
  APARTMENTS_SETUP: '/apartments/setup',
  APARTMENTS_UNSETUP: '/apartments/unsetup',
  APARTMENTS_PUBLICATION: '/apartments/publication',
  APARTMENTS_KEY_LIST: '/apartments/keyslist',
  APARTMENTS_INVENTORIES: '/apartments/inventories',

  // bookings
  BOOKINGS: '/bookings',
  BOOKINGS_DETAILS: '/bookings/:id([0-9]+)',

  // finances
  FINANCES: '/finances',
  FINANCES_RENTS: '/finances/rents',
  FINANCES_EXTRACT: '/finances/extract',

  // contracts
  CONTRACTS: '/contracts',
  CONTRACTS_USERS_LIST: '/contracts/users-list',

  // settings
  SETTINGS: '/settings',
  SETTINGS_USER: '/settings/user',
  SETTINGS_USERS: '/settings/users',
  SETTINGS_AGENCIES: '/settings/agencies',
  SETTINGS_CITIES: '/settings/cities',
  SETTINGS_NEIGHBORHOODS: '/settings/neighborhoods',

  // cleanings
  CLEANINGS: '/cleanings',
  CLEANINGS_HEATMAP: '/cleanings/heatmap',
  CLEANINGS_CONTRACTORS: '/cleanings/contractors',

  // events
  SPECIAL_EVENTS: '/special-events',
};
