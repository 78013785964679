import React, { FormEvent, PureComponent } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Tools } from '@utils/tools';

import './GenericCopyToClipboard.scss';

type Props = {
  t: Function;
  children: React.ReactChild;
};

type State = {
  show: boolean;
};

export class GenericCopyToClipboard extends PureComponent<Props, State> {
  tools: Tools;
  ref: null;
  state = {
    show: false,
  };

  constructor(props: Props) {
    super(props);

    const { t } = props;
    this.tools = new Tools(t);
  }

  render() {
    const { children } = this.props;
    const { show } = this.state;

    return (
      <div className="generic-copy-to-clipboard d-flex">
        {children}
        <div
          ref={this.setRootRef}
          className={`generic-copy-to-clipboard__container ${
            show ? 'generic-copy-to-clipboard__container--clicked' : ''
          }`}
          onClick={this.handleClick}
        >
          <FontAwesomeIcon icon={faCopy} />
        </div>
        {this.ref && (
          <Overlay target={this.ref} show={show} placement="bottom">
            {this.renderTooltip}
          </Overlay>
        )}
      </div>
    );
  }

  renderTooltip = (props: any) => {
    const { t } = this.props;

    if ('show' in props) delete props.show;

    return (
      <Tooltip
        className="generic-copy-to-clipboard__tooltip"
        id="copy"
        {...props}
      >
        {t('common.copiedToClipboard')}
      </Tooltip>
    );
  };

  handleClick = (event: FormEvent) => {
    const { show } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (!show) {
      this.setState({ show: true }, this.handleReInit);

      this.tools.copyToClipboard(this.getValueToCopy());
    }
  };

  handleReInit = () => {
    setTimeout(this.handleCloseTooltip, 800);
  };

  handleCloseTooltip = () => {
    this.setState({ show: false });
  };

  setRootRef = (element: any) => {
    this.ref = element;
  };

  getValueToCopy = () => {
    const { children } = this.props;

    if (!children) return '';

    if (typeof children === 'string') return children;

    if (typeof children === 'object') {
      if (typeof children.props.children === 'string')
        return children.props.children;
      if (typeof children.props.value === 'string') return children.props.value;
    }

    return '';
  };
}

export default withTranslation()(GenericCopyToClipboard);
