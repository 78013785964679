import Statistic from './Statistic';

export default class Statistics {
  public leavings?: { signedMonths: Statistic; managedMonths: Statistic };
  public created?: {
    deleted: Statistic;
    owner: Statistic;
    rejected: Statistic;
    result: Statistic;
    signed: { owner: Statistic; tenant: Statistic; total: any };
    tenant: Statistic;
  };
  public signed?: {
    total: Statistic;
    createdRate: Statistic;
    visitedRate: Statistic;
  };
  public renewed?: Statistic;
  public visited?: Statistic;
  public ownership?: Statistic;
  public sources?: { lloyd: Statistic; website: Statistic; others: Statistic };

  constructor(data: any) {
    this.created = {
      deleted:
        data && data.created.deleted
          ? new Statistic(data.created.deleted)
          : undefined,
      owner:
        data && data.created.owner
          ? new Statistic(data.created.owner)
          : undefined,
      rejected:
        data && data.created.rejected
          ? new Statistic(data.created.rejected)
          : undefined,
      result: data && data.created ? new Statistic(data.created) : undefined,
      signed: {
        owner:
          data && data.created.signed.owner
            ? new Statistic(data.created.signed.owner)
            : undefined,
        tenant:
          data && data.created.signed.tenant
            ? new Statistic(data.created.signed.tenant)
            : undefined,
        total: data.created.signed.total,
      },
      tenant:
        data && data.created.tenant
          ? new Statistic(data.created.tenant)
          : undefined,
    };
    this.signed = {
      total: data && data.signed ? new Statistic(data.signed) : undefined,
      createdRate:
        data && data.signed.createdRate
          ? new Statistic(data.signed.createdRate)
          : undefined,
      visitedRate:
        data && data.signed.visitedRate
          ? new Statistic(data.signed.visitedRate)
          : undefined,
    };
    this.renewed =
      data && data.renewed ? new Statistic(data.renewed) : undefined;
    this.visited =
      data && data.visited ? new Statistic(data.visited) : undefined;
    this.ownership =
      data && data.ownership ? new Statistic(data.ownership) : undefined;
    this.sources = {
      lloyd:
        data && data.sources.lloyd
          ? new Statistic(data.sources.lloyd)
          : undefined,
      website:
        data && data.sources.website
          ? new Statistic(data.sources.website)
          : undefined,
      others:
        data && data.sources.others
          ? new Statistic(data.sources.others)
          : undefined,
    };
    this.leavings = {
      signedMonths:
        data && data.leavings.signedMonths
          ? new Statistic(data.leavings.signedMonths)
          : undefined,
      managedMonths:
        data && data.leavings.managedMonths
          ? new Statistic(data.leavings.managedMonths)
          : undefined,
    };
  }
}
