import translationFR from '@locales/fr/translation';

import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';

const resources = {
  fr: {
    translation: translationFR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  react: { withRef: true },
  debug: !['production', 'test'].includes(process.env.NODE_ENV),
  interpolation: {
    escapeValue: false,
  },
} as any);

export default i18n;
