import moment from 'moment-timezone';

export default class AccessKeyInfo {
  public id: number;
  public apartmentKeyId: number;
  public accessCode: string;
  public accessStartDate: moment.Moment;
  public accessEndDate: moment.Moment;
  public pickupDate: moment.Moment;
  public depositDate: moment.Moment;
  public createdAt: moment.Moment;
  public updatedAt: moment.Moment;

  constructor(
    data: Partial<AccessKeyInfo & { pickUpDate?: moment.Moment | string }>,
  ) {
    Object.assign(this, data);

    this.accessStartDate = data.accessStartDate
      ? moment(data.accessStartDate)
      : null;
    this.accessEndDate = data.accessEndDate ? moment(data.accessEndDate) : null;
    this.pickupDate = data.pickUpDate ? moment(data.pickUpDate) : null;
    this.depositDate = data.depositDate ? moment(data.depositDate) : null;
    this.createdAt = data.createdAt ? moment(data.createdAt) : null;
    this.updatedAt = data.updatedAt ? moment(data.updatedAt) : null;
  }
}
