import moment from 'moment-timezone';
import type { Document } from 'src/types/global';

import User from './User';

export default class Contact {
  public userId: number;
  public user: User;
  public address = '';
  public bic = '';
  public iban = '';
  public birthDate: moment.Moment;
  public birthTown = '';
  public createdAt: moment.Moment;
  public updatedAt: moment.Moment;
  public id: number;
  public identityDocuments: Document[] = [];
  public ribId: number;
  public rib: Document;

  constructor(
    data: Partial<
      | Contact
      | {
          birthDate?: string;
          createdAt?: string;
          updatedAt?: string;
        }
    >,
  ) {
    Object.assign(this, data);

    const contactData = data as Contact;

    this.user = contactData.user && new User(contactData.user);
    this.birthDate = data.birthDate ? moment(data.birthDate) : undefined;
    this.createdAt = data.createdAt ? moment(data.createdAt) : null;
    this.updatedAt = data.updatedAt ? moment(data.updatedAt) : null;
  }

  getFullName = () => {
    return this.user.getFullName();
  };
}
