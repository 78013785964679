const INDEX_DB_VERSION = 1;

export const InitIndexedDB = () => {
  return new Promise<IDBDatabase>((resolve, reject) => {
    const request = indexedDB.open('NotificationsDB', INDEX_DB_VERSION);

    request.onerror = () => {
      reject('[IndexDB] Error on init');
    };

    request.onupgradeneeded = (event) => {
      const db = (event.target as IDBOpenDBRequest).result;
      db.createObjectStore('notifications', { keyPath: 'id' });
    };

    request.onsuccess = (event) => {
      resolve((event.target as IDBOpenDBRequest).result);
    };
  });
};

export const AddNotificationToIndexDB = (db, notification) => {
  return new Promise<boolean>((resolve, reject) => {
    let transaction = db.transaction(['notifications'], 'readwrite');
    let store = transaction.objectStore('notifications');
    let getRequest = store.get(notification.id);

    getRequest.onsuccess = () => {
      if (!getRequest.result) {
        store.add(notification).onsuccess = () => {
          resolve(true);
        };
      } else {
        resolve(false);
      }
    };

    getRequest.onerror = () => {
      reject('[IndexDB] Error while retrieving the notification');
    };
  });
};

export const ClearIndexedDB = () => {
  indexedDB.deleteDatabase('NotificationsDB');
};
