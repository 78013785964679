import moment from 'moment-timezone';
import { Dispatch, GetState } from 'src/types/redux';

import CONFIG from '@config';

import ENUM from '@enum';

import Apartment from '@redux/models/Apartment';
import Room from '@redux/models/Room';

import {
  simpleDeleteApartmentFile,
  simpleDeleteApartmentFiles,
  simpleDeleteContactFile,
  simpleDeleteContactFiles,
  simpleDeleteLeavingFile,
  simplePatchApartment,
  simplePatchContact,
  simplePatchLeaving,
  simplePostApartmentFile,
  simplePostApartmentFiles,
  simplePostContactFile,
  simplePostContactFiles,
  simplePostLeavingFile,
  simplePostRooms,
  simpleFetchProspects,
} from '@redux/entities/actions';

import { getProspectDetailsId } from '@redux/prospects/selectors';

import * as schema from '@redux/schema';

export const FETCH_PROSPECTS = 'FETCH_PROSPECTS';
export const fetchProspects = (
  offset?: number,
  limit?: number,
  more = false,
  status: string | string[] = [],
  agencyId = '',
  saleId = '',
  text = '',
  order = '',
  isPaused = false,
  isFakeDate = false,
  rejectionMotive = '',
  statusStart: moment.Moment = null,
  statusEnd: moment.Moment = null,
  eventStart: moment.Moment = null,
  eventEnd: moment.Moment = null,
) => (dispatch: Dispatch) => {
  return dispatch({
    ...simpleFetchProspects(
      offset,
      limit,
      more,
      status as string[],
      agencyId,
      saleId,
      text,
      order,
      isPaused,
      isFakeDate,
      rejectionMotive,
      statusStart,
      statusEnd,
      eventStart,
      eventEnd,
    ),
    type: FETCH_PROSPECTS,
    schema: { result: [schema.prospect] },
    hideLoading: true,
  });
};

export const FETCH_NEW_PROSPECTS = 'FETCH_NEW_PROSPECTS';
export const fetchNewProspects = (agencyId = '') => (dispatch: Dispatch) => {
  return dispatch({
    ...simpleFetchProspects(
      0,
      10,
      false,
      [ENUM.PROSPECT_STATUS.NEW],
      agencyId,
      '',
      '',
      '',
    ),
    type: FETCH_NEW_PROSPECTS,
    schema: { result: [schema.prospect] },
  });
};

export const FETCH_SIGNED_PROSPECTS = 'FETCH_SIGNED_PROSPECTS';
export const fetchSignedProspects = (agencyId = '') => (dispatch: Dispatch) => {
  return dispatch({
    ...simpleFetchProspects(
      0,
      10,
      false,
      [ENUM.PROSPECT_STATUS.SIGNED],
      agencyId,
      '',
      '',
      '',
    ),
    type: FETCH_SIGNED_PROSPECTS,
    schema: { result: [schema.prospect] },
  });
};

export const FETCH_PROSPECT = 'FETCH_PROSPECT';
export const fetchProspect = (id: number) => ({
  type: FETCH_PROSPECT,
  route: `/prospects/${id}`,
  method: 'GET',
  schema: schema.prospect,
  url: CONFIG.API_V2_URL,
});

export const FETCH_PROSPECT_ACTIVITY = 'FETCH_PROSPECT_ACTIVITY';
export const fetchProspectActivity = (id: number) => ({
  type: FETCH_PROSPECT_ACTIVITY,
  route: `/prospects/${id}/activities`,
  method: 'GET',
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_PROSPECTS_STATISTIC = 'FETCH_PROSPECTS_STATISTIC';
export const fetchProspectsStatistics = (
  agencyId = '',
  startDate = '',
  endDate = '',
) => ({
  type: FETCH_PROSPECTS_STATISTIC,
  route: `/prospects/statistics`,
  query: {
    agencyId,
    startDate,
    endDate,
  },
  method: 'GET',
  url: CONFIG.API_V2_URL,
});

export const PATCH_PROSPECT = 'PATCH_PROSPECT';
export const patchProspect = (
  id: number,
  prospectModifications: Object,
  hideLoading = false,
) => (dispatch: Dispatch) => {
  return Promise.resolve()
    .then(() =>
      dispatch({
        type: PATCH_PROSPECT,
        route: `/prospects/${id}`,
        method: 'PATCH',
        body: prospectModifications,
        schema: schema.prospect,
        hideLoading,
        url: CONFIG.API_V2_URL,
      }),
    )
    .tap(() => dispatch(fetchProspectActivity(id)));
};

export const POST_PROSPECT = 'POST_PROSPECT';
export const postProspect = (prospectModifications: Object) => ({
  type: POST_PROSPECT,
  route: `/prospects/`,
  method: 'POST',
  body: prospectModifications,
  schema: schema.prospect,
  url: CONFIG.API_V2_URL,
});

export const DELETE_PROSPECT = 'DELETE_PROSPECT';
export const deleteProspect = (id: number) => ({
  type: DELETE_PROSPECT,
  route: `/prospects/${id}`,
  method: 'DELETE',
  url: CONFIG.API_V2_URL,
});

export const PATCH_PROSPECT_APARTMENT = 'PATCH_PROSPECT_APARTMENT';
export const patchProspectApartment = (
  id: number,
  apartmentModifications: Partial<Record<keyof Apartment, any>>,
  hideLoading = false,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return Promise.resolve()
    .then(() =>
      dispatch({
        ...simplePatchApartment(id, apartmentModifications),
        hideLoading,
      }),
    )
    .tap(() => {
      return dispatch(fetchProspectActivity(prospectId));
    });
};

export const PATCH_PROSPECT_APARTMENT_LIST = 'PATCH_PROSPECT_APARTMENT_LIST';
export const patchProspectApartmentList = (
  id: number,
  apartmentModifications: Partial<Record<keyof Apartment, any>>,
  hideLoading = false,
) => ({
  ...simplePatchApartment(id, apartmentModifications),
  hideLoading,
});

export const PATCH_PROSPECT_CONTACT = 'PATCH_PROSPECT_CONTACT';
export const patchProspectContact = (
  id: number,
  contactModifications: Object,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simplePatchContact(id, contactModifications)).then(() =>
    dispatch(fetchProspectActivity(prospectId)),
  );
};

export const PATCH_PROSPECT_LEAVING = 'PATCH_PROSPECT_LEAVING';
export const patchProspectLeaving = (
  id: number,
  leavingModifications: Record<string, any>,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simplePatchLeaving(id, leavingModifications)).then(() =>
    dispatch(fetchProspectActivity(prospectId)),
  );
};

export const POST_PROSPECT_APARTMENT_ROOMS = 'POST_PROSPECT_APARTMENT_ROOMS';
export const postProspectApartmentRooms = (
  apartmentId: number,
  rooms: Partial<Room>[],
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simplePostRooms(apartmentId, rooms)).then(() =>
    dispatch(fetchProspectActivity(prospectId)),
  );
};

export const POST_PROSPECT_APARTMENT_FILE = 'POST_PROSPECT_APARTMENT_FILE';
export const postProspectApartmentFile = (
  id: number,
  file: Object,
  path: string,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simplePostApartmentFile(id, file, path)).then(() => {
    dispatch(fetchProspect(prospectId));
    dispatch(fetchProspectActivity(prospectId));
  });
};

export const DELETE_PROSPECT_APARTMENT_FILE = 'DELETE_PROSPECT_APARTMENT_FILE';
export const deleteProspectApartmentFile = (id: number, path: string) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simpleDeleteApartmentFile(id, path)).then(() =>
    dispatch(fetchProspectActivity(prospectId)),
  );
};

export const POST_PROSPECT_APARTMENT_FILES = 'POST_PROSPECT_APARTMENT_FILES';
export const postProspectApartmentFiles = (
  id: number,
  files: Array<Object>,
  path: string,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simplePostApartmentFiles(id, files, path)).then(() =>
    dispatch(fetchProspectActivity(prospectId)),
  );
};

export const DELETE_PROSPECT_APARTMENT_FILES =
  'DELETE_PROSPECT_APARTMENT_FILES';
export const deleteProspectApartmentFiles = (
  id: number,
  path: string,
  documentId: number,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simpleDeleteApartmentFiles(id, path, documentId)).then(() =>
    dispatch(fetchProspectActivity(prospectId)),
  );
};

export const POST_PROSPECT_CONTACT_FILE = 'POST_PROSPECT_CONTACT_FILE';
export const postProspectContactFile = (
  id: number,
  file: FormData,
  path: string,
  apartmentId: number,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simplePostContactFile(id, file, path, apartmentId)).then(() =>
    dispatch(fetchProspectActivity(prospectId)),
  );
};

export const DELETE_PROSPECT_CONTACT_FILE = 'DELETE_PROSPECT_CONTACT_FILE';
export const deleteProspectContactFile = (
  id: number,
  path: string,
  apartmentId: number,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simpleDeleteContactFile(id, path, apartmentId)).then(() =>
    dispatch(fetchProspectActivity(prospectId)),
  );
};

export const POST_PROSPECT_CONTACT_FILES = 'POST_PROSPECT_CONTACT_FILES';
export const postProspectContactFiles = (
  id: number,
  files: FormData,
  path: string,
  apartmentId: number,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simplePostContactFiles(id, files, path, apartmentId)).then(
    () => {
      dispatch(fetchProspect(prospectId));
      dispatch(fetchProspectActivity(prospectId));
    },
  );
};

export const DELETE_PROSPECT_CONTACT_FILES = 'DELETE_PROSPECT_CONTACT_FILES';
export const deleteProspectContactFiles = (
  id: number,
  path: string,
  documentId: number,
  apartmentId: number,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(
    simpleDeleteContactFiles(id, path, documentId, apartmentId),
  ).then(() => dispatch(fetchProspectActivity(prospectId)));
};

export const POST_PROSPECT_LEAVING_FILE = 'POST_PROSPECT_LEAVING_FILE';
export const postProspectLeavingFile = (
  id: number,
  file: Object,
  path: string,
) => (dispatch: Dispatch, getState: GetState) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simplePostLeavingFile(id, file, path)).then(() => {
    dispatch(fetchProspect(prospectId));
    dispatch(fetchProspectActivity(prospectId));
  });
};

export const DELETE_PROSPECT_LEAVING_FILE = 'DELETE_PROSPECT_LEAVING_FILE';
export const deleteProspectLeavingFile = (id: number, path: string) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const prospectId = getProspectDetailsId(getState());

  return dispatch(simpleDeleteLeavingFile(id, path)).then(() =>
    dispatch(fetchProspectActivity(prospectId)),
  );
};

export const POST_MANDATE = 'POST_MANDATE';
export const postMandate = (
  id: number,
  prospectId: number,
  manualMode = false,
) => (dispatch: Dispatch) => {
  return dispatch({
    type: POST_MANDATE,
    route: `/leavings/${id}/mandate`,
    method: 'POST',
    hideError: true,
    body: {
      manualMode,
    },
    url: CONFIG.API_V2_URL,
  })
    .then((result: any) => {
      return result.error ? result : dispatch(fetchProspect(prospectId));
    })
    .then((result: any) => {
      return result.error
        ? result
        : dispatch(fetchProspectActivity(prospectId));
    });
};

export const POST_PROSPECT_COMMENT = 'POST_PROSPECT_COMMENT';
export const postProspectComment = (id: number, comment: string) => (
  dispatch: Dispatch,
) => {
  return dispatch({
    type: POST_PROSPECT_COMMENT,
    route: `/prospects/${id}/comments`,
    method: 'POST',
    body: {
      comment,
    },
    url: CONFIG.API_V2_URL,
  }).then(() => dispatch(fetchProspectActivity(id)));
};

export const SET_PROSPECTS_LIST_TEXT_FILTER = 'SET_PROSPECTS_LIST_TEXT_FILTER';
export const setProspectsListTextFilter = (text: string) => ({
  type: SET_PROSPECTS_LIST_TEXT_FILTER,
  text,
});

export const POST_PROSPECT_HIGHLIGHT_ACTIVITY =
  'POST_PROSPECT_HIGHLIGHT_ACTIVITY';
export const postProspectHighlightActivity = (
  prospectId: number,
  activityId: number,
) => ({
  type: POST_PROSPECT_HIGHLIGHT_ACTIVITY,
  schema: schema.prospect,
  route: `/prospects/${prospectId}/highlighted-activities`,
  method: 'POST',
  body: {
    activityId,
  },
  url: CONFIG.API_V2_URL,
});

export const FETCH_PROSPECT_HOST_AREA_TOKEN = 'FETCH_PROSPECT_HOST_AREA_TOKEN';
export const fetchProspectHostAreaToken = (prospectId: number) => ({
  type: FETCH_PROSPECT_HOST_AREA_TOKEN,
  route: `/prospects/${prospectId}/host-area-token`,
  method: 'GET',
  url: CONFIG.API_V2_URL,
});

export const POST_PAUSE_PROSPECT = 'POST_PAUSE_PROSPECT';
export const postPauseProspect = (
  prospectId: number,
  until: moment.Moment,
) => ({
  type: POST_PAUSE_PROSPECT,
  route: `/prospects/${prospectId}/pause`,
  method: 'POST',
  body: {
    until,
  },
  schema: schema.prospect,
  url: CONFIG.API_V2_URL,
});

export const POST_UNPAUSE_PROSPECT = 'POST_UNPAUSE_PROSPECT';
export const postUnpauseProspect = (prospectId: number) => ({
  type: POST_UNPAUSE_PROSPECT,
  route: `/prospects/${prospectId}/unpause`,
  method: 'POST',
  schema: schema.prospect,
  url: CONFIG.API_V2_URL,
});

export const GET_SEMI_AUTO_MAIL = 'GET_SEMI_AUTO_MAIL';
export const getSemiAutoMail = (prospectId: number, type: string) => ({
  type: GET_SEMI_AUTO_MAIL,
  route: `/prospects/${prospectId}/mail-template`,
  method: 'GET',
  query: {
    type,
  },
  url: CONFIG.API_V2_URL,
});

export const SEND_SEMI_AUTO_MAIL = 'SEND_SEMI_AUTO_MAIL';
export const sendSemiAutoMail = (
  prospectId: number,
  content: string,
  type: string,
  subject: string,
  documents: any = [],
) => ({
  type: SEND_SEMI_AUTO_MAIL,
  route: `/prospects/${prospectId}/mail-send`,
  method: 'POST',
  body: {
    content,
    type,
    subject,
    documents,
  },
  url: CONFIG.API_V2_URL,
});

export const FETCH_PROSPECT_REMINDERS = 'FETCH_PROSPECT_REMINDERS';
export const fetchProspectReminders = (id: number) => ({
  type: FETCH_PROSPECT_REMINDERS,
  route: `/prospects/${id}/reminders`,
  method: 'GET',
  url: CONFIG.API_V2_URL,
});

export const PATCH_PROSPECT_STATUS = 'PATCH_PROSPECT_STATUS';
export const patchProspectStatus = (id: number, data: Record<string, any>) => (
  dispatch: Dispatch,
) =>
  dispatch({
    type: PATCH_PROSPECT_STATUS,
    route: `/prospects/${id}/status`,
    method: 'PATCH',
    url: CONFIG.API_V2_URL,
    body: data,
    schema: schema.prospect,
  }).then(() => dispatch(fetchProspectActivity(id)));
