import { normalize } from 'normalizr';
import { io } from 'socket.io-client';
import { Action, Store } from 'src/types/redux';

import CONFIG from '@config';

import { Notification as NotificationType } from '@redux/models/Notifications';

import { addEntities } from '@redux/entities/actions';
import { LOGOUT } from '@redux/me/actions';

import * as schema from '@redux/schema';

import {
  AddNotificationToIndexDB,
  InitIndexedDB,
} from '../../../indexDB/indexDB';

export const WEBSOCKET_CONNECT = 'WEBSOCKET_CONNECT';
export const WEBSOCKET_DISCONNECT = 'WEBSOCKET_DISCONNECT';
export const WEBSOCKET_NOTIFICATION = 'WEBSOCKET_NOTIFICATION';
export const WEBSOCKET_DELETE_NOTIFICATION = 'WEBSOCKET_DELETE_NOTIFICATION';

const LLOYD_URL = window.location.origin;

const handleNotificationPush = (db, payload: NotificationType) => {
  AddNotificationToIndexDB(db, payload).then((shouldNotify) => {
    if (shouldNotify) {
      const notificationPush = new Notification(payload.content.title, {
        body: payload.content.subtitle,
        icon: '/favicon.ico',
      });

      notificationPush.onclick = (event) => {
        event.preventDefault();

        window.open(payload.content.callbackUrl || LLOYD_URL, '_blank');
        notificationPush.close();
      };
    }
  });
};

const websocketMiddleware = (store: Store) => (next: Function) => async (
  action: Action,
) => {
  let socket = null;

  switch (action.type) {
    case WEBSOCKET_CONNECT:
      const db = await InitIndexedDB();

      socket = io(CONFIG.API_V2_URL.replace('/v2', ''), {
        withCredentials: true,
      });

      socket.on(
        `/notifications/${action.userId}`,
        (payload: NotificationType) => {
          const normalized = normalize(payload, schema.notification);

          store.dispatch(addEntities(normalized.entities));
          store.dispatch({
            type: WEBSOCKET_NOTIFICATION,
            schema: schema.notification,
            payload: normalized.result,
          });
          handleNotificationPush(db, payload);
        },
      );

      socket.on(
        `/notifications/delete/${action.userId}`,
        (payload: NotificationType) => {
          store.dispatch({
            type: WEBSOCKET_DELETE_NOTIFICATION,
            schema: schema.notification,
            payload: payload.id,
          });
        },
      );

      break;

    case LOGOUT:
      if (socket) {
        socket.disconnect();
      }

      return next(action);
    default:
      return next(action);
  }
};

export default websocketMiddleware;
