import CONFIG from '@config';

import { hostRentRow } from '@redux/schema';

export const FETCH_HOST_RENT_ROWS = 'FETCH_HOST_RENT_ROWS';
export const fetchHostRentRows = (hostRentId: number) => ({
  type: FETCH_HOST_RENT_ROWS,
  route: `/host-rents/${hostRentId}/host-rent-rows`,
  method: 'GET',
  schema: [hostRentRow],
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const POST_HOST_RENT_ROW = 'POST_HOST_RENT_ROW';
export const postHostRentRow = (
  hostRentId: number,
  amount: number,
  reason: string,
) => ({
  type: POST_HOST_RENT_ROW,
  route: `/host-rents/${hostRentId}/host-rent-rows`,
  method: 'POST',
  body: {
    amount,
    reason,
  },
  meta: {
    hostRentId,
    amount,
    reason,
  },
  schema: hostRentRow,
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const DELETE_HOST_RENT_ROW = 'DELETE_HOST_RENT_ROW';
export const deleteHostRentRow = (hostRentRowId: number) => ({
  type: DELETE_HOST_RENT_ROW,
  route: `/host-rent-rows/${hostRentRowId}`,
  method: 'DELETE',
  schema: hostRentRow,
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});
