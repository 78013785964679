import * as React from 'react';
import { Dropdown } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';

import './GenericDropdownSelector.scss';

type Props = {
  data: Array<option>;
  onChange: Function;
  children: React.ReactNode;
  closeOnClick?: boolean;
  disabled?: boolean;
  t?: Function;
};

type State = {
  isOpen: boolean;
  selected: Array<any>;
};

interface option {
  value: string | number;
  label: string;
}

export class GenericDropdownSelector extends React.PureComponent<Props, State> {
  static ref: any;

  toggleRef: any;

  static defaultProps = {
    closeOnClick: false,
    disabled: false,
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      selected: [],
    };

    this.toggleRef = React.createRef();

    GenericDropdownSelector.ref = React.createRef();
  }

  componentDidUpdate() {
    this.handleOpen();
  }

  render() {
    const { isOpen, selected } = this.state;
    const { t, data, children, disabled } = this.props;

    return (
      <>
        <Dropdown className="GenericDropdownSelector" show={isOpen}>
          <Dropdown.Toggle
            ref={this.toggleRef}
            disabled={disabled}
            className={`GenericDropdownSelector__toggle ${
              disabled ? 'GenericDropdownSelector__toggle--disabled' : ''
            }`}
            size="sm"
            onClick={this.handleToggle as any}
          >
            {children}
          </Dropdown.Toggle>
          <Dropdown.Menu className="GenericDropdownSelector__menu">
            {data && data.length > 0 ? (
              data.map((d) => {
                return (
                  <Dropdown.Item
                    key={d.value}
                    disabled={selected.includes(d.value)}
                    onClick={this.handleClick.bind(null, d.value)}
                  >
                    {d.label}
                  </Dropdown.Item>
                );
              })
            ) : (
              <Dropdown.Item onClick={this.handleClickOutside as any}>
                {t('common.none')}
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
        {isOpen && (
          <div
            className="GenericDropdownSelector__toggle__overlay"
            onClick={this.handleClickOutside as any}
          />
        )}
      </>
    );
  }

  handleClick = (value: string | number, event: MouseEvent) => {
    const { onChange, closeOnClick } = this.props;
    const { selected } = this.state;

    event.stopPropagation();

    if (closeOnClick) {
      GenericDropdownSelector.ref.current = null;
    } else {
      const newSelected = selected.includes(value)
        ? selected.filter((e) => e === value)
        : [...selected, value];

      this.setState({
        selected: newSelected,
      });
    }

    onChange(value);
  };

  handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();

    if (GenericDropdownSelector.ref.current) {
      GenericDropdownSelector.ref.current = null;
      this.forceUpdate();
    }
  };

  handleOpen = () => {
    const { isOpen } = this.state;

    if (
      GenericDropdownSelector.ref.current !== this.toggleRef.current &&
      isOpen
    ) {
      this.setState({ isOpen: false, selected: [] });
    }
    if (
      GenericDropdownSelector.ref.current === this.toggleRef.current &&
      !isOpen
    ) {
      this.setState({ isOpen: true });
    }
  };

  handleToggle = (event: MouseEvent) => {
    event.stopPropagation();

    if (GenericDropdownSelector.ref.current === this.toggleRef.current) {
      GenericDropdownSelector.ref.current = null;
    } else {
      GenericDropdownSelector.ref.current = this.toggleRef.current;
    }

    this.forceUpdate();
  };
}

export default withTranslation()(GenericDropdownSelector);
