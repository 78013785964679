const CONFIG = {
  development: {
    ENVIRONMENT: 'development',
    API_URL: 'http://localhost:5005/api',
    API_V2_URL: 'http://localhost:7006/v2',
    FO_URL: 'http://localhost:3001',
    CLEANING_URL: 'http://localhost:3003',
    MAPS_TOKEN: 'AIzaSyCAa2q4ij1-8NZsonoGgbMNKrxA-sdeGpQ',
    GOOGLE_CLIENT_ID:
      '264989817729-rf2vb92hpak7keusq9c1e8p8vpeaaukq.apps.googleusercontent.com',
  },

  staging: {
    ENVIRONMENT: 'staging',
    API_URL: 'https://api-staging.leazly.fr/api',
    API_V2_URL: 'https://smart-api-staging.leazly.fr/v2',
    FO_URL: 'https://app-staging.leazly.fr',
    CLEANING_URL: 'https://contractor-staging.leazly.fr',
    MAPS_TOKEN: 'AIzaSyCAa2q4ij1-8NZsonoGgbMNKrxA-sdeGpQ',
    GOOGLE_CLIENT_ID:
      '264989817729-rf2vb92hpak7keusq9c1e8p8vpeaaukq.apps.googleusercontent.com',
  },

  test: {
    ENVIRONMENT: 'test',
    API_URL: 'http://localhost:5000/api',
    FO_URL: 'http://localhost:3000',
    API_V2_URL: 'http://localhost:7006/v2',
    CLEANING_URL: 'http://localhost:3003',
    MAPS_TOKEN: 'blabla',
    GOOGLE_CLIENT_ID: 'toto.apps.googleusercontent.com',
  },

  preproduction: {
    ENVIRONMENT: 'preproduction',
    API_URL: 'https://api-preproduction.leazly.fr/api',
    API_V2_URL: 'https://smart-api-preproduction.leazly.fr/v2',
    FO_URL: 'https://app-preproduction.leazly.fr',
    CLEANING_URL: 'https://contractor-preproduction.leazly.fr',
    MAPS_TOKEN: 'AIzaSyCAa2q4ij1-8NZsonoGgbMNKrxA-sdeGpQ',
    GOOGLE_CLIENT_ID:
      '264989817729-rf2vb92hpak7keusq9c1e8p8vpeaaukq.apps.googleusercontent.com',
  },

  production: {
    ENVIRONMENT: 'production',
    API_URL: 'https://api-production.leazly.fr/api',
    API_V2_URL: 'https://smart-api-production.leazly.fr/v2',
    FO_URL: 'https://app.leazly.fr',
    CLEANING_URL: 'https://contractor.leazly.fr',
    MAPS_TOKEN: 'AIzaSyCAa2q4ij1-8NZsonoGgbMNKrxA-sdeGpQ',
    GOOGLE_CLIENT_ID:
      '264989817729-rf2vb92hpak7keusq9c1e8p8vpeaaukq.apps.googleusercontent.com',
  },
};

let CURRENT_CONFIG = CONFIG[process.env.NODE_ENV];

const window = (global as any).window;
// If I get an ENVIRONMENT from the idex.html, I use it. If not then I am in dev mode
// istanbul ignore next
if (window && window.ENVIRONMENT && window.ENVIRONMENT !== '__ENVIRONMENT__')
  CURRENT_CONFIG = CONFIG[window.ENVIRONMENT];

// eslint-disable-next-line
export default CURRENT_CONFIG;
