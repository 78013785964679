export default class AccessStep {
  public id: number;
  public accessId: number;
  public type: string;
  public position: number;
  public value: string;

  constructor(data: Partial<AccessStep>) {
    this.id = data.id;
    this.accessId = data.accessId;
    this.type = data.type;
    this.position = data.position;
    this.value = data.value;
  }
}
