import { useCallback } from 'react';
import { useDispatch, useStore } from 'react-redux';

export function useAction<T extends (...args) => any>(fn: T) {
  const dispatch = useDispatch();
  const store = useStore();

  return useCallback((...args: Parameters<T>) => {
    return dispatch(fn.apply(null, [...args, dispatch, store.getState]));
  }, []);
}
