import React, { ChangeEvent, Component } from 'react';
import { Form } from 'react-bootstrap';

import { GenericFormGroup } from '@components';

type Props = {
  value?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  disabled?: boolean;
  autocomplete?: boolean;
  autoFocus?: boolean;
  controlId?: string;
  rows?: string | number;
  maxlength?: string | number;
};

class GenericTextArea extends Component<Props> {
  static defaultProps = {
    autocomplete: true,
    autoFocus: false,
    className: '',
    controlId: null,
    disabled: false,
    rows: '3',
    maxlength: undefined,
    onBlur: () => {},
  };

  render() {
    const {
      value,
      label,
      className,
      required,
      maxlength,
      controlId,
    } = this.props;

    const length = value ? value.length : 0;
    const max = parseInt(maxlength as string) || 0;

    let color = 'ok';

    if (max - length <= 10) color = 'warning';

    if (length >= max) color = 'error';

    return (
      <GenericFormGroup
        label={label}
        required={required}
        className={className}
        controlId={controlId}
      >
        {this.renderInput()}
        {maxlength && (
          <div className="text-right">
            <span className={`form__size form__size--${color}`}>
              {`${length}/${maxlength}`}
            </span>
          </div>
        )}
      </GenericFormGroup>
    );
  }

  renderInput = () => {
    const {
      value,
      label,
      disabled,
      placeholder,
      rows,
      autocomplete,
      autoFocus,
      required,
      maxlength,
    } = this.props;

    return (
      <Form.Control
        placeholder={placeholder || label}
        rows={parseInt(rows as string) || undefined}
        maxLength={parseInt(maxlength as string) || undefined}
        disabled={disabled}
        as="textarea"
        value={value || ''}
        required={required}
        autoFocus={autoFocus}
        onChange={this.handleChange}
        autoComplete={autocomplete ? 'on' : 'off'}
        onBlur={this.handleBlur}
      />
    );
  };

  handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { onChange } = this.props;
    const {
      target: { value },
    } = event;

    onChange?.(value);
  };

  handleBlur = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { onBlur } = this.props;
    const {
      target: { value },
    } = event;

    event.stopPropagation();

    if (onBlur && typeof onBlur === 'function') onBlur?.(value);
  };
}

export default GenericTextArea;
