import CONFIG from '@config';

import * as schema from '@redux/schema';

export const FETCH_NEIGHBORHOODS = 'FETCH_NEIGHBORHOODS';
export const fetchNeighborhoods = (
  offset = 0,
  limit = 10,
  text?: string,
  agencyId?: number,
  more = false,
) => ({
  type: FETCH_NEIGHBORHOODS,
  route: `/neighborhoods`,
  method: 'GET',
  query: {
    limit,
    offset,
    text,
    agencyId: agencyId || '',
  },
  meta: {
    more,
  },
  hideLoading: true,
  schema: [schema.neighborhood],
  url: CONFIG.API_V2_URL,
});

export const POST_NEIGHBORHOOD = 'POST_NEIGHBORHOOD';
export const postNeighborhood = (data: Object) => ({
  type: POST_NEIGHBORHOOD,
  route: `/neighborhoods`,
  method: 'POST',
  body: data,
  url: CONFIG.API_V2_URL,
});

export const ARCHIVE_NEIGHBORHOOD = 'ARCHIVE_NEIGHBORHOOD';
export const archiveNeighborhood = (id: number) => ({
  type: ARCHIVE_NEIGHBORHOOD,
  route: `/neighborhoods/${id}/archive`,
  method: 'POST',
  url: CONFIG.API_V2_URL,
});

export const PATCH_NEIGHBORHOOD = 'PATCH_NEIGHBORHOOD';
export const patchNeighborhood = (id: number, data: Object) => ({
  type: PATCH_NEIGHBORHOOD,
  route: `/neighborhoods/${id}`,
  method: 'PATCH',
  body: data,
  url: CONFIG.API_V2_URL,
});
