import CleaningContract from './CleaningContract';

export default class CleaningContracts {
  public cleaningContractDetails: CleaningContract;
  public cleaningContracts: CleaningContract[];
  public lateCleaningContracts: CleaningContract[];
  public cleaningContractsListTextFilter: string;

  constructor(data: any) {
    this.cleaningContractDetails = data.cleaningContractDetails || null;
    this.lateCleaningContracts = data.lateCleaningContracts || [];
    this.cleaningContracts = data.cleaningContracts || [];
    this.cleaningContractsListTextFilter =
      data.cleaningContractsListTextFilter || '';
  }
}
