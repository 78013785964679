import React, { FormEvent, PureComponent } from 'react';

import {
  faSort,
  faSortUp,
  faSortDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import '../GenericTable.scss';
import { Field, Fields, FieldOrder } from '../GenericTableTypes.d';

type Props = {
  sort: string;
  containerClassName?: string;
  rowClassName?: string;
  rowCellClassName?: string;
  fields: Fields;
  order?: FieldOrder;
  onSort?: (newOrder: string) => any;
};

type State = {};
export class GenericTableHeader extends PureComponent<Props, State> {
  static defaultProps = {
    order: undefined,
    sort: '',
    onSort: () => {},
    containerClassName: 'table__header generic-table__header',
    rowClassName: 'table__row generic-table__row',
    rowCellClassName: 'table__row__cell generic-table__row__cell',
  };

  render() {
    const { fields, rowClassName, containerClassName } = this.props;

    if (!fields) return null;

    return (
      <div className={containerClassName}>
        <div className={rowClassName}>
          {Object.keys(fields)
            .sort(this.orderSort)
            .map((key) => {
              const elem = fields[key];
              const classNames = [this.getRowCellClassName(elem)];

              if (/action/u.test(key))
                classNames.push(
                  'table__row__cell--actions generic-table__row__cell--actions',
                );
              if (elem.className) classNames.push(elem.className as string);

              return (
                <div
                  key={key || elem.label}
                  onClick={
                    elem.sortable
                      ? this.handleSortClick(key)
                      : this.handlePreventDefault
                  }
                  className={classNames.join(' ')}
                >
                  {elem.label}
                  {elem.sortable && this.renderSortIcon(key)}
                </div>
              );
            })}
        </div>
      </div>
    );
  }

  renderSortIcon = (field: string) => {
    const { sort } = this.props;

    let icon = faSort;

    if (sort === `${field}ASC`) icon = faSortUp;

    if (sort === `${field}DESC`) icon = faSortDown;

    return <FontAwesomeIcon className="ml-1 clickable" icon={icon} />;
  };

  handlePreventDefault = (event: FormEvent) => {
    event.preventDefault();
  };

  handleSortClick = (key: string) => () => {
    const { onSort, sort } = this.props;

    const cleanOrder = sort.replace(/(ASC)|(DESC)/u, '');
    let newSort = key;

    if (cleanOrder === key && sort.indexOf('ASC') !== -1) newSort += 'DESC';
    else newSort += 'ASC';

    if (onSort && typeof onSort === 'function') onSort(newSort);
  };

  getRowCellClassName = (field: Field) => {
    const { rowCellClassName } = this.props;

    if (!field.size) return rowCellClassName;

    return `${rowCellClassName || ''} table__row__cell--${
      field.size
    } generic-table__row__cell--${field.size}`;
  };

  orderSort = (a: string, b: string) => {
    const { order } = this.props;

    if (!order) return 0;

    return order.indexOf(a) > order.indexOf(b) ? 1 : -1;
  };
}

export default GenericTableHeader;
