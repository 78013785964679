import access from './access.json';
import agency from './agency.json';
import apartment from './apartment.json';
import apartmentKey from './apartmentKey.json';
import app from './app.json';
import booking from './booking.json';
import bookingsync from './bookingsync.json';
import city from './city.json';
import cleaningContract from './cleaningContract.json';
import comment from './comment.json';
import commission from './commission.json';
import common from './common.json';
import contact from './contact.json';
import contractor from './contractor.json';
import contractors from './contractors.json';
import hostRent from './hostRent.json';
import hostRentRow from './hostRentRow.json';
import internetFacilities from './internetFacilities.json';
import inventories from './inventories.json';
import keySetup from './keySetup.json';
import leaving from './leaving.json';
import login from './login.json';
import mandate from './mandate.json';
import neighborhood from './neighborhood.json';
import notification from './notification.json';
import others from './others.json';
import photoContract from './photoContract.json';
import prospect from './prospect.json';
import unSetup from './unSetup.json';
import user from './user.json';
import users from './users.json';

export default {
  app,
  mandate,
  login,
  neighborhood,
  photoContract,
  city,
  prospect,
  apartment,
  apartmentKey,
  'apartment-key': apartmentKey,
  comment,
  commission,
  leaving,
  bookingsync,
  contact,
  booking,
  agency,
  contractor,
  contractors,
  cleaningContract,
  hostRent,
  hostRentRow,
  users,
  user,
  internetFacilities,
  common,
  keySetup,
  unSetup,
  access,
  inventories,
  notification,
  ...others,
};
