import React, { ChangeEvent, Component } from 'react';
import { Form } from 'react-bootstrap';

import { GenericFormGroup } from '@components';

type Props = {
  value?: string | number | boolean;
  label?: string;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  onChange?: Function;
  controlId?: string;
  className?: string;
};

class GenericCheckbox extends Component<Props> {
  static defaultProps = {
    controlId: null,
    disabled: false,
    className: null,
  };

  render() {
    const { label, required, controlId, className } = this.props;

    return (
      <GenericFormGroup
        className={['form-group--checkbox', className].join(' ')}
        label={label}
        required={required}
        controlId={controlId}
      >
        {this.renderCheckbox()}
      </GenericFormGroup>
    );
  }

  renderCheckbox = () => {
    const { value, label, placeholder, required, disabled } = this.props;

    return (
      <Form.Check
        inline
        disabled={disabled}
        placeholder={placeholder || label}
        type="checkbox"
        checked={value as boolean}
        required={required}
        onChange={this.handleChange}
      />
    );
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    const {
      target: { checked },
    } = event;

    onChange(checked);
  };
}

export default GenericCheckbox;
