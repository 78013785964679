import { handle } from 'redux-pack';

import CleaningContracts from '@redux/models/CleaningContracts';

import {
  FETCH_CLEANING_CONTRACT,
  FETCH_CLEANING_CONTRACTS_BY_PRIORITY,
  SET_FOCUSED_CLEANING_CONTRACT,
  COMPLETE_CLEANING_CONTRACT,
  NOT_DONE_CLEANING_CONTRACT,
  CANCEL_CLEANING_CONTRACT,
  FETCH_LATE_CLEANING_CONTRACTS,
} from '@redux/cleaningContracts/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

const initialState = new CleaningContracts({});
export default function cleaningContractsReducer(
  state = initialState,
  action: any,
) {
  const { type } = action;

  switch (type) {
    case FETCH_CLEANING_CONTRACTS_BY_PRIORITY:
      return handle(state, action, {
        start: () => ({
          ...state,
        }),
        success: () => {
          return {
            ...state,
            cleaningContracts: action.meta.more
              ? state.cleaningContracts.concat(action.payload)
              : action.payload,
          };
        },
      });
    case FETCH_LATE_CLEANING_CONTRACTS:
      return handle(state, action, {
        start: () => ({
          ...state,
        }),
        success: () => ({
          ...state,
          lateCleaningContracts: action.payload,
        }),
      });
    case FETCH_CLEANING_CONTRACT:
      return handle(state, action, {
        start: () => ({
          ...state,
          cleaningContractDetails: null,
        }),
        success: () => ({
          ...state,
          cleaningContractDetails: action.payload,
        }),
      });
    case SET_FOCUSED_CLEANING_CONTRACT:
      return {
        ...state,
        cleaningContractDetails: action.payload,
      };
    case NOT_DONE_CLEANING_CONTRACT:
    case COMPLETE_CLEANING_CONTRACT:
      return handle(state, action, {
        success: () => ({
          ...state,
          lateCleaningContracts: [...state.lateCleaningContracts].filter(
            (id) => id !== action.payload,
          ),
        }),
      });
    case CANCEL_CLEANING_CONTRACT:
      return handle(state, action, {
        success: () => {
          return {
            ...state,
            cleaningContracts: state.cleaningContracts.filter(
              (cleaningContract) => cleaningContract !== action.payload,
            ),
          };
        },
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
