import '@locales/i18n';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as Sentry from '@sentry/browser';

import React from 'react';
import ReactDOM from 'react-dom';
import { pdfjs } from 'react-pdf';
import { Provider } from 'react-redux';

import 'core-js/stable';
import moment from 'moment-timezone';
import momentTZ from 'moment-timezone';
import frLocale from 'moment/locale/fr';
import { PersistGate } from 'redux-persist/integration/react';
import 'regenerator-runtime/runtime';

import CONFIG from '@config';

import createStore from '@redux/createStore';

import App from '@scopes/App/App';

import * as serviceWorker from './serviceWorker';

import '@styles/main.scss';

Promise.prototype.tap = function (fn: (...args: any[]) => Promise<any>) {
  return this.then((...args) => {
    fn.apply(this, args);

    return args.length === 1 ? args[0] : args;
  });
};

Promise['map'] = (arr, fn) => {
  return Promise.all(arr.map(fn));
};

Promise.prototype['cancel'] = () => {
  console.info('to implement');
};

Promise.prototype['isPending'] = () => {
  console.info('to implement');
};

Promise['delay'] = (ms: number) => {
  return new Promise((res) => setTimeout(res, ms));
};

moment.updateLocale('fr', frLocale);
momentTZ.updateLocale('fr', (moment.localeData() as any)._config);
momentTZ().tz('Europe/Paris').format();

// Hack from https://github.com/wojtekmaj/react-pdf/issues/291
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
// React-pdf cause 37 errors by preview 'cause of this missing polyfill
// Promise['allSettled'] = Promise.all;

if (process.env.NODE_ENV !== 'development')
  Sentry.init({
    dsn: 'https://f8a3337ff5eb4af09ffb5001c6ee2c28@sentry.io/1731953',
  });

const { store, persistor } = createStore();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <GoogleOAuthProvider clientId={CONFIG.GOOGLE_CLIENT_ID}>
        <App />
      </GoogleOAuthProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
