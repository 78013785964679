import moment from 'moment-timezone';
import { ApartmentKeyLocation } from 'src/types/global';

import {
  APARTMENT_KEY_DENOMINATION,
  APARTMENT_KEY_TYPE,
  BOOKING_CHECK_TYPE,
} from '@enum';

import Apartment from '@redux/models/Apartment';
import Booking from '@redux/models/Booking';

type GroupOpeningTime = {
  startMinuteOfDay: number;
  endMinuteOfDay: number;
  dayOfWeek: Array<number>;
};

declare let document: {
  getSelection: Function;
  createElement: Function;
  execCommand: Function;
  getElementsByClassName: Function;
  body: {
    appendChild: Function;
    removeChild: Function;
  };
};

export class Tools {
  t: Function;

  static handleStopPropagation(evt: MouseEvent) {
    evt.stopPropagation();
  }

  static pick(obj: Object, keys: Array<string>) {
    return Object.entries(obj).reduce(
      (acc, [key, value]) =>
        keys.includes(key) ? { ...acc, [key]: value } : acc,
      {},
    );
  }

  private static isObject(item: any): boolean {
    return (
      item &&
      typeof item === 'object' &&
      !Array.isArray(item) &&
      !moment.isMoment(item)
    );
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   */
  static mergeDeep(target: any, ...sources: any): any {
    if (!sources.length) return target;
    const source = sources.shift();

    if (Tools.isObject(target) && Tools.isObject(source)) {
      for (const key in source) {
        if (Tools.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }

          Tools.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return Tools.mergeDeep(target, ...sources);
  }

  static diff(reference: Object, newRef: Object) {
    return Object.entries(newRef).reduce(
      (acc, [key, value]) =>
        (key in reference && reference[key] !== value) || !(key in reference)
          ? { ...acc, [key]: value }
          : acc,
      {},
    );
  }

  static countByProperty = (arr: Array<any>, property: string) =>
    arr.reduce((counter, item) => {
      const name = item[property];

      counter[name] = (counter[name] || 0) + 1;

      return counter;
    }, {});

  static apartmentKeyLocationOpeningHoursToHumanFormat = (
    openingHours: ApartmentKeyLocation['openingHours'],
  ) => {
    const groupBySameOpeningTime = (openingHours || []).reduce<any>(
      (acc: Array<GroupOpeningTime>, openingHour) => {
        const elemIndexWithSameSchedule = acc.findIndex(
          (element) =>
            element.endMinuteOfDay === openingHour.endMinuteOfDay &&
            element.startMinuteOfDay === openingHour.startMinuteOfDay,
        );

        if (elemIndexWithSameSchedule !== -1) {
          return acc.map<GroupOpeningTime>((elem, index) => {
            if (index === elemIndexWithSameSchedule) {
              return {
                ...elem,
                dayOfWeek: [...elem.dayOfWeek, openingHour.dayOfWeek].sort(),
              };
            }

            return elem;
          });
        }

        return [...acc, { ...openingHour, dayOfWeek: [openingHour.dayOfWeek] }];
      },
      [],
    );

    groupBySameOpeningTime.sort(
      (
        elemA: { dayOfWeek: Array<number> },
        elemB: { dayOfWeek: Array<number> },
      ) => {
        if (elemA.dayOfWeek.length > elemB.dayOfWeek.length) return -1;

        return 1;
      },
    );

    const areFollowingDays = (groupOpeningTime: GroupOpeningTime): boolean => {
      return (
        groupOpeningTime.dayOfWeek.length > 1 &&
        groupOpeningTime.dayOfWeek.reduce<boolean>(
          (acc: boolean, day: number, index: number) => {
            if (acc === false) return false;
            if (index === 0) return true;

            return (
              groupOpeningTime.dayOfWeek[index] ===
              groupOpeningTime.dayOfWeek[index - 1] + 1
            );
          },
          true,
        )
      );
    };

    const minutesToHumanFormat = (minutes: number) =>
      moment().startOf('day').add(minutes, 'minutes').format('HH:mm');

    const daysToHumanFormat = (days: number) =>
      moment().endOf('week').add(days, 'days').format('ddd').slice(0, -1);

    return groupBySameOpeningTime.map((groupOpeningTime: GroupOpeningTime) => {
      const sortedDayOfWeek = [...groupOpeningTime.dayOfWeek].sort(
        (dayOfWeek1, dayOfWeek2) => {
          if (dayOfWeek1 === 0 || dayOfWeek2 === 0) {
            return -1;
          }

          return 1;
        },
      );
      const openingHour = minutesToHumanFormat(
        groupOpeningTime.startMinuteOfDay,
      );
      const closingHour = minutesToHumanFormat(groupOpeningTime.endMinuteOfDay);
      const days = areFollowingDays(groupOpeningTime)
        ? `${daysToHumanFormat(sortedDayOfWeek[0])} - ${daysToHumanFormat(
            sortedDayOfWeek[sortedDayOfWeek.length - 1],
          )}`
        : sortedDayOfWeek
            .map((day: number) => daysToHumanFormat(day))
            .join(',');

      return `${days} : ${openingHour} - ${closingHour}`;
    });
  };

  static downloadLink = (fileName: string, downloadFile: Blob) => {
    const a = document.createElement('a');

    a.href = URL.createObjectURL(downloadFile);
    a.download = fileName;

    if (document.body) document.body.appendChild(a);

    a.click();

    if (document.body) document.body.removeChild(a);
  };

  constructor(translate: Function = null) {
    this.t = translate;
  }

  dispatchArray = (
    prefix: string,
    items: Array<string>,
    numericKey?: boolean,
  ) => {
    const obj = [];

    items.forEach((item: string) => {
      obj.push({
        id: numericKey ? (item as any).id : item,
        label: this.t(prefix + item),
      });
    });

    return obj;
  };

  dispatchSSelectArray = (prefix: string, items: Array<string>) => {
    const obj = [];

    items.forEach((item: string) => {
      obj.push({
        value: item,
        label: this.t(prefix + item),
        group:
          item.startsWith('wait') ||
          item === 'to-sign' ||
          item.endsWith('to-validate')
            ? 'waitStatus'
            : 'status',
      });
    });

    return obj;
  };

  getItemName(list: Array<any>, id: number) {
    const foundItem = list.length && list.find((item) => item.id === id);

    return foundItem ? foundItem.name : '';
  }

  cleanString = (
    txt: string,
    removeSpaces = false,
    removeDashes = false,
    removeAmpersands = false,
  ) => {
    /*  eslint-disable */
    const accent = [
      /[\300-\306]/g,
      /[\340-\346]/g, // A, a
      /[\310-\313]/g,
      /[\350-\353]/g, // E, e
      /[\314-\317]/g,
      /[\354-\357]/g, // I, i
      /[\322-\330]/g,
      /[\362-\370]/g, // O, o
      /[\331-\334]/g,
      /[\371-\374]/g, // U, u
      /[\321]/g,
      /[\361]/g, // N, n
      /[\307]/g,
      /[\347]/g, // C, c
    ];

    const noaccent = [
      'A',
      'a',
      'E',
      'e',
      'I',
      'i',
      'O',
      'o',
      'U',
      'u',
      'N',
      'n',
      'C',
      'c',
    ];

    if (!txt) return '';

    for (let i = 0; i < accent.length; i++)
      txt = txt.replace(accent[i], noaccent[i]);

    if (removeSpaces) txt = txt.replace(/\s+/g, '');
    if (removeDashes) txt = txt.replace(/-/g, '');
    if (removeAmpersands) txt = txt.replace(/&/g, '');
    /* eslint-enable */

    return txt.toLowerCase().trim();
  };

  splitPhoneNumber = (phoneNumber: string): string => {
    const cleanedPhoneNumber = phoneNumber?.replace(/ /gu, '') || '';

    if (cleanedPhoneNumber.indexOf('+') === 0) {
      if (cleanedPhoneNumber.length === 13)
        return `${cleanedPhoneNumber.substring(0, 4)} (0)${
          cleanedPhoneNumber[4]
        } ${cleanedPhoneNumber
          .substring(5)
          .replace(/(.{2})/gu, '$1 ')
          .slice(0, -1)}`;

      return `${cleanedPhoneNumber.substring(0, 3)} (0)${
        cleanedPhoneNumber[3]
      } ${cleanedPhoneNumber
        .substring(4)
        .replace(/(.{2})/gu, '$1 ')
        .slice(0, -1)}`;
    }
    if (cleanedPhoneNumber?.[0] !== '0') {
      if (cleanedPhoneNumber.length === 11) {
        // Phone number doesn't start with 0 so first characters are country indicator, if 10 digits, 2 numbers indicator, if 11, 3 numbers indicator
        return `+${cleanedPhoneNumber.substring(0, 2)} (0)${
          cleanedPhoneNumber[2]
        } ${cleanedPhoneNumber
          .substring(3)
          .replace(/(.{2})/gu, '$1 ')
          .slice(0, -1)}`;
      }
      if (cleanedPhoneNumber.length === 12)
        return `+${cleanedPhoneNumber.substring(0, 3)} (0)${
          cleanedPhoneNumber[3]
        } ${cleanedPhoneNumber
          .substring(4)
          .replace(/(.{2})/gu, '$1 ')
          .slice(0, -1)}`;
    }

    return cleanedPhoneNumber.replace(/(.{2})/gu, '$1 ').slice(0, -1);
  };

  copyToClipboard = (str = '') => {
    const el = document.createElement('textarea');

    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);

    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;

    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
  };

  isString = (val: any) => {
    return val && (val instanceof String || typeof val === 'string');
  };

  isFrenchPhoneNumber = (val: any) => {
    if (!this.isString(val)) {
      return false;
    }

    const regexFrench = /^(((\+)|00)33|0)[1-9](\d{2}){4}$/gu;

    return regexFrench.test(val.trim().replace(/\s|[,.-]/gu, ''));
  };

  isInternationalPhoneNumber = (val: any) => {
    if (!this.isString(val)) {
      return false;
    }

    const regexInternational = /^(\+|00)(?:[0-9]?){6,14}[0-9]$/u;

    return regexInternational.test(val.trim().replace(/\s|[,.-]/gu, ''));
  };

  isPhoneNumber = (val: any) => {
    if (!this.isString(val)) {
      return false;
    }

    return (
      this.isFrenchPhoneNumber(val) || this.isInternationalPhoneNumber(val)
    );
  };

  formatNumber = (number: number) => {
    return number.toLocaleString().replace(',', '.').replace(/\s/gu, '&nbsp;');
  };

  transformHTMLSemiAutoMailToPlainText = (
    content: string,
  ): Record<string, any> => {
    const mailTemplate = document.createElement('div');
    mailTemplate.className = 'mail';
    mailTemplate.style.display = 'none';
    mailTemplate.innerHTML = content;

    document.body.appendChild(mailTemplate);

    const fixedElements = document.getElementsByClassName('fixedContent');

    const header = fixedElements[0].cloneNode(true).outerHTML;
    const footer = fixedElements[1].cloneNode(true).outerHTML;

    while (fixedElements.length > 0) {
      fixedElements[0].remove();
    }

    if (document.getElementsByClassName('action')[0]) {
      document.getElementsByClassName('action')[0].outerHTML =
        '{{ ACTION }}\n\n';
    }

    const formattedContent = document
      .getElementsByClassName('mail')[0]
      .innerHTML.replace(/<[/]p>/g, '\n\n')
      .replace(/<p>/g, '')
      .replace(/\n<ul.*?>/g, '')
      .replace(/<ul.*?>/g, '')
      .replace(/<[/]ul>/g, '\n')
      .replace(/<br>/g, '\n')
      .replace(/<li>(.*?)<[/]li>/g, '- $1\n');

    mailTemplate.remove();

    return { header, contentToPlainText: formattedContent, footer };
  };

  transformPlainTextSemiAutoMailToHTML = (
    content: string,
    updatedContent: string,
  ): string => {
    const mailTemplate = document.createElement('div');
    mailTemplate.className = 'mail';
    mailTemplate.style.display = 'none';
    mailTemplate.innerHTML = content;

    document.body.appendChild(mailTemplate);

    let action;
    if (document.getElementsByClassName('action')[0]) {
      action = document.getElementsByClassName('action')[0].outerHTML;
    }

    const fixedTop = document.getElementsByClassName('fixedContent')[0]
      .outerHTML;
    const fixedBottom = document.getElementsByClassName('fixedContent')[1]
      .outerHTML;

    const updatedMail = document.createElement('div');
    updatedMail.className = 'updatedMail';
    updatedMail.style.visibility = 'hidden';
    updatedMail.innerHTML = updatedContent;

    // We are doing weird stuff when replacing ACTION here but trust me, it's supposed to be this way
    // Might need a big refacto for better handling, but not this time
    const formattedUpdatedMail =
      fixedTop +
      updatedMail.innerHTML
        .replace('{{ ACTION }}\n\n', action + '<br>')
        .replace('{{ ACTION }}\n', action + '<br>')
        .replace(/-\s(.*?)\n/g, '<li>$1</li>')
        .replace(
          /(<li>.*<[/]li>)/g,
          `<ul style="list-style-type: '- ';">$1</ul>`,
        )
        .replace(/\n/g, '<br>') +
      fixedBottom;

    mailTemplate.remove();

    return formattedUpdatedMail;
  };

  static getInitialsFromName = (fullname: string) => {
    if (!fullname) return fullname;
    const [firstname, ...lastnames] = fullname.trim().split(/\s|-/);

    return `${firstname[0]}${this.getInitialsFromName(lastnames.join(' '))}`;
  };

  sortLabels = (lastWord?: string) => (
    a: { label: string },
    b: { label: string },
  ) => {
    return (
      (lastWord && a.label === lastWord && 1) ||
      (lastWord && b.label === lastWord && -1) ||
      a.label.localeCompare(b.label)
    );
  };

  sortStrings = (wantedOrder: string[]) => (a: string, b: string) =>
    wantedOrder.indexOf(a) - wantedOrder.indexOf(b);

  static expectedTimeIsBetweenLocationTimeSchedule = (booking: Booking) => {
    const timeSchedule =
      booking.checkType === BOOKING_CHECK_TYPE.CHECKIN
        ? booking.startDate.day()
        : booking.endDate.day();

    const guestKey = (booking.apartment.keys || []).find(
      (key) =>
        key.denomination === APARTMENT_KEY_DENOMINATION.GUEST &&
        [APARTMENT_KEY_TYPE.KEYCAFE, APARTMENT_KEY_TYPE.KEYNEST].includes(
          key.type,
        ),
    );

    const expectedTime =
      booking.checkType === BOOKING_CHECK_TYPE.CHECKIN
        ? booking.expectedCheckinTime
        : booking.expectedCheckoutTime;

    if (!expectedTime || !guestKey || !guestKey.locationTimeSchedule) {
      return false;
    }

    const locationTimeSchedule = (guestKey.locationTimeSchedule || []).find(
      (hour) => hour.dayOfWeek === timeSchedule,
    );

    const shopHours: any = Object.entries(locationTimeSchedule).reduce(
      (acc, [key, value]: [string, string]) => ({
        ...acc,
        [key]: parseInt(value),
      }),
      {},
    );

    const expectedTimeMinutes = moment
      .duration(moment(expectedTime, 'HH:mm').format('HH:mm'))
      .asMinutes();

    const isIn =
      shopHours.startMinuteOfDay < shopHours.endMinuteOfDay
        ? expectedTimeMinutes < shopHours.startMinuteOfDay ||
          expectedTimeMinutes > shopHours.endMinuteOfDay
        : expectedTimeMinutes < shopHours.startMinuteOfDay &&
          expectedTimeMinutes > shopHours.endMinuteOfDay;

    return isIn;
  };

  checkMissingFields = (apartment: Apartment) => {
    const { contact, leavings, keywords, rooms } = apartment;

    const missingFields = [];

    if (!apartment.agencyId)
      missingFields.push('mandate.missing.apartment.agencyId');

    if (!apartment.risk) missingFields.push('mandate.missing.apartment.risk');

    if (!contact.user.gender)
      missingFields.push('mandate.missing.contact.gender');

    if (!contact.user.firstname)
      missingFields.push('mandate.missing.contact.firstname');

    if (!contact.user.lastname)
      missingFields.push('mandate.missing.contact.lastname');

    if (!contact.birthDate)
      missingFields.push('mandate.missing.contact.birthDate');

    if (!contact.birthTown)
      missingFields.push('mandate.missing.contact.birthTown');

    if (!contact.address) missingFields.push('mandate.missing.contact.address');

    if (!contact.user.phone)
      missingFields.push('mandate.missing.contact.phoneNumber');

    if (!contact?.identityDocuments?.length)
      missingFields.push('mandate.missing.contact.identityDocuments');

    if (!apartment.address)
      missingFields.push('mandate.missing.apartment.address');

    if (!apartment.city) missingFields.push('mandate.missing.apartment.city');

    if (!apartment.postalCode)
      missingFields.push('mandate.missing.apartment.postalCode');

    if (!leavings[0].rent) missingFields.push('mandate.missing.leaving.rent');

    if (!apartment.proofDocumentId)
      missingFields.push(
        `mandate.missing.apartment.proofDocument${apartment.ownership}`,
      );

    if (!leavings[0].startDate)
      missingFields.push('mandate.missing.leaving.startDate');

    if (!leavings[0].endDate)
      missingFields.push('mandate.missing.leaving.endDate');

    if (!apartment.ownership)
      missingFields.push('mandate.missing.apartment.ownership');

    if (apartment.isOwner() && !apartment.residenceType)
      missingFields.push('mandate.missing.apartment.residenceType');

    if (leavings[0].commission) {
      if (!leavings[0].commission.percent)
        missingFields.push('mandate.missing.commission.percent');

      if (!leavings[0].commission.startDate)
        missingFields.push('mandate.missing.commission.startDate');

      if (!leavings[0].commission.endDate)
        missingFields.push('mandate.missing.commission.endDate');
    }

    if (!apartment.neighborhoodId)
      missingFields.push('mandate.missing.apartment.neighborhood');

    if (apartment.equipments.length === 0)
      missingFields.push('mandate.missing.apartment.equipment');

    if ((rooms || []).length === 0)
      missingFields.push('mandate.missing.apartment.rooms');
    else {
      const apartmentRooms: { [key: string]: number } = Tools.countByProperty(
        rooms || [],
        'type',
      );

      if (
        !apartmentRooms ||
        ((!('bedroom' in apartmentRooms) || apartmentRooms.bedroom < 1) &&
          (!('livingroom' in apartmentRooms) || apartmentRooms.livingroom < 1))
      )
        missingFields.push('mandate.missing.apartment.rooms');
    }

    const keyWordCounts: { [key: string]: number } = Tools.countByProperty(
      keywords || [],
      'type',
    );

    if (
      keywords?.length === 0 ||
      !('apartment' in keyWordCounts) ||
      keyWordCounts.apartment < 3 ||
      !('building' in keyWordCounts) ||
      keyWordCounts.building < 1 ||
      !('neighborhood' in keyWordCounts) ||
      keyWordCounts.neighborhood < 1
    )
      missingFields.push('mandate.missing.apartment.keywords');

    if (!contact.ribId) {
      missingFields.push('mandate.missing.contact.rib');
    }

    if (!contact.bic || !contact.iban)
      missingFields.push('mandate.missing.contact.bankingInfo');

    if (!apartment.cleaningContractorId)
      missingFields.push('mandate.missing.apartment.contractor');

    if (!apartment.sale) missingFields.push('mandate.missing.apartment.sale');

    if (leavings[0].isFakeDate)
      missingFields.push('mandate.missing.leaving.isFakeDate');

    return missingFields;
  };
}
