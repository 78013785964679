import { schema } from 'normalizr';

export const contractor = new schema.Entity('contractors');
export const notification = new schema.Entity('notifications');

export const guest = new schema.Entity('guests');
export const user = new schema.Entity('users');
export const employee = new schema.Entity('employees', {
  user,
});
export const contractorEmployee = new schema.Entity('contractorEmployees', {
  contractor,
  user,
});
export const accessKeyInfo = new schema.Entity('accessKeyInfos');
export const apartmentKey = new schema.Entity('apartmentKeys', {
  accessKeyInfos: [accessKeyInfo],
  updatedBy: user,
});
export const lastCheck = new schema.Entity('lastChecks');
export const keySetup = new schema.Entity('keySetups');
export const inventory = new schema.Entity('inventories');
export const publishComment = new schema.Entity('publishComments');
export const modality = new schema.Entity('modality');
export const leaving = new schema.Entity('leavings', {
  keySetups: [keySetup],
  lastCheck,
  inventories: [inventory],
  inventoryInOperational: employee,
  inventoryOutOperational: employee,
  inventoryOutValidationOperational: employee,
  publishedAgent: employee,
  publishComments: [publishComment],
  modality: modality,
});
export const city = new schema.Entity('cities');
export const contact = new schema.Entity('contacts', { user });
export const room = new schema.Entity('rooms');
export const neighborhood = new schema.Entity('neighborhoods');
export const rentalAgency = new schema.Entity('rentalAgencies');
export const translatedText = new schema.Entity('translatedTexts');
export const apartmentKeyComment = new schema.Entity('apartmentKeyComments');
export const prerequisite = new schema.Entity('prerequisites');
export const apartment = new schema.Entity('apartments', {
  leavings: [leaving],
  rooms: [room],
  contact,
  sale: employee,
  customerExperience: employee,
  neighborhood,
  keys: [apartmentKey],
  rentalAgency,
  description: translatedText,
  title: translatedText,
  summary: translatedText,
  summaryLong: translatedText,
  accessDescription: translatedText,
  contactDescription: translatedText,
  neighborhoodDescription: translatedText,
  transport: translatedText,
  apartmentKeyComments: [apartmentKeyComment],
  prerequisites: [prerequisite],
});

export const hostRent = new schema.Entity('hostRents', {
  apartment,
});

export const hostRentRow = new schema.Entity('hostRentRows', {
  hostRent,
});

export const hostRentSepa = new schema.Entity('hostRentSepas', {
  generatedBy: employee,
  canceledBy: employee,
});

export const prospect = new schema.Entity('prospects', {
  apartment,
});

export const booking = new schema.Entity('bookings', {
  apartment,
  guest,
  apartmentKey,
});

export const bookingCheckinCheckout = new schema.Entity(
  'bookingsCheckinCheckout',
  {
    apartment,
    guest,
  },
  { idAttribute: (value) => `${value.id}_${value.checkType}` },
);

export const cleaningContract = new schema.Entity('cleaningContracts', {
  apartment,
  booking,
  contractor,
  user: contractorEmployee,
});

export const photoContract = new schema.Entity('photoContracts', {
  apartment,
});

accessKeyInfo.define({ apartmentKey });
leaving.define({ apartment });
