import Activity from './Activity';
import Prospect from './Prospect';
import Reminder from './Reminder';
import Statistics from './Statistics';

export default class Prospects {
  public prospectActivity: Activity[];
  public prospectDetails: Prospect;
  public prospects: Prospect[];
  public prospectsTotal: number;
  public prospectsListTextFilter: string;
  public prospectsNew: Prospect[];
  public prospectsNewTotal: number;
  public prospectsSigned: Prospect[];
  public prospectsSignedTotal: number;
  public statistics: Statistics;
  public prospectReminders: Reminder[];

  constructor(data: any) {
    this.prospectActivity = data.prospectActivity || [];
    this.prospectDetails = data.prospectDetails || null;
    this.prospects = data.prospects || [];
    this.prospectsTotal = data.prospectsTotal || 0;
    this.prospectsListTextFilter = data.prospectsListTextFilter || '';
    this.prospectsNew = data.prospectsNew || [];
    this.prospectsNewTotal = data.prospectsNewTotal || 0;
    this.prospectsSigned = data.prospectsSigned || [];
    this.prospectsSignedTotal = data.prospectsSignedTotal || 0;
    this.statistics = data.statistics || null;
    this.prospectReminders = data.prospectReminders || [];
  }
}
