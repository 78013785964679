import React from 'react';
import { Image } from 'react-bootstrap';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useImage, UseImageOptions } from '@hooks/useImage/useImage';

import { AcceptedFileType } from '@components/GenericDropzone/GenericDropzone';

import './GenericImage.scss';

function GenericImage({
  id,
  mimeType,
  ...options
}: { id: number; mimeType?: string } & UseImageOptions) {
  const image = useImage(id, options);
  if (!image) {
    return (
      <div className="GenericImage text-center">
        <FontAwesomeIcon className="loader fa-pulse" icon={faSpinner} />
      </div>
    );
  }

  if (
    [
      AcceptedFileType.PDF,
      AcceptedFileType.DOCX,
      AcceptedFileType.DOC,
    ].includes(mimeType as any)
  ) {
    return <iframe src={image + '#toolbar=0'} width="70%" height="80%" />;
  }

  return <Image className="GenericImage" src={image} />;
}

export default React.memo(GenericImage);
