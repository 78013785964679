export default class Translation {
  public id: number;
  public translatedTextId: number;
  public language: string;
  public text: string;

  constructor(data: Partial<Translation>) {
    Object.assign(this, data);
  }
}
