import moment from 'moment-timezone';
import { Document } from 'src/types/global';

import User from '@redux/models/User';

import Reminder from './Reminder';

export default class Activity {
  public id: number;
  public createdAt: moment.Moment;
  public updatedAt: moment.Moment;
  public data: any;
  public target = '';
  public leavingId?: number;
  public contactId?: number;
  public apartmentId?: number;
  public type = '';
  public user: User = null;
  public attachments?: Document[] = [];

  constructor(
    data: Partial<Activity | { createdAt?: string; updatedAt?: string }>,
  ) {
    Object.assign(this, data);

    this.createdAt = moment(data.createdAt);
    this.updatedAt = moment(data.updatedAt);
    this.user = (data as Partial<Activity>).user
      ? new User((data as Partial<Activity>).user)
      : null;
  }

  static fromReminder(reminder: Reminder) {
    return new Activity({
      id: reminder.id,
      createdAt: reminder.createdAt,
      updatedAt: reminder.updatedAt,
      target: 'mailType',
      type: reminder.type,
      data: {},
    });
  }
}
