import { handle } from 'redux-pack';

import { LOGOUT, RESET } from '@redux/me/actions';
import { FETCH_PHOTO_CONTRACTS } from '@redux/photoContracts/actions';

const initialState = {
  photoContracts: [],
};
export default function photoContractsReducer(
  state = initialState,
  action: any,
) {
  const { type } = action;

  switch (type) {
    case FETCH_PHOTO_CONTRACTS:
      return handle(state, action, {
        start: () => ({
          ...state,
        }),
        success: () => ({
          ...state,
          photoContracts: action.meta.more
            ? state.photoContracts.concat(action.payload)
            : action.payload,
        }),
      });
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
