import { Dispatch } from 'src/types/redux';

import CONFIG from '@config';

import { fetchContractor } from '@redux/contractors/actions';

import { getMe } from '@redux/me/selectors';

import * as schema from '@redux/schema';

export const ENTER_EMAIL = 'ENTER_EMAIL';
export const enterEmail = (email: string) => ({
  type: ENTER_EMAIL,
  email,
});

export const LOGIN = 'LOGIN';
export const login = (email: string, password: string) => (
  dispatch?: Dispatch,
  getState?: Function,
) => {
  return dispatch({
    type: LOGIN,
    route: '/users/login',
    method: 'POST',
    body: {
      email,
      password,
    },
    schema: schema.user,
    hideError: true,
    url: CONFIG.API_V2_URL,
  }).then(async (result: Object) => {
    const user = getMe(getState());

    if (!user) return result;

    const res = await dispatch(setToken(user.token));

    if (user.isContractor()) {
      await dispatch(fetchContractor(user.contractorEmployee.contractorId));
    }

    return res;
  });
};

export const loginGoogle = (credential: string) => (
  dispatch?: Dispatch,
  getState?: Function,
) => {
  return dispatch({
    type: LOGIN,
    route: '/users/login/google',
    method: 'POST',
    body: {
      credential,
    },
    schema: schema.user,
    hideError: true,
    url: CONFIG.API_V2_URL,
  }).then(async (result: Object) => {
    const user = getMe(getState());

    if (!user) return result;

    const res = await dispatch(setToken(user.token));

    if (user.isContractor()) {
      await dispatch(fetchContractor(user.contractorEmployee.contractorId));
    }

    return res;
  });
};

export const SET_TOKEN = 'SET_TOKEN';
export const setToken = (token: string) => ({
  type: SET_TOKEN,
  token,
});

export const AUTHENTICATE = 'AUTHENTICATE';
export const authenticate = (token?: string) => ({
  type: AUTHENTICATE,
  route: '/users/authenticated',
  method: 'GET',
  schema: !token ? schema.user : undefined,
  hideError: true,
  hideLoading: true,
  token,
  url: CONFIG.API_V2_URL,
});

export const PATCH_ME = 'PATCH_ME';
export const patchMe = (userModifications: Object) => ({
  type: PATCH_ME,
  route: `/users/me`,
  method: 'PATCH',
  body: userModifications,
  schema: schema.user,
  url: CONFIG.API_V2_URL,
});

export const PATCH_PROFILE_PICTURE = 'PATCH_PROFILE_PICTURE';
export const patchProfilePicture = (file: FormData) => ({
  type: PATCH_PROFILE_PICTURE,
  route: `/users/me/profile-picture`,
  method: 'PATCH',
  body: file,
  schema: schema.user,
  url: CONFIG.API_V2_URL,
});

export const LOGOUT = 'LOGOUT';
export const logout = () => ({
  type: LOGOUT,
  route: `/users/logout`,
  method: 'POST',
  url: CONFIG.API_V2_URL,
});

export const RESET = 'RESET';
export const reset = () => ({
  type: RESET,
});

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const forgotPassword = (email: string) => ({
  type: FORGOT_PASSWORD,
  route: `/users/forgot-password`,
  method: 'POST',
  body: { email },
  url: CONFIG.API_V2_URL,
});

export const NEW_PASSWORD = 'NEW_PASSWORD';
export const newPassword = (token: string, password: string) => ({
  type: NEW_PASSWORD,
  route: '/users/new-password',
  method: 'POST',
  url: CONFIG.API_V2_URL,
  body: {
    password,
  },
  token,
});
