import moment from 'moment-timezone';
import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';
import { State } from 'src/types/redux';

import ENUM from '@enum';

import Activity from '@redux/models/Activity';
import Apartment from '@redux/models/Apartment';
import ApartmentKey from '@redux/models/ApartmentKey';
import Apartments from '@redux/models/Apartments';
import Booking from '@redux/models/Booking';
import CleaningContract from '@redux/models/CleaningContract';
import KeySetup from '@redux/models/KeySetup';
import LastCheck from '@redux/models/LastCheck';
import Leaving from '@redux/models/Leaving';

import { getBookingFromCheckinCheckout } from '@redux/bookings/selectors';
import { getActivityFilter } from '@redux/common/selectors';
import { getEntities } from '@redux/entities/selectors';

import {
  apartment,
  booking,
  leaving,
  apartmentKey,
  lastCheck,
  cleaningContract,
} from '@redux/schema';

export const getApartments = (state: State): Apartments => state.apartments;
export const getLeavings = createSelector(
  getEntities,
  (entities: any): { [id: number]: Leaving } => entities.leavings,
);

export const getApartmentById = (apartmentId: number) =>
  createSelector(getEntities, (entities) => {
    const ap = denormalize(apartmentId, apartment, entities);

    return ap && new Apartment(ap);
  });

export const getApartmentsToCheckinTextFilter = createSelector(
  getApartments,
  (apartmentState) => apartmentState.leavingsToCheckinTextFilter,
);

export const getApartmentsToCheckoutTextFilter = createSelector(
  getApartments,
  (apartmentState) => apartmentState.leavingsToCheckoutTextFilter,
);

export const getApartmentsListTextFilter = createSelector(
  getApartments,
  (apartmentState) => apartmentState.apartmentsListTextFilter,
);

export const getApartmentsListCapacityFilter = createSelector(
  getApartments,
  (apartmentState) => apartmentState.apartmentsListCapacityFilter,
);

export const getApartmentsListAreaMinFilter = createSelector(
  getApartments,
  (apartmentState) => apartmentState.apartmentsListAreaMinFilter,
);

export const getApartmentsListAreaMaxFilter = createSelector(
  getApartments,
  (apartmentState) => apartmentState.apartmentsListAreaMaxFilter,
);

export const getApartmentsListCategoryFilter = createSelector(
  getApartments,
  (apartmentState) => apartmentState.apartmentsListCategoryFilter,
);

export const getApartmentsList = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentState) => {
    return denormalize(apartmentState.apartments, [apartment], entities).map(
      (a) => new Apartment(a),
    );
  },
);

export const getApartmentsToCheckinList = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentState) => {
    return denormalize(
      apartmentState.leavingsToCheckin,
      [leaving],
      entities,
    ).map((a) => new Leaving(a));
  },
);

export const getApartmentsToCheckoutList = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentState) => {
    return denormalize(
      apartmentState.leavingsToCheckout,
      [leaving],
      entities,
    ).map((a) => new Leaving(a));
  },
);

export const getWaitingLeavingsInventoriesList = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentState) => {
    return denormalize(
      apartmentState.waitingLeavingsInventories,
      [leaving],
      entities,
    ).map((a) => new Leaving({ ...a, isWaiting: true }));
  },
);

export const getLeavingsInventoriesList = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentState) => {
    return denormalize(
      apartmentState.leavingsInventories,
      [leaving],
      entities,
    ).map((a) => new Leaving(a));
  },
);

export const getApartmentDetails = createSelector(
  getEntities,
  getApartments,
  (entities, { apartmentDetails }) => {
    const apartmentData = denormalize(apartmentDetails, apartment, entities);

    return apartmentData && new Apartment(apartmentData);
  },
);

export const getApartmentPopover = createSelector(
  getEntities,
  getApartments,
  (entities, { apartmentPopover }) => {
    const apartmentData = denormalize(apartmentPopover, apartment, entities);

    return apartmentData && new Apartment(apartmentData);
  },
);

export const getApartmentCheckinDetails = createSelector(
  getEntities,
  getApartments,
  (entities, { apartmentCheckinDetails }) => {
    const apartmentData = denormalize(
      apartmentCheckinDetails,
      apartment,
      entities,
    );

    return apartmentData && new Apartment(apartmentData);
  },
);

export const getApartmentCheckoutDetails = createSelector(
  getEntities,
  getApartments,
  (entities, { apartmentCheckoutDetails }) => {
    const apartmentData = denormalize(
      apartmentCheckoutDetails,
      apartment,
      entities,
    );

    return apartmentData && new Apartment(apartmentData);
  },
);

export const getApartmentActivity = createSelector(
  getApartments,
  getActivityFilter,
  (apartment, activityFilter) => {
    if (!activityFilter) return apartment.apartmentActivity;

    return apartment.apartmentActivity.filter(
      (activity: Activity) => activity.type === activityFilter,
    );
  },
);

export const getApartmentContact = createSelector(
  getApartmentDetails,
  (apartment) => apartment.contact,
);

export const getApartmentLeavings = createSelector(
  getApartmentDetails,
  (apartment) => {
    if (!apartment) return [];

    return apartment.leavings;
  },
);

export const getApartmentCheckinKeys = createSelector(
  getApartmentCheckinDetails,
  (apartment) => {
    if (!apartment) return [];

    return apartment.keys;
  },
);

export const getApartmentKeys = createSelector(
  getEntities,
  getApartmentDetails,
  (entities, apartment) => {
    const keys = denormalize(
      apartment.keys.map((key) => key.id),
      [apartmentKey],
      entities,
    );

    return keys.map((key) => new ApartmentKey(key));
  },
);

export const getFirstLeaving = createSelector(
  getApartmentLeavings,
  (leavings) => {
    return leavings
      .filter((it) => it.signatureDate)
      .sort((a: any, b: any) => {
        return a.startDate - b.startDate;
      })[0];
  },
);

export const getApartmentLeavingsByCategory = createSelector(
  getApartmentLeavings,
  (leavings) => {
    const now = moment(Date.now());
    const sortByChronologicalOrder = (leavingA, leavingB) =>
      leavingA.startDate < leavingB.startDate ? -1 : 1;
    const sortByAntiChronologicalOrder = (leavingA, leavingB) =>
      leavingA.startDate < leavingB.startDate ? 1 : -1;

    return {
      current: filterCurrentLeaving(leavings),
      signing: leavings
        .filter((leaving) => leaving.status === ENUM.LEAVING_STATUS.INIT)
        .sort(sortByChronologicalOrder),
      upcoming: leavings
        .filter(
          (leaving) =>
            moment(leaving.startDate).isAfter(now, 'day') &&
            leaving.status === ENUM.LEAVING_STATUS.SIGNED,
        )
        .sort(sortByChronologicalOrder),
      past: leavings
        .filter(
          (leaving) =>
            moment(leaving.endDate).isSameOrBefore(now, 'day') &&
            leaving.status === ENUM.LEAVING_STATUS.SIGNED,
        )
        .sort(sortByAntiChronologicalOrder),
      expired: leavings
        .filter((leaving) => leaving.status === ENUM.LEAVING_STATUS.EXPIRED)
        .sort(sortByAntiChronologicalOrder),
      canceled: leavings
        .filter((leaving) => leaving.status === ENUM.LEAVING_STATUS.CANCELED)
        .sort(sortByAntiChronologicalOrder),
    };
  },
);

export const getApartmentCurrentLeaving = createSelector(
  getApartmentLeavings,
  filterCurrentLeaving,
);

export const getApartmentLastLeaving = createSelector(
  getApartmentLeavingsByCategory,
  filterLastCurrentLeaving,
);

export const getLeavingChild = (leavingId: number) =>
  createSelector(getApartmentLeavings, (leavings) => {
    return leavings.filter(
      (leaving) =>
        leaving.parentId === leavingId &&
        leaving.status !== ENUM.LEAVING_STATUS.ARCHIVED,
    )[0];
  });

export const getLeavingAncestors = (leavingId: number) =>
  createSelector(
    getLeavings,
    (leavingsObj: { [id: number]: Leaving }): Array<Leaving> => {
      const leaving = leavingsObj[leavingId];
      const ancestor = [];
      let parentId = leaving.parentId;

      while (parentId) {
        ancestor.push(new Leaving(leavingsObj[parentId]));
        parentId = ancestor[ancestor.length - 1].parentId;
      }

      return ancestor;
    },
  );

function filterCurrentLeaving(leavings) {
  const now = moment(Date.now());

  return (
    leavings.filter(
      (leaving) =>
        moment(leaving.startDate).isSameOrBefore(now, 'day') &&
        leaving.endDate.isAfter(now, 'day') &&
        leaving.status === ENUM.LEAVING_STATUS.SIGNED,
    )[0] || null
  );
}

function filterLastCurrentLeaving(leavings) {
  return leavings.current || leavings.upcoming[0] || leavings.past[0];
}

export const getPendingLeavings = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentsState) => {
    return denormalize(
      apartmentsState.apartmentsPendingLeavings,
      [leaving],
      entities,
    ).map((leaving) => new Leaving(leaving));
  },
);

export const getBookings = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentsState) => {
    return denormalize(
      apartmentsState.apartmentsBookings,
      [booking],
      entities,
    ).map((booking) => new Booking(booking));
  },
);

export const getCleanings = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentsState) => {
    return denormalize(
      apartmentsState.apartmentsCleanings,
      [cleaningContract],
      entities,
    ).map((cleaning) => new CleaningContract(cleaning));
  },
);

export const getBookedBookings = createSelector(
  getBookings,
  (apartmentBookings) => {
    return apartmentBookings.filter(
      (booking: Booking) => booking.status === ENUM.BOOKING_STATUS.BOOKED,
    );
  },
);

export const getBookingsUsingApartmentKey = (apartmentKeyId: number) =>
  createSelector(getBookedBookings, (bookings) => {
    return bookings.reduce((acc, booking) => {
      return booking.apartmentKey && booking.apartmentKey.id === apartmentKeyId
        ? [...acc, booking]
        : acc;
    }, []);
  });

export const getFocusedLeavingCheckinId = createSelector(
  getEntities,
  getApartments,
  (entities, { focusedLeavingCheckinId }) => {
    if (!focusedLeavingCheckinId) return null;

    const focusedLeaving = denormalize(
      focusedLeavingCheckinId,
      leaving,
      entities,
    );

    return focusedLeaving && new Leaving(focusedLeaving);
  },
);

export const getFocusedLeavingCheckoutId = createSelector(
  getEntities,
  getApartments,
  (entities, { focusedLeavingCheckoutId }) => {
    if (!focusedLeavingCheckoutId) return null;

    const focusedLeaving = denormalize(
      focusedLeavingCheckoutId,
      leaving,
      entities,
    );

    return focusedLeaving && new Leaving(focusedLeaving);
  },
);

export const getLeavingById = (leavingId: number) =>
  createSelector(getEntities, (entities) => {
    if (!leavingId) return null;

    const foundLeaving = denormalize(leavingId, leaving, entities);

    return foundLeaving && new Leaving(foundLeaving);
  });

export const getApartmentKeysByBookingId = (bookingId: number) =>
  createSelector(
    getEntities,
    getBookingFromCheckinCheckout(bookingId),
    (entities, selectedBooking) => {
      const apartmentdata = denormalize(
        selectedBooking.apartment.id,
        apartment,
        entities,
      );

      return (apartmentdata.keys || []).map(
        (apartmentKey) => new ApartmentKey(apartmentKey),
      );
    },
  );

export const getApartmentKeysByApartmentId = (apartmentId: number) =>
  createSelector(getEntities, (entities) => {
    const apartmentdata = denormalize(apartmentId, apartment, entities);

    return (apartmentdata.keys || []).map(
      (apartmentKey) => new ApartmentKey(apartmentKey),
    );
  });

export const getAgencyIdFromLeaving = (leaving: Leaving) => {
  return leaving && leaving.apartment && leaving.apartment.agencyId;
};

export const getGuestKeySetup = (leaving: Leaving): KeySetup => {
  return (
    leaving &&
    leaving.keySetups &&
    leaving.keySetups.find((keySetup) => {
      return keySetup.denomination === ENUM.APARTMENT_KEY_DENOMINATION.GUEST;
    })
  );
};

export const getCleaningKeySetup = (leaving: Leaving) => {
  return (
    leaving &&
    leaving.keySetups &&
    leaving.keySetups.find((keySetup) => {
      return keySetup.denomination === ENUM.APARTMENT_KEY_DENOMINATION.CLEANING;
    })
  );
};

export const getAgencyKeySetup = (leaving: Leaving) => {
  return (
    leaving &&
    leaving.keySetups &&
    leaving.keySetups.find((keySetup) => {
      return keySetup.denomination === ENUM.APARTMENT_KEY_DENOMINATION.AGENCY;
    })
  );
};

export const getUnSetupLeavingLastCheck = createSelector(
  getFocusedLeavingCheckoutId,
  getEntities,
  (checkoutLeaving, entities) => {
    const leavingData = denormalize(checkoutLeaving.id, leaving, entities);

    const lastCheckData = denormalize(
      leavingData.lastCheckId,
      lastCheck,
      entities,
    );

    return lastCheckData && new LastCheck(lastCheckData);
  },
);

export const getLeavingsToPublishList = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentState) => {
    return denormalize(
      apartmentState.leavingsToPublish,
      [leaving],
      entities,
    ).map((leaving) => new Leaving(leaving));
  },
);

export const getApartmentKeyList = createSelector(
  getEntities,
  getApartments,
  (entities, apartmentState) => {
    return denormalize(
      apartmentState.leavingsWithKeys,
      [leaving],
      entities,
    ).map((leaving) => new Leaving(leaving));
  },
);
