import moment from 'moment-timezone';

export default class ProspectStatusHistory {
  public id: number;
  public prospectId: number;
  public status: string;
  public previous: string;
  public createdAt: moment.Moment;
  public updatedAt: moment.Moment;

  constructor(
    data: Partial<
      | ProspectStatusHistory
      | {
          createdAt?: string;
          updatedAt?: string;
        }
    >,
  ) {
    Object.assign(this, data);

    this.createdAt = data.createdAt && moment(data.createdAt);
    this.updatedAt = data.updatedAt && moment(data.updatedAt);
  }
}
