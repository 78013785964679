import Common from '@redux/models/Common';

import {
  SET_TOAST,
  START_LOADING,
  END_LOADING,
  END_INITIALIZATION,
  SET_AGENCY_FILTER,
  SET_ACTIVITY_FILTER,
} from '@redux/common/actions';
import { LOGOUT, RESET } from '@redux/me/actions';

const initialState = new Common();

export default function commonReducer(state = initialState, action: any) {
  const { type } = action;

  switch (type) {
    case SET_TOAST:
      return {
        ...state,
        toast: action.toast,
      };
    case START_LOADING:
      return {
        ...state,
        loading: true,
      };
    case END_LOADING:
      return {
        ...state,
        loading: false,
      };
    case END_INITIALIZATION:
      return {
        ...state,
        initialized: true,
      };
    case SET_AGENCY_FILTER:
      return {
        ...state,
        agencyFilter: action.agencyId,
      };
    case SET_ACTIVITY_FILTER:
      return {
        ...state,
        activityFilter: action.activityType,
      };
    case LOGOUT:
    case RESET:
      return initialState;
    default:
      return state;
  }
}
