export default class CityStatistic {
  public count: number;
  public id: number;
  public name: string;

  constructor(data: any) {
    this.count = data.count || 0;
    this.id = data.id || undefined;
    this.name = data.name || '';
  }
}
