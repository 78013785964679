import React, { MouseEventHandler, PureComponent } from 'react';

import { faFileAlt } from '@fortawesome/pro-regular-svg-icons';
import { AIcon } from '@smartrenting/smartomic';

import CONFIG from '@config';

import './GenericDownloadReport.scss';

type Props = {
  documentId?: number;
  token: string;
  filename?: string;
};

export default class GenericDownloadReport extends PureComponent<Props> {
  static defaultProps = {
    documentId: null,
    filename: null,
  };

  render() {
    const { documentId } = this.props;

    return (
      <AIcon
        className={`GenericDownloadReport ${
          !documentId ? 'GenericDownloadReport--disabled' : ''
        }`}
        icon={faFileAlt}
        height="1.2rem"
        onClick={
          ((documentId
            ? this.handleDownloadReport
            : this.handleStopEvent) as any) as MouseEventHandler<HTMLDivElement>
        }
      />
    );
  }

  handleStopEvent = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  handleDownloadReport = (event: MouseEvent) => {
    const { token, documentId, filename } = this.props;
    const id = documentId;

    this.handleStopEvent(event);

    const fileName = filename || `${id}.pdf`;

    return fetch(`${CONFIG.API_V2_URL}/documents/${id}`, {
      headers: new Headers({ authorization: token }),
    })
      .then((result) => result.blob())
      .then((file: Blob) => {
        const a = document.createElement('a');

        a.href = URL.createObjectURL(file);
        a.download = fileName;

        a.click();
      });
  };
}
