import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { middleware as packMiddleware } from 'redux-pack';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import promiseMiddleware from 'redux-promise';
import thunkMiddleware from 'redux-thunk';

import apartmentsReducer from '@redux/apartments/reducer';
import bookingsReducer from '@redux/bookings/reducer';
import citiesReducer from '@redux/cities/reducer';
import cleaningContractsReducer from '@redux/cleaningContracts/reducer';
import commonReducer from '@redux/common/reducer';
import referencesReducer from '@redux/config/reducer';
import contractorsReducer from '@redux/contractors/reducer';
import entitiesReducer from '@redux/entities/reducer';
import hostRentRowsReducer from '@redux/hostRentRows/reducer';
import hostRentSepasReducer from '@redux/hostRentSepas/reducer';
import hostRentsReducer from '@redux/hostRents/reducer';
import meReducer from '@redux/me/reducer';
import apiMiddleware from '@redux/middlewares/apiMiddleware';
import websocketMiddleware from '@redux/middlewares/webSocketMiddleware';
import neighborhoodsReducer from '@redux/neighborhoods/reducer';
import notificationReducer from '@redux/notifications/reducer';
import photoContractsReducer from '@redux/photoContracts/reducer';
import prospectsReducer from '@redux/prospects/reducer';
import usersReducer from '@redux/users/reducer';

const userPersistConfig = {
  key: 'me',
  storage,
};

const reducer = combineReducers({
  apartments: apartmentsReducer,
  bookings: bookingsReducer,
  common: commonReducer,
  contractors: contractorsReducer,
  cities: citiesReducer,
  cleaningContracts: cleaningContractsReducer,
  hostRents: hostRentsReducer,
  hostRentRows: hostRentRowsReducer,
  hostRentSepas: hostRentSepasReducer,
  photoContracts: photoContractsReducer,
  me: persistReducer(userPersistConfig, meReducer),
  prospects: prospectsReducer,
  references: referencesReducer,
  neighborhoods: neighborhoodsReducer,
  users: usersReducer,
  entities: entitiesReducer,
  notifications: notificationReducer,
});

export default function createStores() {
  const store = createStore(
    reducer,
    composeWithDevTools(
      applyMiddleware(
        apiMiddleware,
        websocketMiddleware,
        packMiddleware,
        thunkMiddleware,
        promiseMiddleware,
      ),
    ),
  );
  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
}
