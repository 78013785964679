import React, { Component } from 'react';

import { ASeparator } from '@smartrenting/smartomic';

import './GenericBox.scss';

type Props = {
  title: JSX.Element | string;
  children: JSX.Element | JSX.Element[];
  className?: string;
  secondaryMode?: boolean;
  onSwitchMode?: Function;
  disabled?: boolean;
  withSeparator?: boolean;
};

class GenericBox extends Component<Props> {
  static defaultProps = {
    className: null,
    secondaryMode: null,
    onSwitchMode: null,
    disabled: false,
    withSeparator: true,
  };

  render() {
    const {
      title,
      secondaryMode,
      children,
      className: propClass,
      withSeparator,
    } = this.props;

    const className = [
      'GenericBox',
      secondaryMode ? 'GenericBox--second' : '',
      propClass,
    ].join(' ');

    return (
      <div className={className}>
        <div className="GenericBox__header">
          <div className="GenericBox__container">
            <div className="GenericBox__container__title">{title}</div>
            {this.renderIconHeader()}
          </div>
          {withSeparator && <ASeparator />}
        </div>

        <div className="GenericBox__content">{children}</div>
      </div>
    );
  }

  renderIconHeader = () => {
    const { onSwitchMode, secondaryMode, disabled } = this.props;

    if (!onSwitchMode) return null;

    const classNames = ['GenericBox__container__action'];

    if (!disabled) {
      classNames.push(
        secondaryMode
          ? 'GenericBox__container__action GenericBox__container__action--close'
          : 'GenericBox__container__action GenericBox__container__action--edit',
      );
    }

    return (
      <div
        className={classNames.join(' ')}
        onClick={this.handleSwitchMode.bind(null, !secondaryMode)}
      />
    );
  };

  handleSwitchMode = (closed: boolean) => {
    const { onSwitchMode, disabled } = this.props;

    if (onSwitchMode && !disabled) onSwitchMode(closed);
  };
}

export default GenericBox;
