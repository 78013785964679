import * as React from 'react';
import { Modal } from 'react-bootstrap';

type Props = {
  title?: any;
  children?: any;
  footer?: any;
  scrollable?: boolean;
  column?: string;
  onExited?: Function;
  isOpen?: boolean;
  closeButton?: boolean;
  className?: string;
};

type State = {
  show: boolean;
};

class GenericModal extends React.Component<Props, State> {
  static defaultProps = {
    column: '1',
    className: '',
    scrollable: false,
    onExited: () => {},
    isOpen: null,
    closeButton: true,
  };

  state = {
    show: false,
  };

  render() {
    const {
      title,
      children,
      footer,
      column,
      isOpen,
      closeButton,
      scrollable,
      className,
    } = this.props;
    const { show } = this.state;

    const classNameColumn = column ? `modal--column-${column}` : '';

    return (
      <Modal
        scrollable={scrollable}
        show={show || isOpen}
        className={`${className} ${classNameColumn}`}
        onHide={this.handleClose}
        onExited={this.handleExited}
      >
        {title && (
          <Modal.Header closeButton={closeButton}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>{children}</Modal.Body>
        {footer && <Modal.Footer>{footer}</Modal.Footer>}
      </Modal>
    );
  }

  handleClose = () => {
    this.setState({ show: false });
    this.handleExited();
  };

  handleExited = () => {
    const { onExited } = this.props;

    if (onExited) onExited();
  };

  handleShow = () => {
    this.setState({ show: true });
  };
}

export default GenericModal;
