import React, { Component } from 'react';
import { Button, Carousel } from 'react-bootstrap';

import { Document } from 'src/types/global';

import {
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faTrashAlt,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GenericImage, GenericModal } from '@components';

import './GenericCarousel.scss';

type Props = {
  pictures: Array<Object>;
  onRemove?: Function;
};

type State = {
  direction: any;
  index: number;
  isModalOpen: boolean;
  documentId?: number;
};

class GenericCarousel extends Component<Props, State> {
  modalRef: any;

  static defaultProps = {
    onRemove: null,
  };

  constructor(props: Props) {
    super(props);
    this.modalRef = React.createRef();
  }

  state = {
    direction: null,
    index: 0,
    isModalOpen: false,
    documentId: null,
  };

  render() {
    const { pictures } = this.props;
    const { direction, index, isModalOpen } = this.state;

    if (!pictures || !pictures.length) return null;

    return (
      <GenericModal
        isOpen={isModalOpen}
        column="carousel"
        onExited={this.handleHide}
      >
        <Carousel
          className="GenericCarousel"
          activeIndex={index}
          {...{ direction }}
          onSelect={this.handleSelect}
          interval={null}
          nextIcon={
            <span className="GenericCarousel__arrows">
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </span>
          }
          prevIcon={
            <span className="GenericCarousel__arrows">
              <FontAwesomeIcon icon={faChevronCircleLeft} />
            </span>
          }
        >
          {pictures.map(this.renderCarouselItem)}
        </Carousel>
      </GenericModal>
    );
  }

  renderCarouselItem = (picture: Document) => {
    return (
      <Carousel.Item key={`item-${picture.id}`}>
        <div className="GenericCarousel__container">
          <GenericImage id={picture.id} mimeType={picture.mimeType} />
          {this.renderDelete(picture.id)}
        </div>
      </Carousel.Item>
    );
  };

  renderDelete = (id: number) => {
    const { onRemove } = this.props;
    const { documentId } = this.state;

    if (!onRemove) return null;

    return (
      <React.Fragment>
        {documentId && (
          <div className="GenericCarousel__container__confirm">
            <span className="GenericCarousel__container__confirm__buttons">
              <Button
                form="userForm"
                type="submit"
                className="mr-2"
                variant="primary"
                onClick={this.handleDeleteConfirm}
              >
                <FontAwesomeIcon icon={faCheckCircle} />
              </Button>
              <Button
                form="userForm"
                type="submit"
                variant="secondary"
                onClick={this.handleCancel}
              >
                <FontAwesomeIcon icon={faUndoAlt} />
              </Button>
            </span>
          </div>
        )}
        {!documentId && (
          <span
            className="GenericCarousel__container__trash"
            onClick={this.handleDelete.bind(this, id)}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </span>
        )}
      </React.Fragment>
    );
  };

  handleSelect = (selectedIndex: number, e: any) => {
    this.setState({
      index: selectedIndex,
      direction: e.direction,
      documentId: null,
    });
  };

  handleShow = (id: number) => {
    const { pictures } = this.props;

    this.setState({
      isModalOpen: true,
      index: pictures.findIndex((picture: any) => picture.id === id),
    });
  };

  handleHide = () => {
    this.setState({ documentId: null, isModalOpen: false });
  };

  handleDelete = (id: number) => {
    this.setState({ documentId: id });
  };

  handleCancel = () => {
    this.setState({ documentId: null });
  };

  handleDeleteConfirm = () => {
    const { onRemove, pictures } = this.props;
    const { documentId } = this.state;

    let index = pictures.findIndex((picture: any) => picture.id === documentId);

    if (index === pictures.length - 1) index = 0;

    this.setState({ index, documentId: null });

    if (onRemove) onRemove(pictures[index]);
    if (pictures.length === 1) this.handleHide();
  };
}

export default GenericCarousel;
