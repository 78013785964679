import { Dispatch } from 'src/types/redux';

import CONFIG from '@config';

import { Notification } from '@redux/models/Notifications';

import * as schema from '@redux/schema';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const getNotifications = (token: string) => (dispatch?: Dispatch) =>
  dispatch({
    type: FETCH_NOTIFICATIONS,
    route: `/notifications`,
    method: 'GET',
    hideLoading: true,
    token,
    url: CONFIG.API_V2_URL,
    schema: [schema.notification],
  });

export const PATCH_NOTIFICATION = 'PATCH_NOTIFICATION';
export const patchNotification = (
  id: number,
  body: { viewDate?: moment.Moment; completed?: boolean },
) => (dispatch?: Dispatch) =>
  dispatch({
    type: PATCH_NOTIFICATION,
    route: `/notifications/${id}`,
    method: 'PATCH',
    url: CONFIG.API_V2_URL,
    body,
    hideLoading: true,
    schema: schema.notification,
  });

export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const sendNotification = (body: Notification) => (dispatch?: Dispatch) =>
  dispatch({
    type: SEND_NOTIFICATION,
    route: `/notifications/send-notification`,
    method: 'POST',
    url: CONFIG.API_V2_URL,
    body,
    hideLoading: true,
    schema: schema.notification,
  });
