import Contractor from './Contractor';
import User from './User';

export default class ContractorEmployee {
  public id: number;
  public agencyId: number;
  public contractorId: number;
  public contractor: Contractor;
  public userId: number;
  public user: User;

  constructor(data: Partial<User> | any = {}) {
    Object.assign(this, data);

    this.contractor = data.contractor && new Contractor(data.contractor);
    this.user = data.user && new User(data.user);
  }

  getFullName() {
    return this.user.getFullName();
  }

  getShortName() {
    return this.user.getShortName();
  }
}
