import * as Sentry from '@sentry/browser';

import CURRENT_CONFIG from '@config';

import Toast from '@redux/models/Toast';

import { fetchAgencies, fetchReferences } from '@redux/config/actions';
import { fetchContractor, fetchContractors } from '@redux/contractors/actions';
import { authenticate, reset } from '@redux/me/actions';
import { getNotifications } from '@redux/notifications/actions';
import { fetchUsers } from '@redux/users/actions';

import { getMe, getMyContractor, getMyToken } from '@redux/me/selectors';

import { WEBSOCKET_CONNECT } from '@redux/middlewares/webSocketMiddleware';

export const SET_TOAST = 'SET_TOAST';
export const setToast = (toast: Toast) => ({
  type: SET_TOAST,
  toast,
});

export const START_LOADING = 'START_LOADING';
export const startLoading = () => ({
  type: START_LOADING,
});

export const END_LOADING = 'END_LOADING';
export const endLoading = () => ({
  type: END_LOADING,
});

export const SET_AGENCY_FILTER = 'SET_AGENCY_FILTER';
export const setAgencyFilter = (agencyId: number) => ({
  type: SET_AGENCY_FILTER,
  agencyId,
});

export const SET_ACTIVITY_FILTER = 'SET_ACTIVITY_FILTER';
export const setActivityFilter = (activityType?: string) => ({
  type: SET_ACTIVITY_FILTER,
  activityType,
});

export const START_INITIALIZATION = 'START_INITIALIZATION';
export const startInitialization = (isAuthenticated: boolean) => (
  dispatch?: any,
  getState?: Function,
) => {
  if (isAuthenticated) {
    return dispatch(authenticate()).then(async (res) => {
      if (res.error) return dispatch(reset());

      const user = getMe(getState());

      dispatch({ type: WEBSOCKET_CONNECT, userId: user.id });

      if (user?.isContactOnly()) {
        const token = getMyToken(getState());
        setTimeout(() => {
          window.location.replace(CURRENT_CONFIG.FO_URL + `?token=${token}`);
        }, 100);
        dispatch(reset());

        return;
      }

      if (user?.isContractor()) {
        await dispatch(
          fetchContractor(user.contractorEmployee.contractorId),
        ).then(() => {
          const userContractor = getMyContractor(getState());
          if (
            user.isSimpleContractor() &&
            userContractor.isCleaningContractor()
          ) {
            setTimeout(() => {
              window.location.replace(CURRENT_CONFIG.CLEANING_URL);
            }, 100);
            dispatch(reset());

            return;
          }
        });
      }

      Sentry.configureScope((scope) => {
        scope.setUser({
          id: String(user.id),
          email: user.email,
          username: `${user.firstname} ${user.lastname}`,
        });
      });

      const promiseReferences = dispatch(fetchReferences());
      const promiseNotifications = dispatch(getNotifications(user.token));

      let promiseAgencies = null;
      let promiseContractors = null;
      let promiseUsers = null;

      if (!user.isContractor()) {
        promiseAgencies = dispatch(fetchAgencies());
        promiseUsers = dispatch(fetchUsers());
        promiseContractors = dispatch(fetchContractors(true));
      }

      if (user.isContractor()) {
        promiseUsers = dispatch(fetchUsers(user.getContractorId()));
      }

      return Promise.all([
        promiseReferences,
        promiseAgencies,
        promiseContractors,
        promiseUsers,
        promiseNotifications,
      ]).then(() => dispatch(endInitialization()));
    });
  }

  return dispatch(endInitialization());
};

export const END_INITIALIZATION = 'END_INITIALIZATION';
export const endInitialization = () => ({
  type: END_INITIALIZATION,
});
