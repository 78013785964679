import React, { Component } from 'react';
import Dropzone, { FileRejection } from 'react-dropzone';
import { withTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';

import Toast from '@redux/models/Toast';

import { setToast } from '@redux/common/actions';

import './GenericDropzone.scss';

export enum AcceptedFileType {
  ZIP = 'application/zip',
  IMAGE = 'image/*',
  PDF = 'application/pdf',
  DOC = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  VIDEO = 'video/*',
}

const typeExtension = {
  'application/zip': 'zip',
  'image/*': 'image (png, jpeg ...)',
  'application/pdf': 'pdf',
  'application/msword': 'doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'docx',
  'video/*': 'video (mov, mp4 ...)',
};

interface Props extends PropsFromRedux {
  t?: Function;
  defaultText: string;
  dropText: string;
  unsupportedText: string;
  onDrop: Function;
  acceptedFiles?: AcceptedFileType[];
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => ({
  setToast: (toast) => dispatch(setToast(toast)),
});

class GenericDropzone extends Component<Props> {
  render() {
    const {
      defaultText,
      dropText,
      unsupportedText,
      acceptedFiles,
    } = this.props;

    return (
      <Dropzone onDrop={this.handleDrop} accept={acceptedFiles || null}>
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
        }) => {
          const classNames = [
            'dropzone',
            isDragActive ? 'dropzone--active' : '',
          ].join(' ');

          return (
            <div {...getRootProps()} className={classNames}>
              <input {...getInputProps()} />
              <div className="d-flex">
                <span className="d-inline-block global-title-file-grey mr-1" />
                <span>{isDragAccept ? dropText : defaultText}</span>
              </div>
              {isDragReject && <div>{unsupportedText}</div>}
            </div>
          );
        }}
      </Dropzone>
    );
  }

  handleDrop = (Files: Array<File>, fileRejections: Array<FileRejection>) => {
    const { onDrop, setToast, t, acceptedFiles } = this.props;

    if (fileRejections.length > 0) {
      const types = acceptedFiles.map((file) => typeExtension[file]).join(', ');
      const toast = new Toast({
        variant: 'danger',
        text: t('common.errors.uploadedFileUnsupported', { types }),
        autoClose: 7000,
      });
      setToast(toast);
    }
    if (Files.length > 0) onDrop(Files);
  };
}

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const NotConnectedGenericDropzone = GenericDropzone;

export default withTranslation()(connector(GenericDropzone));
