import CONFIG from '@config';

import * as schema from '@redux/schema';

export const FETCH_USERS = 'FETCH_USERS';
export const fetchUsers = (contractorId?: number) => ({
  type: FETCH_USERS,
  route: '/users',
  method: 'GET',
  query: {
    contractorId: contractorId || '',
  },
  hideLoading: true,
  schema: [schema.user],
  url: CONFIG.API_V2_URL,
});

export const PATCH_USER = 'PATCH_USER';
export const patchUser = (id: number, userModifications: Object) => ({
  type: PATCH_USER,
  route: `/users/${id}`,
  method: 'PATCH',
  body: userModifications,
  schema: schema.user,
  url: CONFIG.API_V2_URL,
});

export const POST_USER = 'POST_USER';
export const postUser = (userModifications: Object) => ({
  type: POST_USER,
  route: `/users`,
  method: 'POST',
  body: userModifications,
  schema: schema.user,
  url: CONFIG.API_V2_URL,
});

export const DELETE_USER = 'DELETE_USER';
export const deleteUser = (userId: number) => ({
  type: DELETE_USER,
  route: `/users/${userId}`,
  method: 'DELETE',
  schema: schema.user,
  url: CONFIG.API_V2_URL,
});

export const FETCH_POLICIES = 'FETCH_POLICIES';
export const fetchPolicies = () => ({
  type: FETCH_POLICIES,
  route: `/users/policies`,
  method: 'GET',
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});
