import moment from 'moment-timezone';

import Apartment from './Apartment';
import Contractor from './Contractor';

export default class PhotoContract {
  public id: number;
  public createdAt: moment.Moment;
  public updatedAt: moment.Moment;
  public scheduledOn: moment.Moment;
  public date: moment.Moment;
  public status: string;
  public contactName: string;
  public contactNumber: string;
  public contractorId: number;
  public type: string;
  public directive = '';
  public observation = '';
  public contractor: Contractor;
  public apartment: Apartment;

  constructor(
    data: Partial<
      | PhotoContract
      | {
          createdAt?: string;
          updatedAt?: string;
          scheduledOn?: string;
          date?: string;
        }
    > = {},
  ) {
    Object.assign(this, data);

    const { contractor, apartment } = data as Partial<PhotoContract>;

    this.createdAt = data.createdAt ? moment(data.createdAt) : undefined;
    this.updatedAt = data.updatedAt ? moment(data.updatedAt) : undefined;
    this.scheduledOn = data.scheduledOn ? moment(data.scheduledOn) : undefined;
    this.date = data.date ? moment(data.date) : undefined;
    this.contractor = contractor ? new Contractor(contractor) : undefined;
    this.apartment = apartment ? new Apartment(apartment) : undefined;
  }
}
