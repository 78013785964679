import { createSelector } from 'reselect';
import { State } from 'src/types/redux';

import Common from '@redux/models/Common';

import { getMe } from '@redux/me/selectors';

export const getCommon = (state: State): Common => state.common;

export const isLoading = createSelector(getCommon, (common) => common.loading);

export const isInitialized = createSelector(
  getCommon,
  (common) => common.initialized,
);

export const getToast = createSelector(getCommon, (common) => common.toast);

export const getAgencyFilter = createSelector(
  getCommon,
  getMe,
  (common, me): number => common.agencyFilter || (me && me.getAgencyId()),
);

export const getActivityFilter = createSelector(
  getCommon,
  (common) => common.activityFilter,
);
