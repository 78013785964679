import GenericAutoSuggestComponent from './GenericAutoSuggest/GenericAutoSuggest';
import GenericBoxComponent from './GenericBox/GenericBox';
import GenericCarouselComponent from './GenericCarousel/GenericCarousel';
import GenericCommentZoneComponent from './GenericCommentZone/GenericCommentZone';
import GenericConfirmationModalComponent from './GenericConfirmationModal/GenericConfirmationModal';
import GenericCopyToClipboardComponent from './GenericCopyToClipboard/GenericCopyToClipboard';
import GenericCustomSelectComponent from './GenericCustomSelect/GenericCustomSelect';
import GenericDownloadReportComponent from './GenericDownloadReport/GenericDownloadReport';
import GenericDropdownSelectorComponent from './GenericDropdownSelector/GenericDropdownSelector';
import GenericDropzoneComponent from './GenericDropzone/GenericDropzone';
import GenericCheckboxComponent from './GenericFormComponents/GenericCheckbox/GenericCheckbox';
import GenericDatePickerComponent from './GenericFormComponents/GenericDatePicker/GenericDatePicker';
import GenericDateTimePickerComponent from './GenericFormComponents/GenericDateTimePicker/GenericDateTimePicker';
import GenericFormGroupComponent from './GenericFormComponents/GenericFormGroup/GenericFormGroup';
import GenericInputComponent from './GenericFormComponents/GenericInput/GenericInput';
import GenericListComponent from './GenericFormComponents/GenericList/GenericList';
import GenericMonthNavPickerComponent from './GenericFormComponents/GenericMonthNavPicker/GenericMonthNavPicker';
import GenericMonthPickerComponent from './GenericFormComponents/GenericMonthPicker/GenericMonthPicker';
import GenericRadioGroupComponent from './GenericFormComponents/GenericRadioGroup/GenericRadioGroup';
import GenericSelectComponent from './GenericFormComponents/GenericSelect/GenericSelect';
import GenericTextareaComponent from './GenericFormComponents/GenericTextarea/GenericTextarea';
import GenericTimePickerComponent from './GenericFormComponents/GenericTimePicker/GenericTimePicker';
import GenericToggleComponent from './GenericFormComponents/GenericToggle/GenericToggle';
import GenericHeaderBoxComponent from './GenericHeaderBox/GenericHeaderBox';
import GenericImageComponent from './GenericImage/GenericImage';
import GenericInfoRowComponent from './GenericInfoRow/GenericInfoRow';
import GenericInitialComponent from './GenericInitial/GenericInitial';
import GenericLinkComponent from './GenericLink/GenericLink';
import GenericModalComponent from './GenericModal/GenericModal';
import GenericPopoverComponent from './GenericPopover/GenericPopover';
import GenericTableComponent from './GenericTable/GenericTable';
import GenericTableBodyComponent from './GenericTable/components/GenericTableBody';
import GenericTableHeaderComponent from './GenericTable/components/GenericTableHeader';
import GenericTablePageComponent from './GenericTablePage/GenericTablePage';
import GenericTagComponent from './GenericTag/GenericTag';
import GenericUploadModalComponent from './GenericUploadModal/GenericUploadModal';
import LoaderComponent from './Loader/Loader';
import SuspenseComponentComponent from './Suspense/SuspenseComponent';
import SuspenseRouteFallbackComponent from './SuspenseRouteFallback/SuspenseRouteFallback';

export const GenericBox = GenericBoxComponent;
export const GenericCarousel = GenericCarouselComponent;
export const GenericCheckbox = GenericCheckboxComponent;
export const GenericCommentZone = GenericCommentZoneComponent;
export const GenericConfirmationModal = GenericConfirmationModalComponent;
export const GenericDatePicker = GenericDatePickerComponent;
export const GenericMonthPicker = GenericMonthPickerComponent;
export const GenericMonthNavPicker = GenericMonthNavPickerComponent;
export const GenericTimePicker = GenericTimePickerComponent;
export const GenericDateTimePicker = GenericDateTimePickerComponent;
export const GenericDropzone = GenericDropzoneComponent;
export const GenericFormGroup = GenericFormGroupComponent;
export const GenericHeaderBox = GenericHeaderBoxComponent;
export const GenericImage = GenericImageComponent;
export const GenericInfoRow = GenericInfoRowComponent;
export const GenericInitial = GenericInitialComponent;
export const GenericInput = GenericInputComponent;
export const GenericLink = GenericLinkComponent;
export const GenericList = GenericListComponent;
export const GenericModal = GenericModalComponent;
export const GenericSelect = GenericSelectComponent;
export const GenericTextarea = GenericTextareaComponent;
export const GenericTablePage = GenericTablePageComponent;
export const GenericTable = GenericTableComponent;
export const GenericTableBody = GenericTableBodyComponent;
export const GenericTableHeader = GenericTableHeaderComponent;
export const GenericRadioGroup = GenericRadioGroupComponent;
export const GenericUploadModal = GenericUploadModalComponent;
export const GenericCopyToClipboard = GenericCopyToClipboardComponent;
export const GenericAutoSuggest = GenericAutoSuggestComponent;
export const GenericPopover = GenericPopoverComponent;
export const GenericTag = GenericTagComponent;
export const Loader = LoaderComponent;
export const GenericToggle = GenericToggleComponent;
export const GenericCustomSelect = GenericCustomSelectComponent;
export const GenericDropdownSelector = GenericDropdownSelectorComponent;
export const GenericDownloadReport = GenericDownloadReportComponent;
export const SuspenseComponent = SuspenseComponentComponent;
export const SuspenseRouteFallback = SuspenseRouteFallbackComponent;

export default {
  GenericBox,
  GenericCarousel,
  GenericCheckbox,
  GenericCommentZone,
  GenericConfirmationModal,
  GenericDatePicker,
  GenericMonthPicker,
  GenericMonthNavPicker,
  GenericTimePicker,
  GenericDateTimePicker,
  GenericDropzone,
  GenericFormGroup,
  GenericHeaderBox,
  GenericImage,
  GenericInfoRow,
  GenericInitial,
  GenericInput,
  GenericLink,
  GenericList,
  GenericModal,
  GenericSelect,
  GenericTextarea,
  GenericTablePage,
  GenericTable,
  GenericTableBody,
  GenericTableHeader,
  GenericRadioGroup,
  GenericUploadModal,
  GenericCopyToClipboard,
  GenericAutoSuggest,
  GenericPopover,
  GenericTag,
  Loader,
  GenericToggle,
  GenericCustomSelect,
  GenericDropdownSelector,
  GenericDownloadReport,
  SuspenseComponent,
  SuspenseRouteFallback,
};
