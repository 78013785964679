import React, { PureComponent } from 'react';

import './NavButton.scss';

/*
 * If somebody have the exact type for the ButtonHTMLProps with react
 * Feel free to enlighten me
 */
type Props = any & {
  direction: 'left' | 'right';
};

export class NavButton extends PureComponent<Props> {
  render() {
    const { direction, disabled } = this.props;

    return (
      <div className="NavButton__container">
        <button
          {...this.props}
          type="button"
          disabled={disabled}
          className={`NavButton__arrow NavButton__arrow--${direction}${
            disabled ? '--disabled' : ''
          }`}
        />
      </div>
    );
  }
}

export default NavButton;
