import moment from 'moment-timezone';

import CONFIG from '@config';

import { hostRentSepa } from '@redux/schema';

export const FETCH_HOST_RENT_SEPAS = 'FETCH_HOST_RENT_SEPAS';
export const fetchHostRentSepas = (
  startDate: moment.Moment,
  endDate: moment.Moment,
) => ({
  type: FETCH_HOST_RENT_SEPAS,
  route: '/host-rent-sepas/history',
  method: 'GET',
  query: {
    startDate: startDate.format('YYYY-MM-DD'),
    endDate: endDate.format('YYYY-MM-DD'),
  },
  schema: [hostRentSepa],
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const CANCEL_HOST_RENT_SEPA = 'CANCEL_HOST_RENT_SEPA';
export const cancelHostRentSepa = (id: number) => ({
  type: CANCEL_HOST_RENT_SEPA,
  route: `/host-rent-sepas/${id}/cancel`,
  method: 'POST',
  schema: hostRentSepa,
  hideLoading: false,
  url: CONFIG.API_V2_URL,
});

export const GENERATE_SEPA = 'GENERATE_SEPA';
export const generateSepa = (hostRentsIdsList: Array<number>) => ({
  type: GENERATE_SEPA,
  route: '/host-rent-sepas/generate',
  method: 'POST',
  body: {
    hostRentsIdsList,
  },
  url: CONFIG.API_V2_URL,
});
