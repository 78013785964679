import moment from 'moment-timezone';

export default class Commission {
  public id: number;
  public fullterm: boolean;
  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public percent: number;
  public capped: boolean;

  constructor(
    data: Partial<Commission | { startDate?: string; endDate?: string }>,
  ) {
    Object.assign(this, data);

    this.endDate = data.endDate ? moment(data.endDate) : null;
    this.startDate = data.startDate ? moment(data.startDate) : null;
  }
}
