import Contractor from './Contractor';

export default class Contractors {
  public contractorDetails: Contractor;
  public contractors: Contractor[];
  public contractorsListTextFilter: string;

  constructor(data: any) {
    this.contractorDetails = data.contractorDetails || null;
    this.contractors = data.contractors || [];
    this.contractorsListTextFilter = data.contractorsListTextFilter || '';
  }
}
