import { Document } from 'src/types/global';

export default class LastCheck {
  public id: number;
  public comment: string;
  public type: string;
  public level: string;
  public documents: Array<Document>;

  constructor(data: any) {
    this.id = data.id;
    this.comment = data.comment || '';
    this.type = data.type || '';
    this.level = data.level || '';
    this.documents = data.documents || [];
  }
}
