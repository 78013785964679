import React, { Component } from 'react';
import Datetime from 'react-datetime';

import moment from 'moment-timezone';
import 'moment/locale/fr';

import { GenericFormGroup } from '@components';

type Props = {
  value?: string | moment.Moment;
  label?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  onChange: Function;
  controlId?: string;
  disabled?: boolean;
  viewDate?: moment.Moment;
  min?: string | moment.Moment;
  max?: string | moment.Moment;
  prepend?: string;
  append?: string;
};

type State = {
  valueModifications: moment.Moment;
};

class GenericDateTimePicker extends Component<Props, State> {
  static defaultProps = {
    controlId: null,
    viewDate: moment(),
    className: undefined,
    min: '',
    max: '',
    prepend: '',
    append: '',
    disabled: false,
  };

  state = {
    valueModifications: undefined,
  };

  render() {
    const {
      label,
      className,
      required,
      controlId,
      prepend,
      append,
    } = this.props;

    return (
      <GenericFormGroup
        label={label}
        className={className}
        required={required}
        controlId={controlId}
        prepend={prepend}
        append={append}
      >
        {this.renderDateTimePicker()}
      </GenericFormGroup>
    );
  }

  renderDateTimePicker = () => {
    const {
      disabled,
      value,
      viewDate,
      label,
      placeholder,
      required,
    } = this.props;
    const { valueModifications } = this.state;

    return (
      <Datetime
        locale="fr"
        inputProps={{ placeholder: placeholder || label, required, disabled }}
        value={valueModifications || value}
        {...{ viewDate }}
        isValidDate={this.onHandleValid}
        onChange={this.handleChange as any}
        onClose={this.handleClose}
      />
    );
  };

  handleChange = (date: moment.Moment) => {
    this.setState({ valueModifications: date });
  };

  handleClose = () => {
    const { onChange } = this.props;
    const { valueModifications } = this.state;

    if (
      moment.isMoment(valueModifications) &&
      moment(valueModifications).isValid()
    )
      onChange(valueModifications);
  };

  onHandleValid = (current: moment.Moment): boolean => {
    const { min, max } = this.props;

    let valid = true;

    if (min) valid = current.isSameOrAfter(min);

    if (valid && max) valid = current.isSameOrBefore(max);

    return valid;
  };
}

export default GenericDateTimePicker;
