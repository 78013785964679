import moment from 'moment-timezone';

import ENUM from '@enum';

import Apartment from './Apartment';
import Booking from './Booking';
import CleaningObservation from './CleaningObservation';
import Contractor from './Contractor';
import Tag from './Tag';
import TranslatedText from './TranslatedText';
import User from './User';

export default class CleaningContract {
  public id: number;

  public apartment: Apartment;
  public apartmentId: number;

  public booking: Booking;
  public bookingId: number;

  public contractor: Contractor;
  public contractorId: number;
  public userId: number;
  public cleaningDocumentId: number;

  public completedBy: User;

  public directive = '';
  public observation: TranslatedText;
  public cleaningType: string;
  public status: string;
  public beds: Record<string, any> = {};
  public tags: Tag[] = [];

  public startDate: moment.Moment;
  public endDate: moment.Moment;
  public startedAt: moment.Moment;
  public completedAt: moment.Moment;
  public createdAt: moment.Moment;

  public optimizedEndDate: moment.Moment;
  public optimizedStartDate: moment.Moment;

  public observations: CleaningObservation[] = [];

  public bookingOnDay: boolean;
  public hostReturn: boolean;
  public isDateReal: boolean;
  public isSheetReady: boolean;

  public nextGuestExpectedCheckinTime: string;

  constructor(
    data: Partial<
      | CleaningContract
      | {
          startDate?: string;
          endDate?: string;
          startedAt?: string;
          completedAt?: string;
          createdAt?: string;
          optimizedEndDate?: string;
          optimizedStartDate?: string;
          nextGuestExpectedCheckinTime?: string;
        }
    >,
  ) {
    Object.assign(this, data);

    const {
      apartment,
      contractor,
      booking,
      completedBy,
      observation,
      tags,
      observations,
    } = data as Partial<CleaningContract>;

    this.apartment = apartment ? new Apartment(apartment) : null;
    this.booking = booking ? new Booking(booking) : null;
    this.contractor = contractor ? new Contractor(contractor) : null;
    this.startDate = data.startDate ? moment(data.startDate) : null;
    this.endDate = data.endDate ? moment(data.endDate) : null;
    this.startedAt = data.startedAt ? moment(data.startedAt) : null;
    this.completedAt = data.completedAt ? moment(data.completedAt) : null;
    this.optimizedEndDate = data.optimizedEndDate
      ? moment(data.optimizedEndDate)
      : null;
    this.optimizedStartDate = data.optimizedStartDate
      ? moment(data.optimizedStartDate)
      : null;
    this.completedBy = completedBy ? new User(completedBy) : null;
    this.tags = (tags || []).map((tag) => new Tag(tag));
    this.observation = observation ? new TranslatedText(observation) : null;
    this.observations = (observations || []).map(
      (it) => new CleaningObservation(it),
    );
  }

  getObservationTranslation = (languageCode = ENUM.TRANSLATION_LANGUAGE.FR) => {
    return this.observation
      ? this.observation.getTranslation(languageCode)
      : null;
  };

  isActive = () => {
    return [
      ENUM.CLEANING_CONTRACT_STATUS.ACTIVE,
      ENUM.CLEANING_CONTRACT_STATUS.IN_PROGRESS,
    ].includes(this.status);
  };
}
