import moment from 'moment-timezone';

import { ROLE, ROLE_GROUP } from '@enum';

import Contact from './Contact';
import ContractorEmployee from './ContractorEmployee';
import Employee from './Employee';

export default class User {
  public createdAt: moment.Moment;
  public gender: string;
  public email = '';
  public firstname = '';
  public id: number;
  public lastname = '';
  public phone = '';
  public role: string;
  public status: string;
  public token: string;
  public profilePictureId: number;
  public policies: string[] = [];
  public contact: Contact;
  public employee: Employee;
  public contractorEmployee: ContractorEmployee;
  public subscribedNotifications: Array<{ code: string }>;

  constructor(data: Partial<User> | any = {}) {
    Object.assign(this, data);

    this.createdAt = data?.createdAt ? moment(data.createdAt) : undefined;
    this.contact = data.contact && new Contact(data.contact);
    this.employee = data.employee && new Employee(data.employee);
    this.contractorEmployee =
      data.contractorEmployee &&
      new ContractorEmployee(data.contractorEmployee);
    this.subscribedNotifications = data.subscribedNotifications || [];
  }

  get initials(): string {
    return `${this.firstname} ${this.lastname}`
      .trim()
      .split(/[\s-]+/)
      .map((el) => el[0])
      .join('')
      .toUpperCase();
  }

  getFullName() {
    return `${this.firstname} ${this.lastname}`;
  }

  getShortName() {
    return `${this.firstname} ${this.lastname.substring(0, 1)}`;
  }

  isSuperAdmin() {
    return [1, 2, 3].includes(this.id);
  }

  isAdmin() {
    return this.role === ROLE.ADMIN;
  }

  isSale() {
    return ROLE_GROUP.sales.includes(this.role);
  }

  isOperational() {
    return ROLE_GROUP.operationals.includes(this.role);
  }

  isProspect() {
    return this.role === ROLE.PROSPECT;
  }

  isContractor() {
    return [ROLE.CLEANING_CONTRACTOR, ROLE.CLEANING_CONTRACTOR_ADMIN].includes(
      this.role,
    );
  }

  isContactOnly() {
    return (
      (!!this.contact && !(!!this.employee || !!this.contractorEmployee)) ||
      this.role === ROLE.PROSPECT
    );
  }

  isSimpleContractor() {
    return this.role === ROLE.CLEANING_CONTRACTOR;
  }

  isAdminContractor() {
    return this.role === ROLE.CLEANING_CONTRACTOR_ADMIN;
  }

  getContractorId() {
    return this?.contractorEmployee?.contractorId;
  }

  getAgencyId() {
    return (this?.contractorEmployee || this?.employee)?.agencyId;
  }

  checkPolicies(allowedPolicies: string[]) {
    return this.policies.some((policy) => allowedPolicies.includes(policy));
  }
}
