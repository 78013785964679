import ENUM from '@enum';

import Translation from './Translation';

export default class TranslatedText {
  public id: number;
  public translations: Translation[];

  constructor(data: any) {
    this.id = data.id;
    this.translations = data.translations
      ? data.translations.map((translation) => new Translation(translation))
      : [];
  }

  getTranslation = (languageCode = ENUM.TRANSLATION_LANGUAGE.FR) => {
    const foundTranslation = this.translations.find(
      (translation) => translation.language === languageCode,
    );

    return foundTranslation ? foundTranslation.text : null;
  };
}
