import React from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';

import './GenericPopover.scss';

type Props = {
  children?: React.ReactElement<any>;
  popover?: JSX.Element | JSX.Element[];
  placement?:
    | 'right'
    | 'left'
    | 'top'
    | 'bottom'
    | 'bottom-start'
    | 'bottom-end';
  delay?: { show: number; hide: number };
  defaultShow?: boolean;
  className?: string;
  popoverClassName?: string;
  onEnter?: (node: HTMLElement) => void;
  show?: boolean;
  trigger?: Array<string> | string;
};

function GenericPopover({
  children,
  popover,
  placement = 'right',
  delay = { show: 200, hide: 200 },
  show,
  className = '',
  popoverClassName = '',
  defaultShow = false,
  trigger = ['hover', 'focus'],
  onEnter = () => {},
}: Props) {
  const overlay = popover ? (
    <Popover
      id="GenericPopover__popover"
      className={`GenericPopover__popover ${popoverClassName || ''}`}
    >
      {popover}
    </Popover>
  ) : null;

  return (
    <div className={`GenericPopover ${className || ''}`}>
      <OverlayTrigger
        onEnter={onEnter}
        defaultShow={defaultShow}
        show={show}
        rootClose
        placement={placement}
        delay={delay}
        overlay={overlay}
        trigger={trigger as any}
      >
        {children || null}
      </OverlayTrigger>
    </div>
  );
}

export default GenericPopover;
