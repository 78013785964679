export default class Agency {
  public address = '';
  public bookingsyncAccountId = '';
  public city = '';
  public id: number;
  public name = '';
  public phoneNumber = '';
  public postalCode = '';
  public twilioNumber = '';
  public email = '';

  constructor(data: Partial<Agency>) {
    Object.assign(this, data);
  }

  getFullAddress = () => {
    return `${this.address} ${this.postalCode} ${this.city}`;
  };
}
