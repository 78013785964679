import Toast from './Toast';

export default class Common {
  public initialized = false;
  public loading = false;
  public toast: Toast = null;
  public agencyFilter: number = null;
  public activityFilter: string = null;

  constructor(data = {}) {
    Object.assign(this, data);
  }
}
