import moment from 'moment-timezone';

import CONFIG from '@config';

import * as schema from '@redux/schema';

export const FETCH_CLEANING_CONTRACTS_BY_PRIORITY =
  'FETCH_CLEANING_CONTRACTS_BY_PRIORITY';
export const fetchCleaningContractsByPriority = (
  offset?: number,
  limit?: number,
  more?: boolean,
  agencyId = '',
  text = '',
  contractorId = '',
  contractorUserId = '',
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  order = '',
  status: Array<string> = [],
  cleaningType = '',
  apartmentId = '',
) => ({
  type: FETCH_CLEANING_CONTRACTS_BY_PRIORITY,
  route: '/cleaning-contracts/priority',
  method: 'GET',
  query: {
    offset,
    limit,
    agencyId,
    contractorId,
    userId: contractorUserId,
    apartmentId,
    cleaningType,
    status,
    text,
    startDate,
    endDate,
    order,
  },
  meta: {
    more,
  },
  schema: [schema.cleaningContract],
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_COUNT_CLEANING_CONTRACTS_BY_DATES =
  'FETCH_COUNT_CLEANING_CONTRACTS_BY_DATES';
export const fetchCountCleaningContractsByDate = (
  offset?: number,
  limit?: number,
  more?: boolean,
  agencyId = '',
  text = '',
  contractorId = '',
  contractorUserId = '',
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  order = '',
  status: Array<string> = [],
  cleaningType = '',
  apartmentId = '',
) => ({
  type: FETCH_COUNT_CLEANING_CONTRACTS_BY_DATES,
  route: '/cleaning-contracts/count-by-date',
  method: 'GET',
  query: {
    offset,
    limit,
    agencyId,
    contractorId,
    userId: contractorUserId,
    apartmentId,
    cleaningType,
    status,
    text,
    startDate,
    endDate,
    order,
  },
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_CLEANING_CONTRACTS = 'FETCH_CLEANING_CONTRACTS';
export const fetchCleaningContracts = (
  offset,
  limit,
  agencyId = '',
  text = '',
  startDate = '',
  endDate = '',
  order = '',
  status: Array<string> = [],
  contractorId = '',
  contractorUserId = '',
) => ({
  type: FETCH_CLEANING_CONTRACTS,
  route: '/cleaning-contracts',
  method: 'GET',
  query: {
    limit,
    offset,
    agencyId,
    text,
    startDate,
    endDate,
    order,
    status,
    contractorId,
    userId: contractorUserId,
  },
  schema: [schema.cleaningContract],
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_CLEANING_CONTRACTS_COUNT = 'FETCH_CLEANING_CONTRACTS_COUNT';
export const fetchCleaningContractsCount = (
  agencyId = '',
  startDate = '',
  endDate = '',
  order = '',
  status: Array<string> = [],
  contractorId = '',
  contractorUserId = '',
) => ({
  type: FETCH_CLEANING_CONTRACTS_COUNT,
  route: '/cleaning-contracts/count',
  method: 'GET',
  query: {
    agencyId,
    startDate,
    endDate,
    order,
    status,
    contractorId,
    userId: contractorUserId,
  },
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_LATE_CLEANING_CONTRACTS = 'FETCH_LATE_CLEANING_CONTRACTS';
export const fetchLateCleaningContracts = (
  agencyId = '',
  order = '',
  status: Array<string> = [],
  contractorId = '',
  contractorUserId = '',
) => ({
  type: FETCH_LATE_CLEANING_CONTRACTS,
  route: '/cleaning-contracts',
  method: 'GET',
  query: {
    agencyId,
    startDate: moment().subtract(1, 'month'),
    endDate: moment().subtract(2, 'day').endOf('day'),
    order,
    status,
    contractorId,
    userId: contractorUserId,
  },
  schema: [schema.cleaningContract],
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_CLEANING_CONTRACT = 'FETCH_CLEANING_CONTRACT';
export const fetchCleaningContract = (id: number) => ({
  type: FETCH_CLEANING_CONTRACT,
  route: `/cleaning-contracts/${id}`,
  method: 'GET',
  schema: schema.cleaningContract,
  hideLoading: true,
  url: CONFIG.API_V2_URL,
});

export const FETCH_CLEANING_CONTRACTORS_HEATMAP =
  'FETCH_CLEANING_CONTRACTORS_HEATMAP';
export const fetchCleaningContractorsHeatmap = (
  offset: number,
  limit: number,
  startDate?: moment.Moment,
  endDate?: moment.Moment,
  status?: string[],
) => ({
  type: FETCH_CLEANING_CONTRACT,
  route: `/cleaning-contracts/heatmap`,
  query: {
    startDate: startDate?.toISOString?.(),
    endDate: endDate?.toISOString?.(),
    status,
    offset,
    limit,
  },
  hideLoading: true,
  method: 'GET',
  url: CONFIG.API_V2_URL,
});

export const PATCH_CLEANING_CONTRACT = 'PATCH_CLEANING_CONTRACT';
export const patchCleaningContract = (
  id: number,
  contractModifications: Object,
) => ({
  type: PATCH_CLEANING_CONTRACT,
  route: `/cleaning-contracts/${id}`,
  method: 'PATCH',
  body: contractModifications,
  schema: schema.cleaningContract,
  url: CONFIG.API_V2_URL,
});

export const SET_CLEANING_CONTRACT_USER = 'SET_CLEANING_CONTRACT_USER';
export const setCleaningContractUser = (
  id: number,
  contractModifications: Object,
) => ({
  type: SET_CLEANING_CONTRACT_USER,
  route: `/cleaning-contracts/${id}`,
  method: 'PATCH',
  body: contractModifications,
  schema: schema.cleaningContract,
  url: CONFIG.API_V2_URL,
});

export const POST_CLEANING_CONTRACT = 'POST_CLEANING_CONTRACT';
export const postCleaningContract = (contractModifications: Object) => ({
  type: POST_CLEANING_CONTRACT,
  route: `/cleaning-contracts/`,
  method: 'POST',
  body: contractModifications,
  schema: schema.cleaningContract,
  url: CONFIG.API_V2_URL,
});

export const POST_FIRST_CLEANING = 'POST_FIRST_CLEANING';
export const postFirstCleaning = (
  leavingId: number,
  cleaningDate: moment.Moment,
  contractorId?: number,
) => ({
  type: POST_FIRST_CLEANING,
  route: `/leavings/${leavingId}/firstcleaning`,
  method: 'POST',
  body: {
    date: cleaningDate,
    contractorId,
  },
  schema: schema.leaving,
  url: CONFIG.API_V2_URL,
});

export const POST_LAST_CLEANING = 'POST_LAST_CLEANING';
export const postLastCleaning = (
  leavingId: number,
  cleaningDate: moment.Moment,
  contractorId?: number,
) => ({
  type: POST_LAST_CLEANING,
  route: `/leavings/${leavingId}/lastcleaning`,
  method: 'POST',
  body: {
    date: cleaningDate,
    contractorId,
  },
  schema: schema.leaving,
  url: CONFIG.API_V2_URL,
});

export const CANCEL_CLEANING_CONTRACT = 'CANCEL_CLEANING_CONTRACT';
export const cancelCleaningContract = (id: number) => ({
  type: CANCEL_CLEANING_CONTRACT,
  route: `/cleaning-contracts/${id}/cancel`,
  method: 'POST',
  schema: schema.cleaningContract,
  url: CONFIG.API_V2_URL,
});

export const COMPLETE_CLEANING_CONTRACT = 'COMPLETE_CLEANING_CONTRACT';
export const completeCleaningContract = (id: number) => ({
  type: COMPLETE_CLEANING_CONTRACT,
  route: `/cleaning-contracts/${id}/complete`,
  method: 'POST',
  schema: schema.cleaningContract,
  url: CONFIG.API_V2_URL,
});

export const NOT_DONE_CLEANING_CONTRACT = 'NOT_DONE_CLEANING_CONTRACT';
export const notDoneCleaningContract = (id: number) => ({
  type: NOT_DONE_CLEANING_CONTRACT,
  route: `/cleaning-contracts/${id}/not-done`,
  method: 'POST',
  schema: schema.cleaningContract,
  url: CONFIG.API_V2_URL,
});

export const POST_CLEANING_TAG = 'POST_CLEANING_TAG';
export const postCleaningTag = (id: number, code: string) => ({
  type: POST_CLEANING_TAG,
  route: `/cleaning-contracts/${id}/tags`,
  method: 'POST',
  hideLoading: true,
  body: {
    code,
  },
  meta: {
    cleaningContractId: id,
  },
  url: CONFIG.API_V2_URL,
});

export const DELETE_CLEANING_TAG = 'DELETE_CLEANING_TAG';
export const deleteCleaningTag = (id: number, code: string) => ({
  type: DELETE_CLEANING_TAG,
  route: `/cleaning-contracts/${id}/tags/${code}`,
  method: 'DELETE',
  hideLoading: true,
  meta: {
    cleaningContractId: id,
  },
  url: CONFIG.API_V2_URL,
});

export const SET_FOCUSED_CLEANING_CONTRACT = 'SET_FOCUSED_CLEANING_CONTRACT';
export const setFocusedCleaningContract = (id: number | null) => ({
  type: SET_FOCUSED_CLEANING_CONTRACT,
  payload: id,
});

export const POST_CLEANING_CONTRACT_ASSIGN_CONTRACTOR =
  'POST_CLEANING_CONTRACT_ASSIGN_CONTRACTOR';
export const postCleaningContractAssignContractor = (
  cleaningContractIds: number[],
  contractorId: number,
) => ({
  type: POST_CLEANING_CONTRACT_ASSIGN_CONTRACTOR,
  route: `/cleaning-contracts/assign-contractor`,
  method: 'POST',
  body: {
    cleaningContractIds,
    contractorId,
  },
  url: CONFIG.API_V2_URL,
});
